import React from "react";
import { Box } from "../../styledSystemUtilities";
import { BluePhone, Pdf } from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { BurkhartHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const ReferralsBurkhart = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <BurkhartHeader setPopup={setPopup} name={"Patient Referrals"} tagline={"Enhance Internal Marketing"} />
            <GrayBackground>
                <SellingPoint
                    header={"The lifeblood of a practice"}
                    body={
                        "Having the skill-set to properly ask for referrals and build an effective internal referral system is critical to your practice’s health. We focus on the “why” and the “how” of referrals and believe in an unscripted natural approach that builds confidence and engagement in the practice."
                    }
                />
            </GrayBackground>
            <WhiteBackground>
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={BluePhone}
                    header={"30 Minute Coaching Call"}
                    body={"Key to our customized approach"}
                />
                <IconBulletPoint
                    Icon={Pdf}
                    header={"PDF Scripting and Resources"}
                    body={"Supports team implementation"}
                />
            </WhiteBackground>
        </Box>
    );
};
