import React from "react";
import { Text } from "../Atoms";
import { Box } from "../styledSystemUtilities";

export const OnlineHorizontalPhaseLabel = ({ ...props }) => {
    return (
        <Box display={"flex"} alignItems={"flex-end"} position={"relative"} zIndex={-1} width={"220px"} {...props}>
            <Text variant={"giantNumber"} opacity={0.7} fontSize={"140px"} lineHeight={"90px"} color={"seaGreen"}>
                1
            </Text>
            <Text
                variant={"giantHeader"}
                fontSize={"70px"}
                color={"#619B72"}
                lineHeight={"48px"}
                transform={"translate(-25px, 0)"}
            >
                Online
            </Text>
        </Box>
    );
};

export const GrowthHorizontalPhaseLabel = ({ ...props }) => {
    return (
        <Box display={"flex"} alignItems={"flex-end"} position={"relative"} zIndex={-1} width={"250px"} {...props}>
            <Text variant={"giantNumber"} fontSize={"140px"} lineHeight={"90px"} color={"ipanemaBlue"}>
                2
            </Text>
            <Text
                variant={"giantHeader"}
                fontSize={"70px"}
                color={"petrolBlue"}
                lineHeight={"48px"}
                transform={"translate(-50px, 0)"}
            >
                Growth
            </Text>
        </Box>
    );
};

export const ProfitHorizontalPhaseLabel = ({ ...props }) => {
    return (
        <Box display={"flex"} alignItems={"flex-end"} position={"relative"} zIndex={-1} width={"218px"} {...props}>
            <Text variant={"giantNumber"} fontSize={"140px"} lineHeight={"90px"} color={"balticBlue"}>
                3
            </Text>
            <Text
                variant={"giantHeader"}
                fontSize={"70px"}
                color={"nauticBlue"}
                lineHeight={"48px"}
                transform={"translate(-29px, 0)"}
            >
                Profit
            </Text>
        </Box>
    );
};
