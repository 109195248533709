import React from "react";
import { Box } from "../../styledSystemUtilities";
import { BluePhone, Pdf } from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { BurkhartHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const AncillaryBurkhart = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <BurkhartHeader
                setPopup={setPopup}
                name={"Ancillary Services"}
                tagline={"Sell more profitable treatment."}
            />
            <GrayBackground>
                <SellingPoint
                    header={"The quickest way to improve profitability"}
                    body={
                        "Did you know the main reason many of your patients don’t purchase more of your ancillary services is simply that they don’t know you offer them? Learn how to educate in a way that adds value and strengthens the relationship."
                    }
                />
            </GrayBackground>
            <WhiteBackground>
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={BluePhone}
                    header={"30 Minute Coaching Call"}
                    body={"Key to our customized approach"}
                />
                <IconBulletPoint
                    Icon={Pdf}
                    header={"PDF Scripting and Resources"}
                    body={"Supports team implementation"}
                />
            </WhiteBackground>
        </Box>
    );
};
