import React from "react";
import { Box, FlexRow, FlexRowAlignCenter, Span } from "../styledSystemUtilities";
import { ArrowLeft } from "./assets";
import { Text } from "./Text";
import _ from "lodash/fp";
import { routerHistory } from "../../routerHistory";
import { Checkbox } from "./Checkbox";
import { onlineOptions } from "../../constants/onlineOptions";

export const OnlineOneSummaryRow = ({ visibilityLevel, rank, competitionLevel }) => (
    <SummaryRowContainer number={1} path={"online-one"}>
        <GreenText>
            Visibility: <OrangeSpan>{visibilityLevel}</OrangeSpan>
        </GreenText>
        <GreenText>
            Your rank: <OrangeSpan>{rank}</OrangeSpan>
        </GreenText>
        <GreenText>
            Competition level: <OrangeSpan>{competitionLevel}</OrangeSpan>
        </GreenText>
    </SummaryRowContainer>
);

export const OnlineTwoSummaryRow = ({ checked, setChecked }) => (
    <SummaryRowContainer number={2} path={"online-two"}>
        <Option checked={checked} option={onlineOptions.reduceCosts} setChecked={setChecked} />
        <Option checked={checked} option={onlineOptions.maintain} setChecked={setChecked} />
        <Option checked={checked} option={onlineOptions.improve} setChecked={setChecked} />
    </SummaryRowContainer>
);

const Option = ({ checked, option, setChecked }) => (
    <FlexRowAlignCenter>
        {checked.value === option.value ? (
            <FlexRowAlignCenter cursor={"pointer"}>
                <Text fontSize={"11px"} fontFamily={"montserrat"} color={"sienna"} fontWeight={"medium"}>
                    {option.capitalizedLabel}
                </Text>
                <Box pl={"7px"} />
                <Checkbox fill={"sienna"} isChecked={true} border={"auratiumGreen"} />
            </FlexRowAlignCenter>
        ) : (
            <FlexRowAlignCenter onClick={() => setChecked(option)} cursor={"pointer"}>
                <Text fontSize={"11px"} fontFamily={"montserrat"} color={"auratiumGreen"} fontWeight={"medium"}>
                    {option.capitalizedLabel}
                </Text>
                <Box pl={"7px"} />
                <Checkbox fill={"sienna"} isChecked={false} border={"auratiumGreen"} />
            </FlexRowAlignCenter>
        )}
    </FlexRowAlignCenter>
);

const SummaryRowContainer = ({ number, children, path }) => (
    <FlexRowAlignCenter
        px={"18px"}
        height={"34px"}
        backgroundColor={"rgba(255, 255, 255, .4)"}
        width={"100%"}
        data-testid={`onlineHeaderRow${number}`}
    >
        <FlexRow alignItems={"center"} cursor={"pointer"} onClick={() => routerHistory.push(path)} data-testid={"link"}>
            <ArrowLeft fill={"auratiumGreen"} />
            <Box pl={"3px"} />
            <Text variant={"body2"} color={"auratiumGreen"} fontWeight={"bold"}>
                {number}
            </Text>
        </FlexRow>
        <Box pl={"8px"} />
        <FlexRowAlignCenter justifyContent={"space-between"} width={"100%"}>
            {children}
        </FlexRowAlignCenter>
    </FlexRowAlignCenter>
);

const GreenText = ({ children }) => (
    <Text variant={"label1"} color={"auratiumGreen"}>
        {children}
    </Text>
);

const OrangeSpan = ({ children }) => (
    <Span fontSize={"11px"} fontFamily={"montserrat"} color={"sienna"} fontWeight={"medium"}>
        {_.capitalize(children)}
    </Span>
);
