import { Box } from "../../styledSystemUtilities";
import { X } from "../../Atoms/assets";
import { Text } from "../../Atoms";
import React from "react";
import { useWindowSize } from "react-use";
import { RoiCalculator } from "../../Molecules/PopupComponents";

export function RoiExplanation({ setPopup }) {
    const { width } = useWindowSize();

    return (
        <>
            <Box
                backgroundColor={"auratiumGreen"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup((prevState) => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Box pt={"31px"} />
            <Box
                backgroundColor={"surfGreen"}
                height={"91px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                px={"21px"}
            >
                <Text variant={"header2"} color={"forrestGreen"}>
                    How do we estimate your ROI average?
                </Text>
            </Box>
            <Box backgroundColor={"chalk"} px={"21px"} py={"20px"}>
                <Text variant={"body3"}>1. We estimate how many new patients are likely based on the following:</Text>
                <Box pb={3} />
                <Box pl={"19px"}>
                    <Text variant={"label2"} lineHeight={"14px"}>
                        Using your area competition level and the ranking level chosen on the slider, we calculate a
                        range of new patients. These numbers are based on data from the volume of search in a
                        competitive area and our client’s reporting of new patients from search in the 3 ranking levels.
                    </Text>
                    <Box pb={3} />
                    <Text variant={"label2"} lineHeight={"14px"}>
                        Using the range calculated as described above, we take the average of the range as the number to
                        use for probable new patients.
                    </Text>
                </Box>

                <Box pb={6} />

                <Text variant={"body3"}>
                    2. We multiply the average number of new patients by the default per-patient value of $1,000. (If
                    you’d like to calculate your own per-patient value to improve the accuracy of our ROI predictions,
                    use the calculator below.)
                </Text>
                <Box pb={6} />
                <Text variant={"body3"}>3. We subtract the online total from the recommended products.</Text>
                <Box pb={6} />
                <Text variant={"body3"} fontWeight={"bold"}>
                    The result is your estimated ROI Average*
                </Text>
                <Box pb={6} />
                <Text variant={"label2"} lineHeight={"14px"}>
                    *Improvements in online visibility generally can take anywhere from 3-6 months, and the listed ROI
                    averages are predicated on achieving the desired improvements.
                </Text>
            </Box>
            <RoiCalculator
                setPopup={setPopup}
                backgroundColor={"surfGreen"}
                textColor={"forrestGreen"}
                buttonColor={"auratiumGreen"}
            />
        </>
    );
}
