import React from "react";
import { Box, FlexRowAlignCenter, Grid } from "../styledSystemUtilities";
import { Button, Gradient, Input, Text } from "../Atoms";
import { VideoPlaybackIcon } from "../Atoms/assets";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormError } from "../Atoms/FormError";
import { useDispatch } from "react-redux";
import { CheckoutHeader } from "../Molecules/CheckoutHeader";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";
import { actions } from "../../redux";
import useError from "../../hooks/useError";

const schema = object().shape({
    email: string().required("Please enter your email.").email("Please enter a valid email.").trim(),
    password: string().required("Please enter a password"),
});

export const Login = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });
    const error = useError();

    const onSubmit = (loginCredentials) => {
        dispatch(
            actions.login({
                email: loginCredentials.email,
                password: loginCredentials.password,
            })
        );
    };

    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"44px"} />
                    <CheckoutHeader darkNumber={1} header={"Account Login"} />
                    <Box pb={"20px"} />
                    <FlexRowAlignCenter justifyContent={"center"}>
                        <VideoPlaybackIcon fill={"graphiteGray"} />
                        <Box pl={"9px"} />
                        <Text color={"graphiteGray"} variant={"body2"} fontWeight={"medium"}>
                            Subscription based marketing
                        </Text>
                    </FlexRowAlignCenter>
                    <Box pb={"6px"} />
                    <Text textAlign={"center"} variant={"body2"} color={"graphiteGray"}>
                        The future of digital marketing.
                    </Text>
                    <Box pb={"19px"} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            data-testid={"email"}
                            ref={register}
                            name={"email"}
                            placeholder="Email Address"
                            hasError={errors.email}
                        />
                        <FormError error={errors.email} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"password"}
                            ref={register}
                            name={"password"}
                            placeholder="Password"
                            hasError={errors.password}
                        />
                        <FormError error={errors.password} />
                        <Box pb={"26px"} />
                        <Button backgroundColor={"graphiteGray"} onClick={handleSubmit}>
                            login
                        </Button>
                        <FormError error={error} />
                        <Box pb={15} />
                    </form>
                </Box>
            </Grid>
        </Box>
    );
};
