import React from "react";
import { Box } from "../styledSystemUtilities";
import { Text } from "./Text";
import { InfoIcon } from "./assets";
import { Checkbox } from "./Checkbox";
import { StarRating } from "../Molecules";
const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export const GrowthProductCard = ({ product, handleAdd, setPopup, isSummary }) => {
    return (
        <Box
            display={"grid"}
            gridTemplateColumns={"1fr 26px 44px 52px"}
            alignItems={"center"}
            height={12}
            backgroundColor={"white"}
            pl={"11px"}
        >
            <Box
                cursor={"pointer"}
                onClick={() => {
                    window.scrollTo(0, 0);
                    setPopup((prevState) => product.popup);
                }}
            >
                {isSummary ? (
                    <>
                        <Text variant={"body3"} color={"ipanemaBlue"}>
                            {product.name}
                        </Text>
                        <Box ml={2}>
                            <Text variant={"label1"}>{product.description}</Text>
                        </Box>
                    </>
                ) : (
                    <>
                        <Text variant={"body3"} color={"ipanemaBlue"} lineHeight={"10px"} mt={2}>
                            {product.name}
                        </Text>
                        <Box display={"flex"} alignItems={"flex-end"}>
                            <StarRating numStars={product.numStars} />
                            {product.isBestOption ? (
                                <Text variant={"label1"} ml={5} mb={"1px"}>
                                    Best Option
                                </Text>
                            ) : null}
                        </Box>
                    </>
                )}
            </Box>
            <InfoIcon
                fill={"ipanemaBlue"}
                onClick={() => {
                    window.scrollTo(0, 0);
                    setPopup((prevState) => product.popup);
                }}
                data-testid={`infoIcon${product.id}`}
            />
            <Text variant={"body3"} justifySelf={"end"}>
                {formatter.format(product.monthlyPrice)}
            </Text>
            <Checkbox
                onClick={() => handleAdd(product)}
                fill={"ipanemaBlue"}
                isChecked={product.isChecked}
                justifySelf={"center"}
                data-testid={`checkbox${product.id}`}
            />
        </Box>
    );
};
