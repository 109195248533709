import { createSlice } from "@reduxjs/toolkit";
import { onlineOptions } from "../../constants/onlineOptions";

export default createSlice({
    name: "onlineOptions",
    initialState: onlineOptions.improve,
    reducers: {
        setOption: (state, action) => action.payload,
    },
});
