import _ from "lodash/fp";
import { useSelector } from "react-redux";

export const useOnlineEstimates = (products) => {
    const perPatientValue = useSelector((state) => state.perPatientValue);
    const highEstimate = _.round(_.sumBy("newPatientEstimate", products));
    const rawLowEstimate = _.round(highEstimate * 0.7);
    const lowEstimate = highEstimate ? (highEstimate === rawLowEstimate ? highEstimate - 1 : rawLowEstimate) : 0;
    const cost = _.sumBy("monthlyPrice", products);
    const revenue = ((highEstimate + lowEstimate) / 2) * perPatientValue;
    const roi = revenue - cost;
    const estimate = highEstimate === 0 ? "0" : `${lowEstimate}-${highEstimate}`;

    return {
        roi,
        estimate,
        cost,
        revenue,
        highEstimate,
        lowEstimate,
    };
};
