import React, { useState } from "react";
import { Box, FlexRow, FlexRowAlignCenter, Span } from "../styledSystemUtilities";
import { Button, Checkbox, HalfInput, Text } from "../Atoms";
import { BurkhartLogo, Calculator, EDentWithBurkhart, LionSpeakLogo, X } from "../Atoms/assets";
import { useWindowSize } from "react-use";
import { formatter } from "../../utils/formatter";
import { useDispatch } from "react-redux";
import _ from "lodash/fp";
import { slices } from "../../redux";

export const RoiCalculator = ({ setPopup, backgroundColor, textColor, buttonColor }) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState({ label: "12 month", value: 12 });
    const [revenue, setRevenue] = useState("0");
    const [patients, setPatients] = useState("0");

    const perPatientValue = Number(patients)
        ? _.round(getNumbers(revenue) / 12 / (Number(patients) / selected.value))
        : 0;

    const onSubmit = () => {
        dispatch(slices.perPatientValue.actions.set(perPatientValue));
        setPopup(null);
    };
    return (
        <>
            <FlexRow backgroundColor={backgroundColor} px={"21px"} py={"12px"}>
                <Box width={"21px"} pt={"4px"}>
                    <Calculator fill={textColor} />
                </Box>

                <Box pl={"13px"} />
                <Text variant={"header2"} color={textColor}>
                    Calculate your annual per patient value.
                </Text>
            </FlexRow>
            <Box pb={"21px"} />
            <Box px={"21px"}>
                <Text variant={"body3"}>
                    1. Enter your current active patient count: <br /> (Select if it is based on 12 or 18 months)
                </Text>
                <Box pb={"6px"} />
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <HalfInput
                        placeholder={"0"}
                        data-testid={"patientCount"}
                        onChange={(event) => setPatients(event.target.value)}
                        width={"50%"}
                    />
                    <Box pr={"28px"}>
                        <TimeframeOption
                            option={{ value: 12, label: "12 month" }}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Box pb={"10px"} />
                        <TimeframeOption
                            option={{ value: 18, label: "18 month" }}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </FlexRowAlignCenter>
                <Box pb={"20px"} />
                <Text variant={"body3"}>2. Enter your gross revenue for the last 12 months:</Text>
                <Box pb={"6px"} />
                <HalfInput
                    placeholder={"$0"}
                    onChange={(event) => setRevenue(event.target.value)}
                    data-testid={"revenue"}
                />
                <Box pb={"15px"} />
                <Text variant={"body3"} fontWeight={"bold"}>
                    Annual per-patient value:
                </Text>
                <Box pb={"4px"} />
                <FlexRow justifyContent={"space-between"}>
                    <FlexRowAlignCenter justifyContent={"center"} width={"49%"} border={"mid"}>
                        <Text variant={"body3"} color={"graphiteGray"} data-testid={"perPatientValue"}>
                            {formatter.format(perPatientValue)}
                        </Text>
                    </FlexRowAlignCenter>
                    <Box width={"49%"}>
                        <Button backgroundColor={buttonColor} onClick={onSubmit}>
                            enter ppv
                        </Button>
                    </Box>
                </FlexRow>
                <Box pb={"24px"} />
            </Box>
        </>
    );
};

export function OnlineHeader({ setPopup, Hero }) {
    const { width } = useWindowSize();
    return (
        <>
            <Box
                backgroundColor={"auratiumGreen"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup(() => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Hero />

            <Box backgroundColor={"chalk"} mt={"-28px"} height={"28px"} />
        </>
    );
}

export function GrowthHeader({ setPopup, Hero }) {
    const { width } = useWindowSize();
    return (
        <>
            <Box
                backgroundColor={"petrolBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup(() => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Hero />
            <Box backgroundColor={"chalk"} mt={"-27px"} height={"27px"} />
        </>
    );
}

export function ProfitHeader({ setPopup, Hero, chalkHeight = "100px", name, tagline }) {
    const { width } = useWindowSize();
    return (
        <>
            <Box
                backgroundColor={"balticBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup(() => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Hero name={name} tagline={tagline} />
            <Box backgroundColor={"chalk"} mt={"-27px"} height={"27px"} />
        </>
    );
}

export function BurkhartHeader({ name, tagline, setPopup }) {
    const { width } = useWindowSize();
    return (
        <Box>
            <Box
                backgroundColor={"balticBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup(() => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Box pb={"21px"} />
            <Box pl={"21px"} height={"158px"} backgroundColor={"backgroundGray"}>
                <Box pb={8} />
                <Text variant={"header2"} color={"nauticBlue"}>
                    {name}
                </Text>
                <Box pb={9} />
                <Text fontFamily={"montserrat"} color={"white"} fontSize={"30px"}>
                    {tagline}
                </Text>
            </Box>
            <Box
                backgroundColor={"white"}
                height={"269px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <BurkhartLogo />
            </Box>
            <Box height={"50px"} backgroundColor={"chalk"} />
        </Box>
    );
}

export function EDentHeader({ name, tagline, setPopup }) {
    const { width } = useWindowSize();
    return (
        <Box>
            <Box
                backgroundColor={"balticBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup(() => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Box pb={"21px"} />
            <Box pl={"21px"} height={"158px"} backgroundColor={"backgroundGray"}>
                <Box pb={8} />
                <Text variant={"header2"} color={"nauticBlue"}>
                    {name}
                </Text>
                <Box pb={9} />
                <Text fontFamily={"montserrat"} color={"white"} fontSize={"30px"}>
                    {tagline}
                </Text>
            </Box>
            <Box
                backgroundColor={"white"}
                height={"269px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <EDentWithBurkhart />
            </Box>
            <Box height={"50px"} backgroundColor={"chalk"} />
        </Box>
    );
}

export function LionSpeakHeader({ name, tagline, setPopup }) {
    const { width } = useWindowSize();
    return (
        <Box>
            <Box
                backgroundColor={"balticBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup(() => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Box pb={"21px"} />
            <Box pl={"21px"} height={"158px"} backgroundColor={"backgroundGray"}>
                <Box pb={8} />
                <Text variant={"header2"} color={"nauticBlue"}>
                    {name}
                </Text>
                <Box pb={9} />
                <Text fontFamily={"montserrat"} color={"white"} fontSize={"30px"}>
                    {tagline}
                </Text>
            </Box>
            <Box
                backgroundColor={"white"}
                height={"269px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <LionSpeakLogo />
            </Box>
            <Box height={"50px"} backgroundColor={"chalk"} />
        </Box>
    );
}

export function Disclaimers() {
    return (
        <Box pb={6}>
            <Text variant={"label2"} lineHeight={"14px"}>
                *Estimated Revenue is based on your annual per patient value and your average monthly new patients.
            </Text>
            <Text variant={"label2"} lineHeight={"14px"}>
                **ROI calculations use projected revenue and minus your ads-end budget and Ascent management fees.
            </Text>
        </Box>
    );
}

export function SamplesHeader({ color }) {
    return (
        <Box mx={"11px"} pt={7} pb={7}>
            <Text variant={"header2"} color={color}>
                Samples
            </Text>
        </Box>
    );
}

export function PricingNotes({ children }) {
    return (
        <GrayBackground>
            <Box pt={10} />
            <Text variant={"body2"} fontWeight={"bold"}>
                Pricing Notes
            </Text>
            <Box mb={5} />
            <Text variant={"body3"}>{children}</Text>
            <Box pb={11} />
        </GrayBackground>
    );
}

export function SellingPoint({ header, body, lineHeight = "20px" }) {
    return (
        <Box pb={7}>
            <Text variant={"body2"} fontWeight={"bold"}>
                {header}
            </Text>
            <Box ml={"17px"} mt={2}>
                <Text variant={"body3"} lineHeight={lineHeight} style={{ whiteSpace: "pre-line" }}>
                    {body}
                </Text>
            </Box>
        </Box>
    );
}

export function IconContainer({ name, children }) {
    return (
        <Box mt={4}>
            <Box borderBottom={"mid"}>
                <Text variant={"body3"} fontWeight={"bold"}>
                    {name}
                </Text>
                <Box mb={2} />
            </Box>
            <Box display={"grid"} gridRowGap={"8px"} gridTemplateColumns={"1fr 1fr 1fr"} mt={"14px"}>
                {children}
            </Box>
        </Box>
    );
}

export function ReportingHeader({ product, color }) {
    return (
        <Box py={5}>
            <Text variant={"header2"} color={color}>
                Reporting
            </Text>
            <Box mb={2} />
            <Text variant={"body3"} fontWeight={"medium"}>
                How will you know if your {product} is effective?
            </Text>
        </Box>
    );
}

export function ReportingCard({ children }) {
    return (
        <>
            <Box backroundColor={"white"} px={"21px"} pt={"18px"}>
                {children}
            </Box>
            <Box height={6} backgroundColor={"chalk"} />
        </>
    );
}

export function NumberedParagraph({ header, body }) {
    return (
        <Text variant={"body3"} lineHeight={"20px"} pb={6}>
            <Span fontWeight={"medium"}>{header}</Span> - {body}
        </Text>
    );
}

export function Paragraph({ children }) {
    return (
        <Text variant={"body3"} lineHeight={"20px"} mb={6}>
            {children}
        </Text>
    );
}

export function GrayBackground({ children }) {
    return (
        <Box px={"21px"} backgroundColor={"chalk"}>
            {children}
        </Box>
    );
}

export function WhiteBackground({ children }) {
    return (
        <>
            <Box px={"21px"} py={"22px"} backgroundColor={"white"}>
                {children}
            </Box>
            <Box backgroundColor={"chalk"} height={"14px"} />
        </>
    );
}

export function SampleContainer({ children }) {
    return (
        <Box px={5} backgroundColor={"chalk"}>
            {children}
        </Box>
    );
}

export function Dimensions({ bold, light }) {
    return (
        <>
            <IconContainer name={"Dimensions"} />
            <Box display={"flex"} alignItems={"center"} pl={3} pb={7}>
                <Text fontSize={"20px"} fontWeight={"bold"}>
                    {bold}&nbsp;
                </Text>
                <Text fontSize={"14px"}>{light}</Text>
            </Box>
        </>
    );
}

export function IconBulletPoint({ Icon, header, body }) {
    return (
        <Box display={"flex"} pt={5} pb={10}>
            <Box width={"36px"} display={"flex"} justifyContent={"center"}>
                <Icon />
            </Box>

            <Box ml={"13px"} />
            <Box>
                <Text variant={"body2"} fontWeight={"bold"} pb={2}>
                    {header}
                </Text>
                <Text variant={"body3"} ml={7}>
                    {body}
                </Text>
            </Box>
        </Box>
    );
}

function getNumbers(string) {
    const stringArray = string.split("");
    const filtered = stringArray.filter((char) => char !== "$" && char !== ",");
    return _.toNumber(filtered.join(""));
}

const TimeframeOption = ({ option, selected, setSelected }) => {
    return (
        <FlexRowAlignCenter
            cursor={"pointer"}
            onClick={() => setSelected(option)}
            data-testid={`${option.value}Months`}
        >
            <Checkbox fill={"graphiteGray"} isChecked={selected.value === option.value} />
            <Box pl={"10px"} />
            <Text variant={"body3"}>{option.label}</Text>
        </FlexRowAlignCenter>
    );
};
