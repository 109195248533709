import React from "react";
import { Box, FlexCol, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Text } from "./Text";
import { useCart } from "../../hooks/useCart";
import { useSetPopup } from "../../hooks/useSetPopup";
import { productCardLogos } from "../../constants/productCardLogos";
import { productCardBottomRows } from "../../constants/productCardBottomRows";
import { ProductBottomRow } from "./ProductBottomRows";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export const ProductCard = ({ product }) => {
    const [isAdded, toggleProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    return (
        <Box data-testid={product.recurlyCode}>
            {product.logo ? productCardLogos[product.logo]() : null}
            <ProductDetails
                name={product.name}
                description={product.description}
                price={product.price}
                subPrice={product.subPrice}
            />
            {product.bottomRow ? (
                productCardBottomRows[product.bottomRow]()
            ) : (
                <ProductBottomRow
                    toggleProduct={toggleProduct}
                    isAdded={isAdded}
                    color={"nauticBlue"}
                    backgroundColor={"pattensBlue"}
                    setPopup={setPopup}
                    learnMoreText={product.learnMoreText}
                />
            )}
        </Box>
    );
};

export const ProductDetails = ({ name, description, price, subPrice = "" }) => (
    <FlexRowAlignCenter justifyContent={"space-between"} px={"13px"} py={"6px"} backgroundColor={"catskillWhite"}>
        <Box>
            <Text variant={"body3"} color={"nauticBlue"}>
                {name}
            </Text>
            <Box pb={"1px"} />
            <Text variant={"label1"} color={"balticBlue"}>
                {description}
            </Text>
        </Box>
        <FlexCol alignItems={"flex-end"}>
            {price === 0 ? null : (
                <>
                    <Text variant={"body3"}>{formatter.format(price)}</Text>
                    <Box pb={"1px"} />
                    <Text variant={"label1"}>{subPrice}</Text>
                </>
            )}
        </FlexCol>
    </FlexRowAlignCenter>
);
