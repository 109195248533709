import React from "react";
import { Box } from "../../styledSystemUtilities";
import { BluePhone, Cart, Pdf } from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { BurkhartHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const PhoneSkillsBurkhart = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <BurkhartHeader setPopup={setPopup} name={"Phone Skills"} tagline={'Have them at "Hello."'} />
            <GrayBackground>
                <SellingPoint
                    header={"The most critical skill for effective marketing"}
                    body={
                        "Generating phone calls is an expensive process. Even using the best (Ascent) at efficiently driving quality phone calls into your practice, each new phone call represents an investment. Even small improvements in call closure rates make big differences in your per-patient acquisition costs."
                    }
                />
            </GrayBackground>
            <WhiteBackground>
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={BluePhone}
                    header={"30 Minute Coaching Call"}
                    body={"Key to our customized approach."}
                />
                <IconBulletPoint
                    Icon={Pdf}
                    header={"PDF Scripting and Resources"}
                    body={"Supports team implementation."}
                />
                <IconBulletPoint
                    Icon={Cart}
                    header={"1 Mystery Shopper Call"}
                    body={"We’ll report on how your team did."}
                />
            </WhiteBackground>
        </Box>
    );
};
