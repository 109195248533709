import React from "react";
import { Box } from "../styledSystemUtilities";
import { CardContainer, GrowthSlider, HeaderRowContainer, Text, TotalRowContainer } from "../Atoms";
import { ProductCardContainer, StarRating } from "../Molecules";
import { QuestionIcon } from "../Atoms/assets";
import _ from "lodash/fp";
import { GrowthRoiExplanation } from "./Popups";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export const GrowthProductSelect = ({
    products,
    setProducts,
    perPatientValue,
    cpc,
    newPatientGoal = 5,
    setNewPatientGaol,
    setPopup,
    defaultValue,
    setDefaultValue,
}) => {
    const cost = _.flow(_.filter("isChecked"), _.sumBy("monthlyPrice"))(products);
    const revenue = _.flow(_.filter("isChecked"), _.sumBy("revenue"))(products);
    const roi = revenue - cost;

    const handleSliderChange = (value) => {
        const newProducts = formatGrowthProducts(products, perPatientValue, cpc, value);
        setProducts((prevState) => newProducts);
        setNewPatientGaol((prevState) => value);
        setDefaultValue(value);
    };

    return (
        <CardContainer>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={3}>
                <Text variant={"body3"} ml={"11px"}>
                    How many patients per campaign?
                </Text>
                <Box
                    height={9}
                    width={16}
                    backgroundColor={"white"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text variant={"body3"} fontWeight={"medium"} color={"ipanemaBlue"} data-testid={"newPatientGoal"}>
                        {newPatientGoal}
                    </Text>
                </Box>
            </Box>

            <Box px={"11px"} mb={8}>
                <GrowthSlider handleChange={handleSliderChange} defaultValue={defaultValue} />
            </Box>

            <ProductCardContainer
                phaseName={"growth"}
                products={products}
                setProducts={setProducts}
                headers={["CAMPAIGN", "MONTHLY", "ADD?"]}
                setPopup={setPopup}
            />
            <Box mb={4} />
            <HeaderRowContainer>
                <Text variant={"label3"} color={"slateGray"} gridColumn={2} justifySelf={"center"}>
                    MONTHLY
                </Text>
            </HeaderRowContainer>
            <TotalRowContainer>
                <Text variant={"body3"} fontWeight={"bold"} color={"ipanemaBlue"}>
                    Growth Total
                </Text>
                <Text variant={"body3"} fontWeight={"bold"} justifySelf={"end"} data-testid={"total"}>
                    {formatter.format(cost)}
                </Text>
            </TotalRowContainer>
            <Box mb={1} />
            <TotalRowContainer>
                <Text variant={"body4"}>Estimated Revenue</Text>
                <Text variant={"body3"} justifySelf={"end"}>
                    {formatter.format(revenue)}
                </Text>
            </TotalRowContainer>
            <Box mb={1} />
            <TotalRowContainer>
                <Text variant={"body3"} fontWeight={"bold"} color={"ipanemaBlue"}>
                    Estimated ROI
                </Text>
                <Text variant={"body3"} fontWeight={"bold"} justifySelf={"end"}>
                    {formatter.format(roi)}
                </Text>
                <QuestionIcon
                    fill={"ipanemaBlue"}
                    justifySelf={"center"}
                    onClick={() => setPopup((prevState) => GrowthRoiExplanation)}
                    data-testid={"questionIcon"}
                />
            </TotalRowContainer>

            <Box display={"flex"} justifyContent={"center"} mt={8} mb={3}>
                <Text variant={"body3"}>Ratings based on projected ROI %</Text>
            </Box>

            <Box display={"grid"} gridTemplateColumns={"1fr 1.2fr 1fr .8fr"} alignItems={"center"} pl={"11px"} mb={5}>
                <StarRating numStars={5} />
                <Text variant={"body4"}>Excellent</Text>
                <StarRating numStars={2} />
                <Text variant={"body4"}>Break Even</Text>
                <StarRating numStars={4} />
                <Text variant={"body4"}>Very Good</Text>
                <StarRating numStars={1} />
                <Text variant={"body4"}>Avoid</Text>
                <StarRating numStars={3} />
                <Text variant={"body4"}>Good</Text>
            </Box>
        </CardContainer>
    );
};

function formatGrowthProducts(products, perPatientValue, cpc, newPatientGoal) {
    return _.flow(
        _.map((product) => {
            const cost = product.name === "Pay Per Click" ? cpc : product.costPerMailer;
            const rawCostPerPatient = (1 / (product.conversionRate * product.callConversionRate)) * cost;

            const monthlyPrice =
                rawCostPerPatient * newPatientGoal + product.managementFee + _.getOr(0, "callTrackingFee", product);

            const costPerPatient = monthlyPrice / newPatientGoal;

            const numStars = _.cond([
                [(value) => perPatientValue / value < 0.8, _.constant(1)],
                [(value) => perPatientValue / value >= 0.8 && perPatientValue / value < 1.2, _.constant(2)],
                [(value) => perPatientValue / value >= 1.2 && perPatientValue / value < 2, _.constant(3)],
                [(value) => perPatientValue / value >= 2 && perPatientValue / value < 3, _.constant(4)],
                [(value) => perPatientValue / value >= 3, _.constant(5)],
            ])(costPerPatient);

            return {
                ...product,
                numStars,
                monthlyPrice: _.round(monthlyPrice),
                costPerPatient,
                revenue: newPatientGoal * perPatientValue,
                isBestOption: false,
                isChecked: false,
            };
        }),
        _.sortBy("costPerPatient"),
        _.set("0.isBestOption", true),
        _.set("0.isChecked", true)
    )(products);
}
