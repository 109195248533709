import React from "react";
import {
    HorizontalGridLines,
    LineSeries,
    MarkSeries,
    PolygonSeries,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis,
} from "react-vis";
import _ from "lodash/fp";
import { useTheme } from "styled-components";
import "react-vis/dist/style.css";
import "./plots.css";

export const LocalRankingPlot = ({ data }) => {
    const { competitorArray: competitorsWithoutTop, topCompData, userData } = data;

    const competitorArray = _.orderBy("y", "asc", [...competitorsWithoutTop, ...topCompData]);

    const yTicks = getSeoTickValues(_.get("0.y", _.takeLast(1, competitorArray)));
    // console.log(yTicks);
    const tickValues = [30, 20, 10, 1];

    return (
        <React.Fragment>
            <XYPlot
                xDomain={[30, 1]}
                yDomain={[yTicks[0], 1]}
                height={164}
                width={323}
                margin={{ left: 30, right: 20, top: 30, bottom: 20 }}
            >
                <VerticalGridLines tickValues={tickValues} />
                <HorizontalGridLines tickValues={yTicks} />
                <XAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                    }}
                    tickValues={tickValues}
                    tickPadding={4}
                />
                <YAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: 1 },
                    }}
                    tickValues={yTicks}
                    tickPadding={4}
                />
                <PolygonSeries
                    color="#99C2AD"
                    data={[
                        {
                            x: 30,
                            y: yTicks[1],
                        },
                        {
                            x: 20,
                            y: yTicks[1],
                        },
                        {
                            x: 20,
                            y: yTicks[0],
                        },
                        {
                            x: 30,
                            y: yTicks[0],
                        },
                    ]}
                />
                <PolygonSeries
                    color="hsl(149, 21%, 76%, .5)"
                    data={[
                        {
                            x: 30,
                            y: yTicks[2],
                        },
                        {
                            x: 10,
                            y: yTicks[2],
                        },
                        {
                            x: 10,
                            y: yTicks[0],
                        },
                        {
                            x: 20,
                            y: yTicks[0],
                        },
                        {
                            x: 20,
                            y: yTicks[1],
                        },
                        {
                            x: 30,
                            y: yTicks[1],
                        },
                    ]}
                />
                {!_.isEmpty(competitorArray) ? (
                    <MarkSeries
                        strokeWidth={3}
                        opacity=".5"
                        size={3}
                        data={_.take(29, competitorArray)}
                        color="#A7A9AC"
                    />
                ) : null}

                <MarkSeries strokeWidth={3} opacity="1" size={3} data={userData} color="#E27867" />
                {!_.isEmpty(competitorArray) ? (
                    <MarkSeries strokeWidth={3} opacity="1" size={3} data={[competitorArray[0]]} color="#74AD85" />
                ) : null}
            </XYPlot>
        </React.Fragment>
    );
};

export const ReviewsRankingPlot = ({
    data: { avgRating, avgNumReviews, userData, compData, topCompData, xDomain: xDomainObject, yDomain: yDomainObject },
}) => {
    const { colors } = useTheme();
    const xDomain = [0, xDomainObject.max];
    const yDomain = [yDomainObject.min, yDomainObject.max];

    const verticalLineData = [
        { x: xDomain[0], y: avgRating },
        { x: xDomain[1], y: avgRating },
    ];
    const horizontalLineData = [
        { x: avgNumReviews, y: yDomain[0] },
        { x: avgNumReviews, y: yDomain[1] },
    ];
    const quadrant2Data = [
        { x: xDomain[0], y: avgRating },
        { x: xDomain[0], y: yDomain[1] },
        { x: avgNumReviews, y: yDomain[1] },
        { x: avgNumReviews, y: avgRating },
    ];

    const quadrant3Data = [
        { x: xDomain[0], y: avgRating },
        { x: avgNumReviews, y: avgRating },
        { x: avgNumReviews, y: yDomain[0] },
        { x: xDomain[0], y: yDomain[0] },
    ];
    const quadrant4Data = [
        {
            x: avgNumReviews,
            y: yDomain[0],
        },
        {
            x: avgNumReviews,
            y: avgRating,
        },
        { x: xDomain[1], y: avgRating },
        { x: xDomain[1], y: yDomain[0] },
    ];

    return (
        <XYPlot
            margin={{ left: 50, right: 20, top: 20, bottom: 30 }}
            width={308}
            height={254}
            yDomain={yDomain}
            xDomain={xDomain}
        >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <LineSeries
                data={verticalLineData}
                color={colors.agateGray}
                style={{ strokeLinejoin: "round", strokeWidth: "2" }}
            />
            <LineSeries
                data={horizontalLineData}
                color={colors.agateGray}
                style={{ strokeLinejoin: "round", strokeWidth: "2" }}
            />

            <PolygonSeries data={quadrant2Data} color={colors.seaGreen} style={{ opacity: ".17" }} />
            <PolygonSeries data={quadrant3Data} color={colors.seaGreen} style={{ opacity: ".6" }} />
            <PolygonSeries data={quadrant4Data} color={colors.seaGreen} style={{ opacity: ".17" }} />
            {!_.isEmpty(compData) ? (
                <MarkSeries size={"4"} strokeWidth={4} color={colors.slateGray} data={compData} opacity=".5" />
            ) : null}
            {!_.isEmpty(topCompData) ? (
                <MarkSeries size={"4"} strokeWidth={4} color={colors.auratiumGreen} data={topCompData} />
            ) : null}

            <MarkSeries size={4} strokeWidth={4} color={colors.sienna} data={userData} />
        </XYPlot>
    );
};

function getSeoTickValues(highY) {
    const divisions = highY / 3;
    return [_.round(divisions * 3), _.round(divisions * 2), _.round(divisions), 1];
}
