import React from "react";
import { Box, FlexRowAlignCenter, Grid } from "../styledSystemUtilities";
import { Gradient, Text } from "../Atoms";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";
import { X } from "../Atoms/assets";

export const Terms = ({ setTermsAreOpen }) => {
    return (
        <Box position={"absolute"} zIndex={2} backgroundColor={"white"}>
            <Box position={"fixed"}>
                <Gradient />
            </Box>
            <AbsolutePositionLogo />
            <Grid position={"fixed"} gridTemplateColumns={"1fr minmax(320px, 888px) 1fr"} width={"100vw"} top={"11px"}>
                <Box gridColumn={2} width={"888px"} height={"31px"} backgroundColor={"agateGray"}>
                    <FlexRowAlignCenter
                        pl={"21px"}
                        cursor={"pointer"}
                        onClick={() => setTermsAreOpen(false)}
                        height={"100%"}
                    >
                        <X />
                        <Box pl={"12px"} />
                        <Text variant={"body3"} color={"white"}>
                            Close
                        </Text>
                    </FlexRowAlignCenter>
                </Box>
            </Grid>
            <Grid gridTemplateColumns={"1fr minmax(320px, 920px) 1fr"} width={"100vw"}>
                <Box gridColumn={2} px={"16px"}>
                    <Box pt={"66px"} />
                    <Text variant={"header2"} color={"graphiteGray"} textAlign={"center"}>
                        Terms of Agreement
                    </Text>
                    <Box pt={"50px"} />
                    <Text color={"graphite"} variant={"body3"} lineHeight={"20px"}>
                        This Terms of Agreement (“Agreement”) is set forth by Dental Branding LLC d/b/a Ascent Practice
                        Growth Strategies (“Company”). Any party agreeing to the terms set forth in this agreement will
                        be considered the “Client”.
                        <br />
                        WHEREAS, Company provides certain business and marketing services for dental practices
                        (collectively, the “Service”);
                        <br />
                        WHEREAS, Client desires to engage Company to provide marketing services and products to promote
                        its business with the general public.
                        <br />
                        NOW, THEREFORE, in consideration of the premises, and of the promises, agreements,
                        representations and warranties hereinafter set forth, and for other good and valuable
                        consideration, the receipt and sufficiency of which are hereby acknowledged, Company and Client
                        hereby agree as follows:
                        <br />
                        <br />
                        1. &emsp; PRODUCTS AND SERVICES. Company shall provide the services and products listed on the
                        “Review Order” screen of the Company app of which this Agreement is a part. Depending on Client
                        selections none, some, or all services and/or products may be subscription based. For such,
                        Company will charge for the cumulative amount of all subscription services and products until
                        Client notifies Company of cancelation in writing. Cancelation may be requested at any time, but
                        Client remains liable for all charges accrued to that time including full monthly charges for
                        the month in which cancelation is requested. Products and services may be canceled one at a time
                        or all at once, however additional terms and conditions may still apply. See Section 4, “Term”
                        below for additional details. Additional details regarding specific products are included in
                        “Exhibit 1” at the end of this Agreement.
                        <br />
                        a. &emsp; Products and services may be added at any time. Company will do its best to attempt to
                        bill new additions of products and services on the initial billing recurrence date. When this is
                        not feasible, as determined by the Company, a different billing date for the additional products
                        and services will be selected and a new, additional billing date will be set. Any new charges
                        for products and services will be subject to all the terms and conditions as set forth in this
                        Agreement.
                        <br />
                        <br />
                        2. &emsp; CONSIDERATION. The payment for the services and products set is on the “Review Order”
                        page.
                        <br />
                        <br />
                        3. &emsp; DELIVERY. Company shall deliver the services and products as set forth on the “Review
                        Order” page.
                        <br />
                        <br />
                        4. &emsp; TERM. This Agreement shall begin on the Effective Date as dated above. The Agreement
                        shall continue until canceled by Client, or terminated pursuant to Section 5 below
                        (“Termination”). Cancelation by Client will be effective 30 days after written notification of
                        cancelation. Any payments due prior to the cancelation date through the date 30 days after
                        Company’s reception of the notice from Client, will be due and payable immediately. See Section
                        5b “Payments Due upon Termination” for additional details.
                        <br />
                        <br />
                        5. &emsp; TERMINATION.
                        <br />
                        a. &emsp; Termination for Breach. Either party may terminate this Agreement prior to its
                        scheduled expiration if the other party materially breaches this Agreement and, if such material
                        breach can be cured, fails to cure such material breach within thirty (30) days after receiving
                        written notice from the non-breaching party describing the alleged breach in reasonable detail,
                        or such longer cure period as the non-breaching party may afford the breaching party.
                        <br />
                        b. &emsp; Payments Due upon Termination. Upon termination of this Agreement or specific
                        projects/products, Company shall determine any additional amounts owed by Client to company.
                        Certain projects/products require significant upfront expense, for example, direct mail
                        projects/products. Client shall be responsible for additional expenses not covered by Client
                        payments to Company as of the termination date, including but not limited to hard costs, labor
                        expense, outside services, research, etc. These additional amounts, if any, shall be due and
                        payable immediately upon termination of this Agreement or specific projects/products.
                        Cancelation of a specific project(s)/product(s) does not invalidate any other product or service
                        or any other aspect of this Agreement. Company will notify Client of due and payable amount as
                        soon as possible and as soon as the determination can be made.
                        <br />
                        <br />
                        6. &emsp; SERVICE MARKS AND INTELLECTUAL PROPERTY. All rights in the names, trademarks, service
                        marks and logos of the Company, its affiliates and the Service (other than the Client Marks)
                        (collectively, the “Company Marks”) are and shall remain the sole and exclusive property of the
                        Company and/or its parent, subsidiary or affiliated entities, and Client has not and will not
                        acquire any proprietary or other rights therein as a result hereof.
                        <br />
                        All rights in the names, trademarks, service marks and logos of the Client, its affiliates and
                        the Service (other than the Company Marks) (collectively, the “Client Marks”) are and shall
                        remain the sole and exclusive property of the Client and/or its parent, subsidiary or affiliated
                        entities. Any and all marketing collateral designed, created and delivered to the Client by
                        Company, will remain the property of the Company until such items have been completely paid to
                        Company. Once paid and delivered to Client, Client then acquires any and all proprietary or
                        other rights therein. Furthermore, the Client grants Company rights and permission to use any
                        and all designs, creative products, and services, etc. in Company’s own marketing efforts
                        without any current or ongoing obligation to Client.
                        <br />
                        <br />
                        7. &emsp; SUBCONTRACTORS. Company expressly reserves the right to use subcontractors for
                        fulfillment of its services and products to Client as it deems necessary in its sole judgment.
                        <br />
                        <br />
                        8. &emsp; GENERAL
                        <br />
                        a. &emsp; Company makes no warranty, guarantee or representation about (i) Company, (ii) its
                        Service and products, and (iii) whether there will be any result or return from any of the
                        services and products therewith. However, Company represents and warrants that it has full right
                        and ability to enter into this Agreement with client.
                        <br />
                        b. &emsp; This Agreement, including the rights under it, may not be assigned or transferred by
                        either party without first obtaining the consent of the other party as appropriate in writing.
                        Any assignment effected in violation of this provision shall be void ab initio and of no force
                        or effect. This Agreement shall be binding upon and inure to the benefit of the parties hereto
                        and their respective heirs, legal representatives, successors and assigns.
                        <br />
                        c. &emsp; Failure of Company or the Client to enforce any of the provisions herein shall not be
                        construed as a general relinquishment or waiver as to that or any other provision.
                        <br />
                        d. &emsp; This Agreement contains the entire agreement between the parties relating to the
                        subject matter herein contained and cancels and supersedes all previous or contemporaneous
                        contracts, agreements, arrangements, representations, warranties and understandings (whether
                        oral or written, express or implied) by, between or among the parties with respect to the
                        subject matter hereof. No change in its terms and provisions shall be effective unless made in
                        writing.
                        <br />
                        e. &emsp; In no event shall either party be liable for any indirect, consequential, special,
                        incidental or punitive damages, including, without limitation, any lost profits of any kind,
                        arising out of or relating to this Agreement.
                        <br />
                        f. &emsp; This Agreement shall be governed by the laws of the state of Utah (regardless of the
                        laws that might be applicable under principles of conflicts of law).
                        <br />
                        g. &emsp; If any dispute arises between us with respect to Company’s services or products and
                        associated fees as well as any other matter concerning this agreement, either the Company or
                        Client shall have the right to require that the dispute be initially submitted to arbitration in
                        Salt Lake County, Utah in accordance with the commercial rules of the American Arbitration
                        Association, before a single arbitrator selected in accordance with those rules or the rules.
                        Each party is equally responsible for the initial arbitration fees. Please note that each party
                        is waiving its right to a jury trial by agreeing to this arbitration provision.
                        <br />
                        h. &emsp; The terms and conditions set forth in Exhibit 1 attached hereto, shall be incorporated
                        herein as part of the Agreement.
                        <br />
                        <br />
                        Agreeing to the terms of agreement and finalizing a purchase will officially execute this
                        Agreement between the Company and Client as of the purchase date.
                        <br />
                        <br />
                        EXHIBIT 1
                        <br />
                        Products and Services
                        <br />
                        <br />
                        Company shall provide Client the services and products as outlined in the “Review Order” page of
                        the Company app to which this document is a part. All products and services shall require
                        specific feedback and information from the Client. This feedback and information shall be
                        delivered to Company no more than three (3) business days after the request has been made by the
                        Company to the Client. Feedback is specific to the Client.
                        <br />
                        Modifications and amendments to specific products and services as stipulated in this agreement,
                        including any exhibit or appendix, shall be enforceable only if they are in writing and are
                        agreed upon by authorized representatives of both parties. Any modifications or amendments shall
                        be subject to the original terms and conditions of this master agreement. Products and services
                        changes include, but are not limited to additions, deletions, changes to deliverable quantities,
                        deliverable dates, etc. Modifications and amendments may include a change to price to Client.
                        <br />
                        <br />
                        Services and Products:
                        <br />
                        • &emsp; Direct mail (DM) may be subject to change and may be beyond the control of the Company.
                        The Company will make its best efforts to meet dates for desired direct mail campaigns. Once
                        direct mail dates are set, they should not be changed. DM date changes, if necessary, may incur
                        an additional cost to the Client and shall be paid in full before DM mailers are put in the US
                        mail.
                        <br />
                        • &emsp; Domain names and transfers, if applicable, are the responsibility of the Client. While
                        Company may give advice and assistance, the ultimate decision making authority and
                        responsibility for domain names and transfers shall rest completely with the Client.
                        <br />
                        • &emsp; Website content shall be provided by the Company. Website content is owned by the
                        Company. Bios of personnel of the Client may be added to the website. Bios will remain the
                        intellectual property of the Client. Upon termination of the recurring payment for the website,
                        the Client may request, in writing, for the Company to provide the website files. Those files do
                        not include any content used in the website other than Bios as previously mentioned. “Go live”
                        timing for the Client’s website shall be determined by the Company.
                        <br />
                        • &emsp; Client’s website may be updated with Client provided content or changes without charge
                        for the duration of the recurring payment for the website. Such changes may include content,
                        photography, updates to personnel, hours of the Client, etc. The Company reserves the right to
                        limit excessive changes as its sole discretion. No changes will be allowed to design, structure,
                        programming or general look and feel of the website.
                        <br /> If the Client wishes to make such changes or upgrades to the Client’s website, the Client
                        may request a special bid to have the Company perform such work as a separate charge outside of
                        this agreement.
                        <br />
                        • &emsp; Search Engine Optimization (SEO) – In the event that the Company does not host the
                        Client’s website, the Client will need to provide the Company access to the website and to
                        relevant accounts such as Google and others. This will include such things as FTP access,
                        usernames and passwords, etc. that will allow the Company to perform the highest level of
                        optimization for the Client’s website.
                        <br />• &emsp; Emails may be included as part of a hosting product. Five (5) emails, secure, but
                        not HIPPA compliant, may be included with a hosting product. Any additional emails will incur an
                        additional fee per email per month. Additional details about these products may be obtained from
                        a Company representative.
                    </Text>
                </Box>
            </Grid>
        </Box>
    );
};
