import _ from "lodash/fp";
import axios from "axios";

export const includesById = (id, collection) => _.flow(_.find(["id", id]), Boolean)(collection);

export const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common["authorization"] = token;
    } else {
        delete axios.defaults.headers.common["authorization"];
    }
};
