import slices from "../slices";
import { api } from "../utilityActions";
import { actions } from "../index";

const getReport = (place, input) =>
    api({
        call: {
            url: "/getReport",
            method: "post",
            data: {
                place,
                input,
            },
        },
        success: (report) => [
            slices.report.actions.set(report),
            actions.createSession(),
            actions.checkIfPracticeExists(report.seo.placeId),
        ],
    });

export default getReport;
