import { createSlice } from "@reduxjs/toolkit";
import { report } from "../../Components/Pages/data";

export default createSlice({
    name: "report",
    initialState: report,
    reducers: {
        set: (state, action) => action.payload,
    },
});
