import { ppcAverages } from "../constants/ppcAverages";

export const getPpcDetails = (cpc, averages, monthlyGoal) => ({
    callConversionRate: averages.conversionRate,
    neededCalls: monthlyGoal / averages.conversionRate,
    clickToCall: averages.ctc,
    neededClicks: monthlyGoal / averages.conversionRate / averages.ctc,
    cpc: cpc,
    adSpend: (monthlyGoal / averages.conversionRate / averages.ctc) * cpc,
    managementFee: ppcAverages.managementFee,
    callTrackingFee: ppcAverages.callTracking,
    totalMonthlyCost:
        (monthlyGoal / averages.conversionRate / averages.ctc) * cpc +
        ppcAverages.managementFee +
        ppcAverages.callTracking,
});

export const getDirectMailDetails = (monthlyGoal, averages) => {
    const neededCalls = monthlyGoal / averages.callConversion;
    const mailersNeeded = neededCalls / averages.mailerToCall;

    const requiredMailings = Math.ceil(15000 / mailersNeeded);
    const finalMailing = (15000 % mailersNeeded) * averages.costPerMailer + averages.managementFee;
    const monthlyPrice = mailersNeeded * averages.costPerMailer + averages.managementFee;

    return {
        monthlyGoal,
        neededCalls,
        requiredMailings,
        mailersNeeded,
        finalMailing,
        monthlyPrice,
        costPerMailer: averages.costPerMailer,
        managementFee: averages.managementFee,
        mailerToCall: averages.mailerToCall,
        callConversion: averages.callConversion,
    };
};
