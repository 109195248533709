import React, { useEffect, useState } from "react";
import { Box, FlexRow, Grid } from "../styledSystemUtilities";
import { Button, Gradient, Input, WelcomeLogo } from "../Atoms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { routerHistory } from "../../routerHistory";
import { FormError } from "../Atoms/FormError";
import entente from "../../entente";
import { useSearchParam } from "react-use";
import { setAuthToken } from "../../utils";
import { useParams } from "react-router-dom";

const shape = {
    loginCode: string().required("Please enter a login code.").trim(),
};

const login = async (loginCode) => {
    const { data } = await entente.post("/open/validate-code", { code: loginCode });

    if (!data.ok) {
        return false;
    }

    localStorage.setItem("jwtToken", data.value);
    setAuthToken(data.value);
    return true;
};

export const LoginCode = () => {
    const [error, setError] = useState(null);
    const schema = object().shape(shape);

    const { loginCode } = useParams();
    console.log(loginCode);

    useEffect(() => {
        login(loginCode).then((success) => {
            if (success) {
                routerHistory.push("/welcome");
            }
        });
    }, [loginCode]);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (form) => {
        const success = await login(form.loginCode);
        if (!success) {
            setError({
                message: "Could not validate code.",
            });
            return;
        }
        routerHistory.push("/welcome");
    };

    return (
        <Box>
            <Gradient />
            <Grid gridTemplateColumns={"1fr minmax(320px, 340px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"48px"} />
                    <FlexRow justifyContent={"center"}>
                        <WelcomeLogo />
                    </FlexRow>
                    <Box pb={"34px"} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            data-testid={"loginCode"}
                            ref={register}
                            name={"loginCode"}
                            placeholder="Login Code"
                            hasError={errors.loginCode}
                        />
                        <FormError error={errors.loginCode} />
                        <FormError error={error} />
                        <Box mb={7} />
                        <Button backgroundColor={"graphiteGray"} onClick={handleSubmit}>
                            submit code
                        </Button>
                        <Box pb={12} />
                    </form>
                </Box>
            </Grid>
        </Box>
    );
};
