export const report = {
    reviews: {
        placeId: "ChIJB-0xGoKGUocRKZreyA0vfMg",
        practiceName: "River City Dental",
        numReviews: 27,
        rating: 4.9,
        topFiveAvgRating: 4.839999999999999,
        topFiveAvgNumReviews: 284.6,
        reviewsRank: 29,
        competitors: [
            {
                practiceName: "Genesis Dental of South Jordan",
                rating: 4.8,
                numReviews: 352,
                placeId: "ChIJdWIE7CuEUocREpGpIU0oH_A",
            },
            {
                practiceName: "Johnson Pediatrics",
                rating: 4.9,
                numReviews: 323,
                placeId: "ChIJB-0xGoKGUocRQU_z4qF5oX8",
            },
            {
                practiceName: "Young Family Dental",
                rating: 4.6,
                numReviews: 268,
                placeId: "ChIJscyBWbOFUocR7TTPAvUU6L8",
            },
            {
                practiceName: "Utah Surgical Arts, Wisdom Teeth and Dental Implants",
                rating: 5,
                numReviews: 257,
                placeId: "ChIJAzrDUb-FUocRtf0EHR7ifbY",
            },
            {
                practiceName: "Riverton Dental",
                rating: 4.9,
                numReviews: 223,
                placeId: "ChIJHTPb34-GUocRaW7etgt2bmI",
            },
            {
                practiceName: "Silverwolf Dental Spa",
                rating: 4.9,
                numReviews: 215,
                placeId: "ChIJP5EYtp2GUocRjJ-Hwl-Eoqw",
            },
            {
                practiceName: "Talbot Orthodontics",
                rating: 4.9,
                numReviews: 190,
                placeId: "ChIJB-0xGoKGUocRngRme8sPFhY",
            },
            {
                practiceName: "Jacobsen Pediatric Dentistry",
                rating: 5,
                numReviews: 174,
                placeId: "ChIJSekSuLKFUocRXLJsjuIoR6E",
            },
            {
                practiceName: "Dawson Pediatric Dentistry & Orthodontics",
                rating: 4.9,
                numReviews: 170,
                placeId: "ChIJ5-rx09WEUocRrUTxINpt6wc",
            },
            {
                practiceName: "Stone Creek Family Dental",
                rating: 4.8,
                numReviews: 157,
                placeId: "ChIJP8SxIoKGUocRHYViWYpljPY",
            },
            {
                practiceName: "Professional Dental",
                rating: 4.9,
                numReviews: 157,
                placeId: "ChIJFR_E09WEUocR9H0-sGxIIRo",
            },
            {
                practiceName: "Abundant Dental Care of Riverton",
                rating: 4.9,
                numReviews: 151,
                placeId: "ChIJe4XQ9jSEUocR_o8nfxJp1C8",
            },
            {
                practiceName: "Bluffdale Dental - Dr. David Babcock",
                rating: 4.8,
                numReviews: 134,
                placeId: "ChIJfVjsQrKGUocRN1vAL7DT92M",
            },
            {
                practiceName: "Herriman Hills Dental",
                rating: 4.9,
                numReviews: 129,
                placeId: "ChIJg0HxyvqEUocRYRAGt5pKfKo",
            },
            {
                practiceName: "Abundant Dental Care of Herriman",
                rating: 4.2,
                numReviews: 129,
                placeId: "ChIJ0yr19bKFUocRTnT8AyTHrMQ",
            },
            {
                practiceName: "Copper Creek Endodontics - Riverton",
                rating: 4.7,
                numReviews: 111,
                placeId: "ChIJhQj85DSEUocRZTlii4QR-iE",
            },
            {
                practiceName: "Herriman Family Dental",
                rating: 4.8,
                numReviews: 94,
                placeId: "ChIJ-wIw7yiFUocRyjVkFEfTVVE",
            },
            {
                practiceName: "Dentists of South Jordan and Orthodontics",
                rating: 4.6,
                numReviews: 86,
                placeId: "ChIJ8ci14paFUocRZTk_gFMJW08",
            },
            {
                practiceName: "Riverton Heights Dental Care",
                rating: 4.7,
                numReviews: 82,
                placeId: "ChIJxTC_20CEUocRCNKiGfR94y4",
            },
            {
                practiceName: "Silver Ridge Dental Care",
                rating: 5,
                numReviews: 80,
                placeId: "ChIJL-HpM-2GUocRsh9G18TlQTk",
            },
            {
                practiceName: "Smiles For Everyone",
                rating: 5,
                numReviews: 79,
                placeId: "ChIJMcG_p0aEUocRO43uvGLdgMY",
            },
            {
                practiceName: "Lighthouse Dental & Orthodontics",
                rating: 4.6,
                numReviews: 67,
                placeId: "ChIJbxwef0SEUocRTQaWJbK39Zs",
            },
            {
                practiceName: "Professional Dental",
                rating: 4.6,
                numReviews: 65,
                placeId: "ChIJJXGWlbqGUocR7RdbwgRGVTM",
            },
            {
                practiceName: "Welton Orthodontics",
                rating: 4.9,
                numReviews: 57,
                placeId: "ChIJ1Y6IL5eATYcRacTsSnntmOA",
            },
            {
                practiceName: "Rose Creek Dental",
                rating: 4.7,
                numReviews: 44,
                placeId: "ChIJl5PnuLKFUocRF1xNA8sq7HA",
            },
            {
                practiceName: "South Pointe Dental",
                rating: 4.9,
                numReviews: 40,
                placeId: "ChIJh73LBeiAUocRU1pBgQvaNZE",
            },
            {
                practiceName: "Desert Rose Dental",
                rating: 4.4,
                numReviews: 33,
                placeId: "ChIJxWjo9zSEUocRrH4zw8cXBF8",
            },
            {
                practiceName: "Platinum Dental Care - Daybreak",
                rating: 4.8,
                numReviews: 31,
                placeId: "ChIJVwtgkoGFUocRc74cnJUKNVI",
            },
            {
                practiceName: "Dentists of Riverton",
                rating: 5,
                numReviews: 19,
                placeId: "ChIJse1YZWiFUocRcQ1pOylT86Q",
            },
            {
                practiceName: "Beautiful Smiles Dental Care",
                rating: 4.9,
                numReviews: 16,
                placeId: "ChIJ75ZYHI6GUocRzMdHC1s5AYA",
            },
            {
                practiceName: "Lloyd, Jon W DDS",
                rating: 5,
                numReviews: 14,
                placeId: "ChIJIwYzsJKGUocRzVxyG-cYCgM",
            },
            {
                practiceName: "Copper Creek Endodontics, Curtis J Brimley DMD MBA MS",
                rating: 4.9,
                numReviews: 14,
                placeId: "ChIJBQn85DSEUocRaEuc1fyoCrw",
            },
            {
                practiceName: "Mandana Mahdi DDS",
                rating: 5,
                numReviews: 10,
                placeId: "ChIJ75ZYHI6GUocRNIujFcZ6P2Q",
            },
            {
                practiceName: "South Hills Dental Specialists",
                rating: 5,
                numReviews: 10,
                placeId: "ChIJKfWSvkSEUocRtT3zaWrHonk",
            },
            {
                practiceName: "South Jordan Dental",
                rating: 3.7,
                numReviews: 10,
                placeId: "ChIJy8bdoyKGUocRkzGNVi4lLo8",
            },
            {
                practiceName: "Dr. Bradley E. Devries",
                rating: 5,
                numReviews: 5,
                placeId: "ChIJ4bE0sJKGUocRuUMnP-VnDuQ",
            },
            {
                practiceName: "Pugh B Charles DDS",
                rating: 4.2,
                numReviews: 5,
                placeId: "ChIJzfYRHJCGUocRrZ1v_6vQwRM",
            },
            {
                practiceName: "Dr. Brian Borg, DMD",
                rating: 5,
                numReviews: 3,
                placeId: "ChIJxTC_20CEUocRcVrUSbzOKTQ",
            },
            {
                practiceName: "Dr. Sam B. Richey, DDS",
                rating: 5,
                numReviews: 3,
                placeId: "ChIJY1tjeUSEUocRX0KOJMS9csM",
            },
            {
                practiceName: "Riverton Family Dentistry",
                rating: 5,
                numReviews: 2,
                placeId: "ChIJUzAzkZKGUocRx3EZY_1T4MI",
            },
            {
                practiceName: "Southridge Clinic - Dentistry",
                rating: 5,
                numReviews: 1,
                placeId: "ChIJ37txCG2FUocRdYm_9hl_HS4",
            },
            {
                practiceName: "A A Dental Care: Christensen Craig M DDS",
                rating: 5,
                numReviews: 1,
                placeId: "ChIJsy8zkZKGUocRISLK8dCIMc8",
            },
            {
                practiceName: "Jay W Silcox Inc",
                rating: 4,
                numReviews: 1,
                placeId: "ChIJIwYzsJKGUocRnaLMov9LC4E",
            },
            {
                practiceName: "CDT Dental Lab Inc",
                rating: 5,
                numReviews: 1,
                placeId: "ChIJw9It6qeAUocR6nJhoxyAqUk",
            },
            {
                practiceName: "Quick Care Dental Riverton",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJNwDm9jSEUocRUkQqHmdLrQ8",
            },
            {
                practiceName: "Dr. Joel L. Howarth, DDS",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJvXjQgi6EUocR5LUvOoStRZc",
            },
            {
                practiceName: "Brimley Curtis DDS",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJe4XQ9jSEUocR6Wy1cV7geNY",
            },
            {
                practiceName: "Wisdom Teeth Only By Specialists",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJaVlf9zSEUocRfOlrrN4_Z9k",
            },
            {
                practiceName: "Soutnwest District Orthodontics",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJ3ZebOr-FUocReCwpDjJepM0",
            },
            {
                practiceName: "River Crossing Dental",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJj2io1IOFUocRfAXHw7CLpHA",
            },
            {
                practiceName: "Mathew Smile Dental",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJGSwliC-HUocRDVxOuPRHg8Q",
            },
            {
                practiceName: "Troy L. Sargent, DMD",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJqcyLFLSFUocRjlUsbxKoyj0",
            },
            {
                practiceName: "A A Dental Care",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJUzAzkZKGUocRN2WjJqkwP80",
            },
            {
                practiceName: "Smiles for Everyone",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJIUh_XtCFUocRU6qmCeVB9vM",
            },
            {
                practiceName: "South West Oral & Facial Surgery",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJMdiGTr-FUocRMA3QM5qICAI",
            },
            {
                practiceName: "Greystone Dental Specialty Lab",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJDwCGL6KGUocR_YUG37aD39c",
            },
            {
                practiceName: "Daniel R. Dowdle, DMD, PC",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJl5PnuLKFUocRWGB5Vr4qTow",
            },
            {
                practiceName: "South Jordan Dentists",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJNdOTpyKGUocRj1XAYfbgAHk",
            },
            {
                practiceName: "Draper Dentist",
                rating: 0,
                numReviews: 0,
                placeId: "ChIJN7G7vgiHUocRIg0cYr9vZSY",
            },
        ],
    },
    seo: {
        practiceName: "River City Dental",
        placeId: "ChIJB-0xGoKGUocRKZreyA0vfMg",
        rankingsFromGoogleSearches: [
            {
                googleSearchTerm: "dentist",
                rank: 16,
                searchVolume: 2100
            },
            {
                googleSearchTerm: "dental",
                rank: 12,
                searchVolume: 2100
            },
            {
                googleSearchTerm: "dental office",
                rank: 13,
                searchVolume: 2100
            },
            {
                googleSearchTerm: "best dentist",
                rank: 11,
                searchVolume: 2100
            },
            {
                googleSearchTerm: "emergency dentist",
                rank: 5,
                searchVolume: 2100
            },
        ],
        averageRank: 11.4,
        overallRank: 9,
        competitors: [
            {
                practiceName: "Riverton Dental",
                placeId: "ChIJHTPb34-GUocRaW7etgt2bmI",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 1,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 1,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 2,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 5,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 1,
                    },
                ],
                averageRank: 2,
                overallRank: 1,
            },
            {
                practiceName: "Abundant Dental Care of Riverton",
                placeId: "ChIJe4XQ9jSEUocR_o8nfxJp1C8",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 5,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 5,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 3,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 9,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 2,
                    },
                ],
                averageRank: 4.8,
                overallRank: 2,
            },
            {
                practiceName: "Riverton Heights Dental Care",
                placeId: "ChIJxTC_20CEUocRCNKiGfR94y4",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 2,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 4,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 5,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 19,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 7,
                    },
                ],
                averageRank: 7.4,
                overallRank: 3,
            },
            {
                practiceName: "Stone Creek Family Dental",
                placeId: "ChIJP8SxIoKGUocRHYViWYpljPY",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 4,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 6,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 10,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 22,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 4,
                    },
                ],
                averageRank: 9.2,
                overallRank: 4,
            },
            {
                practiceName: "Professional Dental",
                placeId: "ChIJJXGWlbqGUocR7RdbwgRGVTM",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 10,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 2,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 7,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 25,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 3,
                    },
                ],
                averageRank: 9.4,
                overallRank: 5,
            },
            {
                practiceName: "Bluffdale Dental - Dr. David Babcock",
                placeId: "ChIJfVjsQrKGUocRN1vAL7DT92M",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 3,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 3,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 21,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 20,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 6,
                    },
                ],
                averageRank: 10.6,
                overallRank: 6,
            },
            {
                practiceName: "Lighthouse Dental & Orthodontics",
                placeId: "ChIJbxwef0SEUocRTQaWJbK39Zs",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 6,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 7,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 6,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 24,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 12,
                    },
                ],
                averageRank: 11,
                overallRank: 7,
            },
            {
                practiceName: "Silver Ridge Dental Care",
                placeId: "ChIJL-HpM-2GUocRsh9G18TlQTk",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 9,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 11,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 11,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 1,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 23,
                    },
                ],
                averageRank: 11,
                overallRank: 8,
            },
            {
                practiceName: "Desert Rose Dental",
                placeId: "ChIJxWjo9zSEUocRrH4zw8cXBF8",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 7,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 8,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 12,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 26,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 9,
                    },
                ],
                averageRank: 12.4,
                overallRank: 10,
            },
            {
                practiceName: "Johnson Pediatrics",
                placeId: "ChIJB-0xGoKGUocRQU_z4qF5oX8",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 15,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 14,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 22,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 8,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 19,
                    },
                ],
                averageRank: 15.6,
                overallRank: 11,
            },
            {
                practiceName: "Silverwolf Dental Spa",
                placeId: "ChIJP5EYtp2GUocRjJ-Hwl-Eoqw",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 12,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 10,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 23,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 10,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 25,
                    },
                ],
                averageRank: 16,
                overallRank: 12,
            },
            {
                practiceName: "Smiles For Everyone",
                placeId: "ChIJMcG_p0aEUocRO43uvGLdgMY",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 11,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 9,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 1,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 2,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 16.8,
                overallRank: 13,
            },
            {
                practiceName: "Young Family Dental",
                placeId: "ChIJscyBWbOFUocR7TTPAvUU6L8",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 13,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 16,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 20,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 27,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 29,
                    },
                ],
                averageRank: 21,
                overallRank: 14,
            },
            {
                practiceName: "Southridge Clinic - Dentistry",
                placeId: "ChIJ37txCG2FUocRdYm_9hl_HS4",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 18,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 18,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 4,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 16,
                    },
                ],
                averageRank: 23.4,
                overallRank: 15,
            },
            {
                practiceName: "Dentists of Riverton",
                placeId: "ChIJse1YZWiFUocRcQ1pOylT86Q",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 36,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 20,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 50,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 6,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 17,
                    },
                ],
                averageRank: 25.8,
                overallRank: 16,
            },
            {
                practiceName: "Lloyd, Jon W DDS",
                placeId: "ChIJIwYzsJKGUocRzVxyG-cYCgM",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 21,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 27,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 32,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 3,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 28.8,
                overallRank: 17,
            },
            {
                practiceName: "Rose Creek Dental",
                placeId: "ChIJl5PnuLKFUocRF1xNA8sq7HA",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 42,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 39,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 17,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 38,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 14,
                    },
                ],
                averageRank: 30,
                overallRank: 18,
            },
            {
                practiceName: "Dr. Bradley E. Devries",
                placeId: "ChIJ4bE0sJKGUocRuUMnP-VnDuQ",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 14,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 17,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 25,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 35,
                    },
                ],
                averageRank: 30.4,
                overallRank: 19,
            },
            {
                practiceName: "Mandana Mahdi DDS",
                placeId: "ChIJ75ZYHI6GUocRNIujFcZ6P2Q",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 34,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 34,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 4,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 21,
                    },
                ],
                averageRank: 30.8,
                overallRank: 20,
            },
            {
                practiceName: "Riverton Family Dentistry",
                placeId: "ChIJUzAzkZKGUocRx3EZY_1T4MI",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 8,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 15,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 24,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 48,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 31.2,
                overallRank: 21,
            },
            {
                practiceName: "Herriman Hills Dental",
                placeId: "ChIJg0HxyvqEUocRYRAGt5pKfKo",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 48,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 48,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 14,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 34,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 15,
                    },
                ],
                averageRank: 31.8,
                overallRank: 22,
            },
            {
                practiceName: "Genesis Dental of South Jordan",
                placeId: "ChIJdWIE7CuEUocREpGpIU0oH_A",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 45,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 41,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 26,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 40,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 10,
                    },
                ],
                averageRank: 32.4,
                overallRank: 23,
            },
            {
                practiceName: "Dr. Sam B. Richey, DDS",
                placeId: "ChIJY1tjeUSEUocRX0KOJMS9csM",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 27,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 29,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 37,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 11,
                    },
                ],
                averageRank: 33,
                overallRank: 24,
            },
            {
                practiceName: "South Pointe Dental",
                placeId: "ChIJh73LBeiAUocRU1pBgQvaNZE",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 43,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 40,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 19,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 17,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 36,
                overallRank: 25,
            },
            {
                practiceName: "Beautiful Smiles Dental Care",
                placeId: "ChIJ75ZYHI6GUocRzMdHC1s5AYA",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 35,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 38,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 35,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 12,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 36.2,
                overallRank: 26,
            },
            {
                practiceName: "River Crossing Dental",
                placeId: "ChIJj2io1IOFUocRfAXHw7CLpHA",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 29,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 22,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 9,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 36.4,
                overallRank: 27,
            },
            {
                practiceName: "Dr. Brian Borg, DMD",
                placeId: "ChIJxTC_20CEUocRcVrUSbzOKTQ",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 25,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 31,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 46,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 20,
                    },
                ],
                averageRank: 36.6,
                overallRank: 28,
            },
            {
                practiceName: "Smiles for Everyone",
                placeId: "ChIJIUh_XtCFUocRU6qmCeVB9vM",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 33,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 25,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 8,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 37.6,
                overallRank: 29,
            },
            {
                practiceName: "Dr. Joel L. Howarth, DDS",
                placeId: "ChIJvXjQgi6EUocR5LUvOoStRZc",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 20,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 21,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 33,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 39.2,
                overallRank: 30,
            },
            {
                practiceName: "Welton Orthodontics",
                placeId: "ChIJ1Y6IL5eATYcRacTsSnntmOA",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 39,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 49,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 47,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 21,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 40,
                    },
                ],
                averageRank: 39.2,
                overallRank: 31,
            },
            {
                practiceName: "Troy L. Sargent, DMD",
                placeId: "ChIJqcyLFLSFUocRjlUsbxKoyj0",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 31,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 37,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 28,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 41,
                    },
                ],
                averageRank: 39.6,
                overallRank: 32,
            },
            {
                practiceName: "Pugh B Charles DDS",
                placeId: "ChIJzfYRHJCGUocRrZ1v_6vQwRM",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 19,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 28,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 31,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 40,
                overallRank: 33,
            },
            {
                practiceName: "South West Oral & Facial Surgery",
                placeId: "ChIJMdiGTr-FUocRMA3QM5qICAI",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 37,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 36,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 15,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 42,
                overallRank: 34,
            },
            {
                practiceName: "Jay W Silcox Inc",
                placeId: "ChIJIwYzsJKGUocRnaLMov9LC4E",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 28,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 24,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 38,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 42.4,
                overallRank: 35,
            },
            {
                practiceName: "Quick Care Dental Riverton",
                placeId: "ChIJNwDm9jSEUocRUkQqHmdLrQ8",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 17,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 13,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 42.6,
                overallRank: 36,
            },
            {
                practiceName: "Copper Creek Endodontics, Curtis J Brimley DMD MBA MS",
                placeId: "ChIJBQn85DSEUocRaEuc1fyoCrw",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 38,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 42,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 58,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 14,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 42.6,
                overallRank: 37,
            },
            {
                practiceName: "South Jordan Dental",
                placeId: "ChIJy8bdoyKGUocRkzGNVi4lLo8",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 51,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 51,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 41,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 13,
                    },
                ],
                averageRank: 43.4,
                overallRank: 38,
            },
            {
                practiceName: "Wisdom Teeth Only By Specialists",
                placeId: "ChIJaVlf9zSEUocRfOlrrN4_Z9k",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 24,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 19,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 45.2,
                overallRank: 39,
            },
            {
                practiceName: "A A Dental Care: Christensen Craig M DDS",
                placeId: "ChIJsy8zkZKGUocRISLK8dCIMc8",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 22,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 23,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 45.6,
                overallRank: 40,
            },
            {
                practiceName: "Daniel R. Dowdle, DMD, PC",
                placeId: "ChIJl5PnuLKFUocRWGB5Vr4qTow",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 47,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 47,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 51,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 22,
                    },
                ],
                averageRank: 45.6,
                overallRank: 41,
            },
            {
                practiceName: "Jacobsen Pediatric Dentistry",
                placeId: "ChIJSekSuLKFUocRXLJsjuIoR6E",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 40,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 7,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 46,
                overallRank: 42,
            },
            {
                practiceName: "Brimley Curtis DDS",
                placeId: "ChIJe4XQ9jSEUocR6Wy1cV7geNY",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 23,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 26,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 46.4,
                overallRank: 43,
            },
            {
                practiceName: "Talbot Orthodontics",
                placeId: "ChIJB-0xGoKGUocRngRme8sPFhY",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 50,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 59,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 59,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 23,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 42,
                    },
                ],
                averageRank: 46.6,
                overallRank: 44,
            },
            {
                practiceName: "Utah Surgical Arts, Wisdom Teeth and Dental Implants",
                placeId: "ChIJAzrDUb-FUocRtf0EHR7ifbY",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 60,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 52,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 13,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 47,
                    },
                ],
                averageRank: 46.6,
                overallRank: 45,
            },
            {
                practiceName: "Mathew Smile Dental",
                placeId: "ChIJGSwliC-HUocRDVxOuPRHg8Q",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 30,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 30,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 48.6,
                overallRank: 46,
            },
            {
                practiceName: "A A Dental Care",
                placeId: "ChIJUzAzkZKGUocRN2WjJqkwP80",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 32,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 32,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 49.4,
                overallRank: 47,
            },
            {
                practiceName: "South Hills Dental Specialists",
                placeId: "ChIJKfWSvkSEUocRtT3zaWrHonk",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 46,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 18,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 49.4,
                overallRank: 48,
            },
            {
                practiceName: "Dentists of South Jordan and Orthodontics",
                placeId: "ChIJ8ci14paFUocRZTk_gFMJW08",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 44,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 46,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 36,
                    },
                ],
                averageRank: 49.6,
                overallRank: 49,
            },
            {
                practiceName: "Professional Dental",
                placeId: "ChIJFR_E09WEUocR9H0-sGxIIRo",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 56,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 53,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 53,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 30,
                    },
                ],
                averageRank: 50.6,
                overallRank: 50,
            },
            {
                practiceName: "Greystone Dental Specialty Lab",
                placeId: "ChIJDwCGL6KGUocR_YUG37aD39c",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 41,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 35,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 51.8,
                overallRank: 51,
            },
            {
                practiceName: "Herriman Family Dental",
                placeId: "ChIJ-wIw7yiFUocRyjVkFEfTVVE",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 59,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 57,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 29,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 53.4,
                overallRank: 52,
            },
            {
                practiceName: "Dawson Pediatric Dentistry & Orthodontics",
                placeId: "ChIJ5-rx09WEUocRrUTxINpt6wc",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 54,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 32,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 53.8,
                overallRank: 53,
            },
            {
                practiceName: "Soutnwest District Orthodontics",
                placeId: "ChIJ3ZebOr-FUocReCwpDjJepM0",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 26,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 54,
                overallRank: 54,
            },
            {
                practiceName: "Abundant Dental Care of Herriman",
                placeId: "ChIJ0yr19bKFUocRTnT8AyTHrMQ",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 53,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 34,
                    },
                ],
                averageRank: 54,
                overallRank: 55,
            },
            {
                practiceName: "CDT Dental Lab Inc",
                placeId: "ChIJw9It6qeAUocR6nJhoxyAqUk",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 49,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 44,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 55.2,
                overallRank: 56,
            },
            {
                practiceName: "Platinum Dental Care - Daybreak",
                placeId: "ChIJVwtgkoGFUocRc74cnJUKNVI",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 52,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 50,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 52,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 55.2,
                overallRank: 57,
            },
            {
                practiceName: "South Jordan Dentists",
                placeId: "ChIJNdOTpyKGUocRj1XAYfbgAHk",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 55,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 54,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 58.4,
                overallRank: 58,
            },
            {
                practiceName: "Draper Dentist",
                placeId: "ChIJN7G7vgiHUocRIg0cYr9vZSY",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 58,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 55,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 59.2,
                overallRank: 59,
            },
            {
                practiceName: "Copper Creek Endodontics - Riverton",
                placeId: "ChIJhQj85DSEUocRZTlii4QR-iE",
                rankingsFromGoogleSearches: [
                    {
                        googleSearchTerm: "dentist",
                        rank: 57,
                    },
                    {
                        googleSearchTerm: "dental",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "dental office",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "best dentist",
                        rank: 61,
                    },
                    {
                        googleSearchTerm: "emergency dentist",
                        rank: 61,
                    },
                ],
                averageRank: 60.2,
                overallRank: 60,
            },
        ],
        numCompetitors: 59,
    },
    ppc: {
        keywords: [
            {
                cpc: 5.780625,
                keyword: "dentist",
            },
            {
                cpc: 5.780625,
                keyword: "dental",
            },
            {
                cpc: 21.955177499999998,
                keyword: "emergency dentist",
            },
        ],
    },
    charts: {
        reviewsChartData: {
            xDomain: {
                max: 352,
                min: 27,
            },
            yDomain: {
                max: 5,
                min: 4.2,
            },
            avgRating: 4.814285714285714,
            avgNumReviews: 167.0952380952381,
            userData: [
                {
                    x: 27,
                    y: 4.9,
                    practiceName: "River City Dental",
                    placeId: "ChIJB-0xGoKGUocRKZreyA0vfMg",
                },
            ],
            topCompData: [
                {
                    x: 352,
                    y: 4.8,
                    placeId: "ChIJdWIE7CuEUocREpGpIU0oH_A",
                    practiceName: "Genesis Dental of South Jordan",
                },
            ],
            compData: [
                {
                    x: 323,
                    y: 4.9,
                    placeId: "ChIJB-0xGoKGUocRQU_z4qF5oX8",
                    practiceName: "Johnson Pediatrics",
                },
                {
                    x: 268,
                    y: 4.6,
                    placeId: "ChIJscyBWbOFUocR7TTPAvUU6L8",
                    practiceName: "Young Family Dental",
                },
                {
                    x: 257,
                    y: 5,
                    placeId: "ChIJAzrDUb-FUocRtf0EHR7ifbY",
                    practiceName: "Utah Surgical Arts, Wisdom Teeth and Dental Implants",
                },
                {
                    x: 223,
                    y: 4.9,
                    placeId: "ChIJHTPb34-GUocRaW7etgt2bmI",
                    practiceName: "Riverton Dental",
                },
                {
                    x: 215,
                    y: 4.9,
                    placeId: "ChIJP5EYtp2GUocRjJ-Hwl-Eoqw",
                    practiceName: "Silverwolf Dental Spa",
                },
                {
                    x: 190,
                    y: 4.9,
                    placeId: "ChIJB-0xGoKGUocRngRme8sPFhY",
                    practiceName: "Talbot Orthodontics",
                },
                {
                    x: 174,
                    y: 5,
                    placeId: "ChIJSekSuLKFUocRXLJsjuIoR6E",
                    practiceName: "Jacobsen Pediatric Dentistry",
                },
                {
                    x: 170,
                    y: 4.9,
                    placeId: "ChIJ5-rx09WEUocRrUTxINpt6wc",
                    practiceName: "Dawson Pediatric Dentistry & Orthodontics",
                },
                {
                    x: 157,
                    y: 4.8,
                    placeId: "ChIJP8SxIoKGUocRHYViWYpljPY",
                    practiceName: "Stone Creek Family Dental",
                },
                {
                    x: 157,
                    y: 4.9,
                    placeId: "ChIJFR_E09WEUocR9H0-sGxIIRo",
                    practiceName: "Professional Dental",
                },
                {
                    x: 151,
                    y: 4.9,
                    placeId: "ChIJe4XQ9jSEUocR_o8nfxJp1C8",
                    practiceName: "Abundant Dental Care of Riverton",
                },
                {
                    x: 134,
                    y: 4.8,
                    placeId: "ChIJfVjsQrKGUocRN1vAL7DT92M",
                    practiceName: "Bluffdale Dental - Dr. David Babcock",
                },
                {
                    x: 129,
                    y: 4.9,
                    placeId: "ChIJg0HxyvqEUocRYRAGt5pKfKo",
                    practiceName: "Herriman Hills Dental",
                },
                {
                    x: 129,
                    y: 4.2,
                    placeId: "ChIJ0yr19bKFUocRTnT8AyTHrMQ",
                    practiceName: "Abundant Dental Care of Herriman",
                },
                {
                    x: 111,
                    y: 4.7,
                    placeId: "ChIJhQj85DSEUocRZTlii4QR-iE",
                    practiceName: "Copper Creek Endodontics - Riverton",
                },
                {
                    x: 94,
                    y: 4.8,
                    placeId: "ChIJ-wIw7yiFUocRyjVkFEfTVVE",
                    practiceName: "Herriman Family Dental",
                },
                {
                    x: 86,
                    y: 4.6,
                    placeId: "ChIJ8ci14paFUocRZTk_gFMJW08",
                    practiceName: "Dentists of South Jordan and Orthodontics",
                },
                {
                    x: 82,
                    y: 4.7,
                    placeId: "ChIJxTC_20CEUocRCNKiGfR94y4",
                    practiceName: "Riverton Heights Dental Care",
                },
                {
                    x: 80,
                    y: 5,
                    placeId: "ChIJL-HpM-2GUocRsh9G18TlQTk",
                    practiceName: "Silver Ridge Dental Care",
                },
                {
                    x: 79,
                    y: 5,
                    placeId: "ChIJMcG_p0aEUocRO43uvGLdgMY",
                    practiceName: "Smiles For Everyone",
                },
            ],
        },
        seoChartData: {
            box: {
                lil: {
                    x: 21,
                    y: 20,
                },
                big: {
                    x: 9,
                    y: 8,
                },
            },
            xDomain: {
                max: 30,
                min: 1,
            },
            avgRank: 22.60666666666667,
            yDomain: {
                max: 39.2,
                min: 2,
            },
            userData: [
                {
                    placeId: "ChIJB-0xGoKGUocRKZreyA0vfMg",
                    practiceName: "River City Dental",
                    x: 9,
                    y: 11.4,
                },
            ],
            topCompData: [
                {
                    practiceName: "Riverton Dental",
                    placeId: "ChIJHTPb34-GUocRaW7etgt2bmI",
                    x: 1,
                    y: 2,
                },
            ],
            competitorArray: [
                {
                    practiceName: "Abundant Dental Care of Riverton",
                    placeId: "ChIJe4XQ9jSEUocR_o8nfxJp1C8",
                    x: 2,
                    y: 4.8,
                },
                {
                    practiceName: "Riverton Heights Dental Care",
                    placeId: "ChIJxTC_20CEUocRCNKiGfR94y4",
                    x: 3,
                    y: 7.4,
                },
                {
                    practiceName: "Stone Creek Family Dental",
                    placeId: "ChIJP8SxIoKGUocRHYViWYpljPY",
                    x: 4,
                    y: 9.2,
                },
                {
                    practiceName: "Professional Dental",
                    placeId: "ChIJJXGWlbqGUocR7RdbwgRGVTM",
                    x: 5,
                    y: 9.4,
                },
                {
                    practiceName: "Bluffdale Dental - Dr. David Babcock",
                    placeId: "ChIJfVjsQrKGUocRN1vAL7DT92M",
                    x: 6,
                    y: 10.6,
                },
                {
                    practiceName: "Lighthouse Dental & Orthodontics",
                    placeId: "ChIJbxwef0SEUocRTQaWJbK39Zs",
                    x: 7,
                    y: 11,
                },
                {
                    practiceName: "Silver Ridge Dental Care",
                    placeId: "ChIJL-HpM-2GUocRsh9G18TlQTk",
                    x: 8,
                    y: 11,
                },
                {
                    practiceName: "Desert Rose Dental",
                    placeId: "ChIJxWjo9zSEUocRrH4zw8cXBF8",
                    x: 10,
                    y: 12.4,
                },
                {
                    practiceName: "Johnson Pediatrics",
                    placeId: "ChIJB-0xGoKGUocRQU_z4qF5oX8",
                    x: 11,
                    y: 15.6,
                },
                {
                    practiceName: "Silverwolf Dental Spa",
                    placeId: "ChIJP5EYtp2GUocRjJ-Hwl-Eoqw",
                    x: 12,
                    y: 16,
                },
                {
                    practiceName: "Smiles For Everyone",
                    placeId: "ChIJMcG_p0aEUocRO43uvGLdgMY",
                    x: 13,
                    y: 16.8,
                },
                {
                    practiceName: "Young Family Dental",
                    placeId: "ChIJscyBWbOFUocR7TTPAvUU6L8",
                    x: 14,
                    y: 21,
                },
                {
                    practiceName: "Southridge Clinic - Dentistry",
                    placeId: "ChIJ37txCG2FUocRdYm_9hl_HS4",
                    x: 15,
                    y: 23.4,
                },
                {
                    practiceName: "Dentists of Riverton",
                    placeId: "ChIJse1YZWiFUocRcQ1pOylT86Q",
                    x: 16,
                    y: 25.8,
                },
                {
                    practiceName: "Lloyd, Jon W DDS",
                    placeId: "ChIJIwYzsJKGUocRzVxyG-cYCgM",
                    x: 17,
                    y: 28.8,
                },
                {
                    practiceName: "Rose Creek Dental",
                    placeId: "ChIJl5PnuLKFUocRF1xNA8sq7HA",
                    x: 18,
                    y: 30,
                },
                {
                    practiceName: "Dr. Bradley E. Devries",
                    placeId: "ChIJ4bE0sJKGUocRuUMnP-VnDuQ",
                    x: 19,
                    y: 30.4,
                },
                {
                    practiceName: "Mandana Mahdi DDS",
                    placeId: "ChIJ75ZYHI6GUocRNIujFcZ6P2Q",
                    x: 20,
                    y: 30.8,
                },
                {
                    practiceName: "Riverton Family Dentistry",
                    placeId: "ChIJUzAzkZKGUocRx3EZY_1T4MI",
                    x: 21,
                    y: 31.2,
                },
                {
                    practiceName: "Herriman Hills Dental",
                    placeId: "ChIJg0HxyvqEUocRYRAGt5pKfKo",
                    x: 22,
                    y: 31.8,
                },
                {
                    practiceName: "Genesis Dental of South Jordan",
                    placeId: "ChIJdWIE7CuEUocREpGpIU0oH_A",
                    x: 23,
                    y: 32.4,
                },
                {
                    practiceName: "Dr. Sam B. Richey, DDS",
                    placeId: "ChIJY1tjeUSEUocRX0KOJMS9csM",
                    x: 24,
                    y: 33,
                },
                {
                    practiceName: "South Pointe Dental",
                    placeId: "ChIJh73LBeiAUocRU1pBgQvaNZE",
                    x: 25,
                    y: 36,
                },
                {
                    practiceName: "Beautiful Smiles Dental Care",
                    placeId: "ChIJ75ZYHI6GUocRzMdHC1s5AYA",
                    x: 26,
                    y: 36.2,
                },
                {
                    practiceName: "River Crossing Dental",
                    placeId: "ChIJj2io1IOFUocRfAXHw7CLpHA",
                    x: 27,
                    y: 36.4,
                },
                {
                    practiceName: "Dr. Brian Borg, DMD",
                    placeId: "ChIJxTC_20CEUocRcVrUSbzOKTQ",
                    x: 28,
                    y: 36.6,
                },
                {
                    practiceName: "Smiles for Everyone",
                    placeId: "ChIJIUh_XtCFUocRU6qmCeVB9vM",
                    x: 29,
                    y: 37.6,
                },
                {
                    practiceName: "Dr. Joel L. Howarth, DDS",
                    placeId: "ChIJvXjQgi6EUocR5LUvOoStRZc",
                    x: 30,
                    y: 39.2,
                },
            ],
            xTicks: [30, 20, 10, 1],
            yTicks: [30, 20, 10],
            everyone: [
                {
                    placeId: "ChIJB-0xGoKGUocRKZreyA0vfMg",
                    practiceName: "River City Dental",
                    x: 9,
                    y: 11.4,
                },
                {
                    practiceName: "Riverton Dental",
                    placeId: "ChIJHTPb34-GUocRaW7etgt2bmI",
                    x: 1,
                    y: 2,
                },
                {
                    practiceName: "Abundant Dental Care of Riverton",
                    placeId: "ChIJe4XQ9jSEUocR_o8nfxJp1C8",
                    x: 2,
                    y: 4.8,
                },
                {
                    practiceName: "Riverton Heights Dental Care",
                    placeId: "ChIJxTC_20CEUocRCNKiGfR94y4",
                    x: 3,
                    y: 7.4,
                },
                {
                    practiceName: "Stone Creek Family Dental",
                    placeId: "ChIJP8SxIoKGUocRHYViWYpljPY",
                    x: 4,
                    y: 9.2,
                },
                {
                    practiceName: "Professional Dental",
                    placeId: "ChIJJXGWlbqGUocR7RdbwgRGVTM",
                    x: 5,
                    y: 9.4,
                },
                {
                    practiceName: "Bluffdale Dental - Dr. David Babcock",
                    placeId: "ChIJfVjsQrKGUocRN1vAL7DT92M",
                    x: 6,
                    y: 10.6,
                },
                {
                    practiceName: "Lighthouse Dental & Orthodontics",
                    placeId: "ChIJbxwef0SEUocRTQaWJbK39Zs",
                    x: 7,
                    y: 11,
                },
                {
                    practiceName: "Silver Ridge Dental Care",
                    placeId: "ChIJL-HpM-2GUocRsh9G18TlQTk",
                    x: 8,
                    y: 11,
                },
                {
                    practiceName: "Desert Rose Dental",
                    placeId: "ChIJxWjo9zSEUocRrH4zw8cXBF8",
                    x: 10,
                    y: 12.4,
                },
                {
                    practiceName: "Johnson Pediatrics",
                    placeId: "ChIJB-0xGoKGUocRQU_z4qF5oX8",
                    x: 11,
                    y: 15.6,
                },
                {
                    practiceName: "Silverwolf Dental Spa",
                    placeId: "ChIJP5EYtp2GUocRjJ-Hwl-Eoqw",
                    x: 12,
                    y: 16,
                },
                {
                    practiceName: "Smiles For Everyone",
                    placeId: "ChIJMcG_p0aEUocRO43uvGLdgMY",
                    x: 13,
                    y: 16.8,
                },
                {
                    practiceName: "Young Family Dental",
                    placeId: "ChIJscyBWbOFUocR7TTPAvUU6L8",
                    x: 14,
                    y: 21,
                },
                {
                    practiceName: "Southridge Clinic - Dentistry",
                    placeId: "ChIJ37txCG2FUocRdYm_9hl_HS4",
                    x: 15,
                    y: 23.4,
                },
                {
                    practiceName: "Dentists of Riverton",
                    placeId: "ChIJse1YZWiFUocRcQ1pOylT86Q",
                    x: 16,
                    y: 25.8,
                },
                {
                    practiceName: "Lloyd, Jon W DDS",
                    placeId: "ChIJIwYzsJKGUocRzVxyG-cYCgM",
                    x: 17,
                    y: 28.8,
                },
                {
                    practiceName: "Rose Creek Dental",
                    placeId: "ChIJl5PnuLKFUocRF1xNA8sq7HA",
                    x: 18,
                    y: 30,
                },
                {
                    practiceName: "Dr. Bradley E. Devries",
                    placeId: "ChIJ4bE0sJKGUocRuUMnP-VnDuQ",
                    x: 19,
                    y: 30.4,
                },
                {
                    practiceName: "Mandana Mahdi DDS",
                    placeId: "ChIJ75ZYHI6GUocRNIujFcZ6P2Q",
                    x: 20,
                    y: 30.8,
                },
                {
                    practiceName: "Riverton Family Dentistry",
                    placeId: "ChIJUzAzkZKGUocRx3EZY_1T4MI",
                    x: 21,
                    y: 31.2,
                },
                {
                    practiceName: "Herriman Hills Dental",
                    placeId: "ChIJg0HxyvqEUocRYRAGt5pKfKo",
                    x: 22,
                    y: 31.8,
                },
                {
                    practiceName: "Genesis Dental of South Jordan",
                    placeId: "ChIJdWIE7CuEUocREpGpIU0oH_A",
                    x: 23,
                    y: 32.4,
                },
                {
                    practiceName: "Dr. Sam B. Richey, DDS",
                    placeId: "ChIJY1tjeUSEUocRX0KOJMS9csM",
                    x: 24,
                    y: 33,
                },
                {
                    practiceName: "South Pointe Dental",
                    placeId: "ChIJh73LBeiAUocRU1pBgQvaNZE",
                    x: 25,
                    y: 36,
                },
                {
                    practiceName: "Beautiful Smiles Dental Care",
                    placeId: "ChIJ75ZYHI6GUocRzMdHC1s5AYA",
                    x: 26,
                    y: 36.2,
                },
                {
                    practiceName: "River Crossing Dental",
                    placeId: "ChIJj2io1IOFUocRfAXHw7CLpHA",
                    x: 27,
                    y: 36.4,
                },
                {
                    practiceName: "Dr. Brian Borg, DMD",
                    placeId: "ChIJxTC_20CEUocRcVrUSbzOKTQ",
                    x: 28,
                    y: 36.6,
                },
                {
                    practiceName: "Smiles for Everyone",
                    placeId: "ChIJIUh_XtCFUocRU6qmCeVB9vM",
                    x: 29,
                    y: 37.6,
                },
                {
                    practiceName: "Dr. Joel L. Howarth, DDS",
                    placeId: "ChIJvXjQgi6EUocR5LUvOoStRZc",
                    x: 30,
                    y: 39.2,
                },
            ],
        },
        ppcChartData: {
            high: 5928.846153846153,
            low: 2890.312499999999,
            xTicks: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
            yTicks: [0, 1000, 2000, 3000, 4000, 5000],
            apts: 20,
        },
    },
    recommendations: {
        seo: {
            competition: "high",
            conclusion: "You need to improve your online visibility",
            recommendation: "Tier 4 Search Engine Optimization (SEO)",
        },
        reviews: {
            competition: "high",
            conclusion: "Improving and refreshing your review totals should be an ongoing process.",
            recommendation: "Active, automated review generation",
        },
        ppc: {
            competition: "Medium",
            recommendation: "Determine a new patient goal and budget your adspend accordingly",
        },
    },
    competitionLevel: 0.8195,
    user: {
        practiceName: "River City Dental",
    },
    config: {
        charts: {
            seo: {
                displayChart: true,
                bigBox: {
                    x: 9,
                    y: 8,
                },
                lilBox: {
                    x: 21,
                    y: 20,
                },
            },
            reviews: true,
            ppc: true,
        },
    },
    leadId: "5fc68a9692db688b4ca7d148",
};
