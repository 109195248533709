import React from "react";
import { Box } from "../../styledSystemUtilities";
import { ReviewsHero } from "../../Atoms/assets";
import {
    Airplane,
    Facebook,
    Google,
    ReviewsOverTimeSample,
    ReviewsOverTimeTableSample,
    ReviewsPlotSample,
    ReviewsTableSample,
    ReviewsTracking,
} from "../../Atoms";
import {
    GrayBackground,
    IconContainer,
    NumberedParagraph,
    OnlineHeader,
    Paragraph,
    PricingNotes,
    ReportingCard,
    ReportingHeader,
    SellingPoint,
} from "../../Molecules";

export const ReviewGeneration = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <OnlineHeader setPopup={setPopup} Hero={ReviewsHero} />
            <GrayBackground>
                <SellingPoint
                    header={"A Google first approach"}
                    body={
                        "Google reviews are by far the most beneficial to a practice for searching, building trust, and generating web actions and phone calls. We excel at generating Google reviews."
                    }
                />
                <SellingPoint
                    header={"More 5 star reviews"}
                    body={
                        "We’ll show you how to consistently generate the highest possible ratings and give you the control to ensure the requests are given to the right patients."
                    }
                />
                <IconContainer name={"Technology"}>
                    <Google name={"Google First"} fill={"auratiumGreen"} />
                    <Facebook name={"Facebook Integrated"} />
                    <Box />
                    <Airplane name={"Practice controlled"} />
                    <ReviewsTracking name={"Reviews Tracking"} fill={"auratiumGreen"} />
                </IconContainer>
                <ReportingHeader product={"Review Generation"} color={"auratiumGreen"} />
            </GrayBackground>
            <ReportingCard>
                <NumberedParagraph
                    header={"1. Competitive Improvement"}
                    body={"Where do you sit in your marketplace and are you improving over time?"}
                />
                <Paragraph>
                    We compare your original and current position to the competitors in your area for Google reviews.
                    You can tell if you’re above or below average in both quality and quantity.
                </Paragraph>
                <ReviewsPlotSample />
                <Paragraph>
                    We track the total number of reviews and average rating for you and the top 5 dentists in your area.
                </Paragraph>
                <ReviewsTableSample />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"How many Google reviews do you generate each month?"}
                    header={"2. Google review generation"}
                />
                <Paragraph>
                    Google reviews are, by far, the most important for your overall marketing success. We show the
                    details of how many you generate each month.
                </Paragraph>
                <ReviewsOverTimeSample />
                <ReviewsOverTimeTableSample />
            </ReportingCard>
            <PricingNotes>
                Review Generation is purchased on a subscription basis and requires 30 days notice to cancel.
            </PricingNotes>
        </Box>
    );
};
