import _ from "lodash/fp";
import { useSelector } from "react-redux";

export const useGrowthEstimates = (products) => {
    const perPatientValue = useSelector((state) => state.perPatientValue);
    const estimate = _.round(_.sumBy("newPatientEstimate", products));
    const cost = _.round(_.sumBy("monthlyPrice", products));
    const revenue = estimate * perPatientValue;
    const roi = revenue - cost;

    return {
        estimate,
        cost,
        revenue,
        roi,
    };
};
