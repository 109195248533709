import React from "react";
import { Box } from "../styledSystemUtilities";
import { Text } from "./Text";
import theme from "../../theme";

export const NumberCircle = ({ color, number }) => {
    return (
        <Box
            height={"54px"}
            width={"54px"}
            border={`1px solid ${theme.colors[color]}`}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"27px"}
        >
            <Text fontWeight={"bold"} fontFamily={"roboto"} fontSize={"30px"} color={color}>
                {number}
            </Text>
        </Box>
    );
};
