import React from "react";
import { Box } from "../../styledSystemUtilities";
import { BoxArrow, HalfCircle, ListCheck, MagnifyingGlass, Tools } from "../../Atoms/assets";
import { Text } from "../../Atoms";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { EDentHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const OneYearEDent = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <EDentHeader setPopup={setPopup} name={"Increase PPO Revenue"} tagline={"Reimbursement, maximized."} />
            <GrayBackground>
                <SellingPoint
                    header={"Increase PPO Revenue"}
                    body={
                        "Our PPO Management service can help your established dental practice improve your low PPO Insurance reimbursements through PPO Negotiations & Optimization."
                    }
                />
                <IconContainer name={"What We Do"} />
                <IconBulletPoint
                    Icon={ListCheck}
                    body={"Find opportunities for improvement."}
                    header={"Evaluate PPO Participation"}
                />
                <IconBulletPoint
                    Icon={BoxArrow}
                    body={"Get the best rate for you."}
                    header={"Negotiate Higher Reimbursements"}
                />
                <IconBulletPoint
                    Icon={HalfCircle}
                    body={"Take advantage of high leasing options."}
                    header={"Optimization"}
                />
                <IconBulletPoint Icon={Tools} body={"Reconfigure your PPO structure."} header={"Implementation"} />
                <IconBulletPoint
                    Icon={MagnifyingGlass}
                    body={"Analyze your market to identify updates."}
                    header={"FreePro"}
                />
            </GrayBackground>
            <WhiteBackground>
                <Text variant={"body2"} color={"balticBlue"} lineHeight={"21px"}>
                    Increase PPO collections through advanced negotiations and strategic leasing re-configuration!
                </Text>
            </WhiteBackground>
            <GrayBackground>
                <Box pb={9} />
                <SellingPoint
                    header={"About e-dental Market"}
                    body={
                        "We specialize in completely optimizing your PPO contracts through advanced direct negotiations and identifying your highest options by taking into consideration the numerous leasing options available so that your reimbursements don’t get impacted. Let us help you find your weak spots, optimize and achieve the best contracted rates possible!"
                    }
                />
                <Box pb={6} />
            </GrayBackground>
        </Box>
    );
};
