export const onlineOptions = {
    improve: {
        value: "improve",
        multiplier: 1,
        label: "improve",
        capitalizedLabel: "Improve",
        description: "I’m intent on achieving ideal visibility.",
        recommendation: "To reach your goal of ideal visibility, we recommend these subscriptions:",
    },
    maintain: {
        value: "maintain",
        multiplier: 0.67,
        label: "maintain",
        capitalizedLabel: "Maintain",
        description: "I’m satisfied with my current position.",
        recommendation: "To maintain your current position, we recommend these subscriptions:",
    },
    reduceCosts: {
        value: "reduceCosts",
        multiplier: 0.33,
        label: "reduce costs",
        capitalizedLabel: "Reduce costs",
        description: "I’m not worried about my visibility. ",
        recommendation: "To reduce costs, we recommend these subscriptions:",
    },
};
