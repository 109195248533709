import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "account",
    initialState: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        accountId: null,
    },
    reducers: {
        set: (state, action) => action.payload,
    },
});
