import { BurkhartProductCard, EDentalProductCard, ProductCard } from "../Components/Atoms";
import { BaseCheckoutCard, LionSpeakCheckoutCard, PrintCheckoutCard } from "../Components/Atoms/CheckoutCards";
import { LionSpeakProductCard } from "../Components/Atoms/LionSpeakProductCard";
import { PrintProductCard } from "../Components/Atoms/PrintProductCard";

export const productCards = {
    productCard: ProductCard,
    checkoutCard: BaseCheckoutCard,
    lionSpeakProductCard: LionSpeakProductCard,
    lionSpeakCheckoutCard: LionSpeakCheckoutCard,
    printProductCard: PrintProductCard,
    printCheckoutCard: PrintCheckoutCard,
    eDentProductCard: EDentalProductCard,
    burkhartProductCard: BurkhartProductCard,
    burkhartCheckoutCard: BaseCheckoutCard,
};
