import React from "react";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { useCart } from "../../hooks/useCart";
import { useSetPopup } from "../../hooks/useSetPopup";
import { ProductBottomRow } from "./ProductBottomRows";
import { Text } from "./Text";
import { BurkhartLogoSmall } from "./assets";

export const BurkhartProductCard = ({ product }) => {
    const [isAdded, toggleProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    return (
        <Box data-testid={product.recurlyCode}>
            <FlexRowAlignCenter backgroundColor={"catskillWhite"} px={"13px"} py={3}>
                <BurkhartLogoSmall />
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                px={"13px"}
                py={"6px"}
                backgroundColor={"catskillWhite"}
            >
                <Box>
                    <Text variant={"body3"} color={"nauticBlue"}>
                        {product.name}
                    </Text>
                    <Box pb={"1px"} />
                    <Text variant={"label1"} color={"balticBlue"}>
                        {product.description}
                    </Text>
                </Box>
                <Box>
                    <Text variant={"body3"} textAlign={"right"}>
                        No Charge
                    </Text>
                    <Text variant={"label1"}>Gold or Platinum BRAVO</Text>
                </Box>
            </FlexRowAlignCenter>
            <ProductBottomRow
                toggleProduct={toggleProduct}
                isAdded={isAdded}
                color={"nauticBlue"}
                backgroundColor={"pattensBlue"}
                setPopup={setPopup}
            />
        </Box>
    );
};
