import slices from "../slices";
import { api } from "../utilityActions";
import actions from "./index";

const createPurchase = (purchase) =>
    api({
        call: {
            url: "/createPurchase",
            method: "post",
            data: purchase,
        },
        success: (invoice) => [slices.invoice.actions.set(invoice), actions.getStrategist()],
    });

export default createPurchase;
