import React, { useRef, useState } from "react";
import { CardElement, Elements, RecurlyProvider, useRecurly } from "@recurly/react-recurly";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { Box, FlexRowAlignCenter, Grid } from "../styledSystemUtilities";
import { Button, Gradient, Input, Text } from "../Atoms";
import { slices } from "../../redux";
import { routerHistory } from "../../routerHistory";
import { FormError } from "../Atoms/FormError";
import { CheckoutHeader } from "../Molecules/CheckoutHeader";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";

export const Checkout = () => {
    return (
        <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
            <Elements>
                <Form />
            </Elements>
        </RecurlyProvider>
    );
};

const schema = object().shape({
    firstName: string().required("Please enter your first name.").trim(),
    lastName: string().required("Please enter your last name.").trim(),
    zipCode: string().required("Please enter your zip.").trim(),
    address1: string().required("Please enter your address.").trim(),
    address2: string().trim(),
    city: string().required("Please enter your city.").trim(),
    country: string()
        .required("Please enter your country.")
        .oneOf(["US", "United States", "CA", "Canada"], "Please enter a valid country.")
        .trim(),
    state: string().required("Please enter your state.").trim(),
});

const Form = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const [error, setError] = useState(false);

    const formRef = useRef();
    const recurly = useRecurly();

    const onSubmit = () => {
        recurly.token(formRef.current, (err, token) => {
            if (err) {
                setError({ message: "Please enter a valid card." });
            } else {
                dispatch(slices.recurlyToken.actions.set(token));
                routerHistory.push("/review-order");
            }
        });
    };

    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"44px"} />
                    <CheckoutHeader header={"Checkout"} darkNumber={2} />
                    <Box pb={"12px"} />
                    <Text variant={"body2"} color={"graphiteGray"}>
                        Billing address
                    </Text>
                    <Box pb={"6px"} />
                    <Text variant={"body4"}>Country</Text>
                    <Box pb={"5px"} />
                    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                        <Input
                            type="text"
                            name={"country"}
                            data-recurly="country"
                            placeholder="Country"
                            defaultValue={"US"}
                            ref={register}
                        />
                        <FormError error={errors.country} />
                        <Box pb={"13px"} />
                        <Input
                            type="text"
                            name={"address1"}
                            data-recurly="address1"
                            placeholder="Address line 1"
                            ref={register}
                        />
                        <FormError error={errors.address1} />
                        <Box pb={"13px"} />
                        <Input
                            type="text"
                            name={"address2"}
                            data-recurly="address2"
                            placeholder="Address line 2"
                            ref={register}
                        />
                        <FormError error={errors.address2} />
                        <Box pb={"13px"} />
                        <Input type="text" name={"city"} data-recurly="city" placeholder="City" ref={register} />
                        <FormError error={errors.city} />
                        <Box pb={"13px"} />
                        <FlexRowAlignCenter justifyContent={"space-between"}>
                            <Box width={"48%"}>
                                <Input
                                    type="text"
                                    name={"state"}
                                    data-recurly="state"
                                    placeholder="State"
                                    ref={register}
                                />
                                <FormError error={errors.state} />
                            </Box>

                            <Box width={"48%"}>
                                <Input
                                    type="text"
                                    name={"zipCode"}
                                    data-recurly="postal_code"
                                    placeholder="Zip"
                                    ref={register}
                                />
                                <FormError error={errors.zipCode} />
                            </Box>
                        </FlexRowAlignCenter>
                        <Box pb={"13px"} />
                        <Input
                            type={"text"}
                            name={"firstName"}
                            data-recurly={"first_name"}
                            placeholder={"First name on card"}
                            ref={register}
                        />
                        <FormError error={errors.firstName} />
                        <Box pb={"13px"} />
                        <Input
                            type="text"
                            name={"lastName"}
                            data-recurly="last_name"
                            placeholder="Last name on card"
                            ref={register}
                        />
                        <FormError error={errors.lastName} />
                        <Box data-testid={"cardElement"}>
                            <CardElement />
                        </Box>
                        <FormError error={error} />
                        <Box pb={"13px"} />
                        <Button backgroundColor={"graphiteGray"} onClick={handleSubmit}>
                            review order
                        </Button>
                    </form>
                </Box>
            </Grid>
        </Box>
    );
};
