import { Box } from "../styledSystemUtilities";
import { Text } from "./Text";
import React from "react";
import _ from "lodash/fp";
const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export const BlueCell = ({ value, label, gridColumn }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridColumn={gridColumn} width={"100%"}>
            <Text variant={"label2"} fontWeight={"bold"} color={"petrolBlue"}>
                {label}
            </Text>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#BFE0E0"}
                height={"24px"}
                width={"100%"}
                mt={2}
            >
                <Text variant={"score"} color={"petrolBlue"} fontWeight={"bold"}>
                    {value}
                </Text>
            </Box>
        </Box>
    );
};

export const BoldWhiteCell = ({ value, label, gridColumn }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridColumn={gridColumn} width={"100%"}>
            <Text variant={"label2"} color={"petrolBlue"} fontWeight={"bold"}>
                {label}
            </Text>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"24px"}
                width={"100%"}
                border={"petrolBlue"}
                mt={2}
            >
                <Text variant={"score"} fontSize={"14px"} color={"sienna"} fontWeight={"medium"}>
                    {value}
                </Text>
            </Box>
        </Box>
    );
};

export const WhiteCell = ({ value, label, color, gridColumn }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridColumn={gridColumn} width={"100%"}>
            <Text variant={"label2"} color={"petrolBlue"}>
                {label}
            </Text>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"24px"}
                width={"100%"}
                border={"blueLine"}
                mt={2}
            >
                <Text variant={"score"} color={color ? color : "agateGray"} fontWeight={"regular"}>
                    {value}
                </Text>
            </Box>
        </Box>
    );
};

export const Cell = ({ value, label, color, gridColumn }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridColumn={gridColumn} width={"100%"}>
            <Text variant={"label2"} color={"petrolBlue"}>
                {label}
            </Text>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"24px"}
                width={"100%"}
                backgroundColor={"cellBlue"}
                border={"blueLine"}
                mt={2}
            >
                <Text variant={"score"} color={color ? color : "agateGray"} fontWeight={color ? "medium" : "regular"}>
                    {value}
                </Text>
            </Box>
        </Box>
    );
};

export const TotalCell = ({ value, label }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Text color={"ipanemaBlue"} variant={"body3"}>
                {label}
            </Text>
            <Box mb={1} />
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={14}
                width={"160px"}
                backgroundColor={"white"}
                border={"gray"}
            >
                <Text color={"petrolBlue"} variant={"header2"}>
                    {formatter.format(_.round(value))}
                </Text>
            </Box>
        </Box>
    );
};

export const Operator = ({ operator }) => {
    return (
        <Box pb={"2px"}>
            <Text variant={"body2"} color={"deepPetrol"}>
                {operator === "/" ? "÷" : operator}
            </Text>
        </Box>
    );
};

export const Row = ({ children }) => {
    return (
        <Box
            display={"grid"}
            gridTemplateColumns={"1fr 20px 1fr 20px 1fr"}
            alignItems={"end"}
            justifyItems={"center"}
            flexGrow={1}
        >
            {children}
        </Box>
    );
};

export const RightCornerLine = () => {
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"flex-end"}
            alignSelf={"flex-end"}
        >
            <Box width={4} height={"1px"} backgroundColor={"blueLine"} />
            <Box width={"1px"} height={"8px"} backgroundColor={"blueLine"} />
        </Box>
    );
};

export const MiddleLine = () => {
    return (
        <Box display={"flex"} flexDirection={"column"} ml={"3px"}>
            <Box height={"8px"} width={"1px"} backgroundColor={"blueLine"} alignSelf={"flex-end"} />
            <Box justifySelf={"center"} width={"100%"} height={"1px"} backgroundColor={"blueLine"} />
            <Box height={"8px"} width={"1px"} backgroundColor={"blueLine"} />
        </Box>
    );
};

export const LeftCornerLine = ({ operator, height }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} mr={2}>
            <Box width={"1px"} height={height ? height : "20px"} ml={"3px"} backgroundColor={"blueLine"} />
            <Operator operator={operator} />
        </Box>
    );
};
