import React from "react";

import styled from "styled-components";
import {
    space,
    color,
    typography,
    position,
    border,
    background,
    layout,
    flexbox,
    grid,
    compose,
    system,
} from "styled-system";

const BorderBoxDiv = styled.div`
    box-sizing: border-box;
`;

export const all = compose(
    space,
    color,
    typography,
    position,
    border,
    background,
    layout,
    flexbox,
    grid,
    system({
        cursor: true,
        writingMode: true,
        textOrientation: true,
        whiteSpace: true,
        transition: true,
        transform: true,
        boxShadow: true,
        backgroundAttachment: true,
        boxSizing: true,
        uppercase: {
            property: "textTransform",
            transform: () => "uppercase",
        },
    })
);

export const Box = styled(BorderBoxDiv)(all);

const Flex = (props) => <Box display={"flex"} {...props} />;

export const FlexRow = (props) => <Flex flexDirection={"row"} {...props} />;

export const Grid = (props) => <Box display={"grid"} {...props} />;
export const FlexCol = (props) => <Flex flexDirection={"column"} {...props} />;

export const Span = (props) => <Box display={"inline"} {...props} />;
export const FlexRowAlignCenter = (props) => <FlexRow alignItems={"center"} {...props} />;
