import { BestForDentistryLogo, GrayAscentLogo, HPALogo } from "./assets";
import { Box } from "../styledSystemUtilities";
import React from "react";
import { useWindowSize } from "react-use";
import { useSelector } from "react-redux";

export const AbsolutePositionLogo = () => {
    const { width } = useWindowSize();
    const isBestForDentistry = useSelector((state) => state.isBestForDentistry);
    const isHpa = useSelector((state) => state.isHpa);

    return width > 940 ? (
        <Box position={"absolute"} top={"55px"} left={"26px"}>
            {isBestForDentistry ? <BestForDentistryLogo /> : isHpa ? <HPALogo /> : <GrayAscentLogo />}
        </Box>
    ) : null;
};
