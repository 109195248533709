import React from "react";
import { Box, FlexRowAlignCenter } from "../../styledSystemUtilities";
import {
    CameraIcon,
    CalendarIcon,
    MySocialPracticeLogo,
    X,
    GreenDesktopComputer,
    GreenHandshake,
} from "../../Atoms/assets";
import { Text } from "../../Atoms";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { WhiteBackground } from "../../Molecules/PopupComponents";

export const MySocialPracticeSelf = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <Box backgroundColor={"seaGreen"} height={"31px"} pl={"23px"} display={"flex"} alignItems={"center"}>
                <Box onClick={() => setPopup(() => null)} cursor={"pointer"} display={"flex"} alignItems={"center"}>
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Box py={7} backgroundColor={"backgroundGreen"} pl={"21px"}>
                <Text variant={"header2"} color={"auratiumGreen"}>
                    Social Media
                </Text>
                <Box pt={7} />
                <Text fontFamily={"montserrat"} fontSize={"30px"} color={"white"}>
                    Engage, Interact
                    <br />
                    and Educate
                </Text>
            </Box>
            <FlexRowAlignCenter justifyContent={"center"} backgroundColor={"white"} height={"123px"}>
                <MySocialPracticeLogo />
            </FlexRowAlignCenter>
            <Box backgroundColor={"chalk"} height={10} />
            <GrayBackground>
                <SellingPoint
                    header={"Grow your practice with the #1 social media marketing service in dentistry."}
                    body={
                        "Our social media marketing service is a first-of-its-kind service that started our business over 12 years ago! Today, we continue to provide the most creative and effective solution in the dental industry."
                    }
                />
                <IconContainer name={"What's Included"} />
                <Text fontWeight={"bold"} uppercase pb={6}>
                    self-managed plan
                </Text>
                <IconBulletPoint
                    Icon={CalendarIcon}
                    header={"Monthly Post Ideas"}
                    body={
                        "Receive photo and video ideas and instructions each month. We’ll even provide you with ideas for contests and giveaways."
                    }
                />
                <IconBulletPoint
                    Icon={CameraIcon}
                    header={"HIPAA-Compliant Patient Photo Apps"}
                    body={
                        "Apps for your Ipad and mobile devices to make patient photography a breeze. We even provide digital HIPAA authorization to ensure it’s all secure. "
                    }
                />
                <IconBulletPoint
                    Icon={GreenDesktopComputer}
                    header={"Access to Our Member Site"}
                    body={
                        "Access to our entire library of dental graphics, gifs, videos, content for educational blogs, additional trending social media posting ideas. Bascially everything you need to be a social media success."
                    }
                />
                <IconBulletPoint
                    Icon={GreenHandshake}
                    header={"Support"}
                    body={"Always here to answer questions and provide peace of mind."}
                />
            </GrayBackground>
            <WhiteBackground>
                <Text variant={"body2"} color={"forrestGreen"} lineHeight={"21px"}>
                    “My Social Practice is simply the BEST way to get ALL of your social media needs met.”
                </Text>
                <Text variant={"body3"} fontWeight={"bold"} textAlign={"end"}>
                    — Ash & Roberts DDS
                </Text>
                <Box pb={9} />
                <Text variant={"body2"} color={"forrestGreen"} lineHeight={"21px"}>
                    “We love working with My Social Practice! They have taken the time consuming part of social media
                    away from us and done a wonderful job at keeping us connected with our patients. What a great
                    value!”
                </Text>
                <Text variant={"body3"} fontWeight={"bold"} textAlign={"end"}>
                    — Becky Sprague
                </Text>
            </WhiteBackground>
            <GrayBackground>
                <Box pb={9} />
                <SellingPoint
                    header={"About My Social Practice"}
                    body={
                        "The team at My Social Practice is thoughtfully composed of professional social media marketers who have a strong work ethic and embody our company core values. With our office in Salt Lake City, Utah and remote employees both in the country and across the globe, our collaborative, dynamic culture allows our teams to optimize productivity. Over the past 12 years, we’ve maintained a progressive outlook, always identifying social media marketing trends to set ourselves apart from the competition."
                    }
                />
                <Box pb={6} />
            </GrayBackground>
        </Box>
    );
};
