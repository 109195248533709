import React, { useState } from "react";
import { Box } from "../styledSystemUtilities";
import styled from "styled-components";
import { ComputerIcon, GrowthIcon, ProfitIcon, X } from "../Atoms/assets";
import { Button, Text } from "../Atoms";
import theme from "../../theme";
import { routerHistory } from "../../routerHistory";

export const PhaseBox = styled(Box)`
    height: 32px;
    width: 100px;
    background: ${(props) =>
        `linear-gradient(110deg, ${props.theme.colors[props.backgroundColor]} 91%, transparent 91% 100%)}`};
    display: flex;
    align-items: center;
    padding-left: 26px;
`;

export const PhaseBanner = ({ frontColor, backColor, Icon, setOpen }) => {
    return (
        <Box position={"relative"} width={"100%"} height={"32px"}>
            <PhaseBox backgroundColor={frontColor} position={"absolute"} zIndex={3}>
                <Icon fill={backColor} />
            </PhaseBox>
            <Box backgroundColor={frontColor} height={"9px"} width={"100%"} position={"absolute"} zIndex={2} />
            <Box
                backgroundColor={backColor}
                height={"32px"}
                width={"100%"}
                position={"absolute"}
                zIndex={1}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                pt={"9px"}
                pr={"30px"}
            >
                <Text
                    variant={"label1"}
                    fontWeight={"bold"}
                    color={frontColor}
                    onClick={() => setOpen((isOpen) => !isOpen)}
                    cursor={"pointer"}
                    uppercase
                >
                    learn more
                </Text>
                {/*<InfoIcon fill={frontColor} onClick={() => setOpen((isOpen) => !isOpen)} />*/}
            </Box>
        </Box>
    );
};

const CardBody = ({ children, goalColor, bodyColor }) => {
    return (
        <Box
            backgroundColor={"rgba(255, 255, 255, .6)"}
            height={"70px"}
            pl={"24px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
        >
            <Text fontFamily={"montserrat"} fontSize={"22px"} color={goalColor}>
                The goal:
            </Text>
            <Box pb={3} />
            <Text variant={"body2"} fontWeight={"medium"} color={bodyColor}>
                {children}
            </Text>
        </Box>
    );
};

const Popup = ({ setOpen, buttonColor, backgroundColor, buttonPath, children }) => {
    return (
        <Box boxShadow={"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}>
            <Box
                backgroundColor={buttonColor}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                width={"100%"}
            >
                <Box
                    onClick={() => setOpen(false)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Box display={"flex"} px={"18px"} py={"14px"} backgroundColor={backgroundColor}>
                <Box backgroundColor={"white"} py={"8px"} px={"14px"}>
                    <Text variant={"body3"}>{children}</Text>
                </Box>
            </Box>
            <Button backgroundColor={buttonColor} onClick={() => routerHistory.push(`/${buttonPath}-one`)}>
                start with {buttonPath}
            </Button>
        </Box>
    );
};

export const OnlinePhaseCard = ({ width = "375px" }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Box width={width}>
            <PhaseBanner backColor={"forrestGreen"} frontColor={"seaGreen"} Icon={ComputerIcon} setOpen={setOpen} />
            {isOpen ? (
                <Popup
                    setOpen={setOpen}
                    backgroundColor={"#D6E7DE"}
                    buttonPath={"online"}
                    buttonColor={"auratiumGreen"}
                >
                    We’ve analyzed your ranking, competitive area and Google reviews strength, to suggest the best
                    products to help you achieve or maintain an ideal online visibility.
                </Popup>
            ) : (
                <>
                    <CardBody goalColor={"auratiumGreen"} bodyColor={"forrestGreen"}>
                        Achieve ideal visibility in online search.
                    </CardBody>
                    <Button backgroundColor={"auratiumGreen"} onClick={() => routerHistory.push("/online-one")}>
                        start with online
                    </Button>
                </>
            )}
        </Box>
    );
};

export const GrowthPhaseCard = ({ width = "375px" }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Box width={width}>
            <PhaseBanner backColor={"deepPetrol"} frontColor={"ipanemaBlue"} Icon={GrowthIcon} setOpen={setOpen} />
            {isOpen ? (
                <Popup setOpen={setOpen} buttonColor={"ipanemaBlue"} backgroundColor={"#B2DDDC"} buttonPath={"growth"}>
                    We’ve analyzed relevant local campaign pricing to estimate the new patient acquisition costs and
                    return on investment of a predictable growth campaign.
                </Popup>
            ) : (
                <>
                    <CardBody bodyColor={"deepPetrol"} goalColor={"petrolBlue"}>
                        Build predictable and profitable growth.
                    </CardBody>
                    <Button backgroundColor={"ipanemaBlue"} onClick={() => routerHistory.push("/growth-one")}>
                        start with growth
                    </Button>
                </>
            )}
        </Box>
    );
};

export const ProfitPhaseCard = ({ width = "375px" }) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <Box width={width}>
            <PhaseBanner backColor={"nauticBlue"} frontColor={"balticBlue"} Icon={ProfitIcon} setOpen={setOpen} />
            {isOpen ? (
                <Popup
                    setOpen={setOpen}
                    buttonColor={"balticBlue"}
                    backgroundColor={"backgroundGray"}
                    buttonPath={"profit"}
                >
                    We’ve listed products to help you improve your profitability. These include brand enhancers, team
                    marketing training, insurance management and print collateral.
                </Popup>
            ) : (
                <>
                    <CardBody goalColor={"balticBlue"} bodyColor={"nauticBlue"}>
                        Enhance profitability and improve your brand.
                    </CardBody>
                    <Button backgroundColor={"balticBlue"} onClick={() => routerHistory.push("/profit-one")}>
                        start with profit
                    </Button>
                </>
            )}
        </Box>
    );
};

export const BannerGapFiller = ({ startFrontColor, startBackColor, finishColor }) => {
    return (
        <Box position={"relative"} height={"32px"} width={"20px"}>
            <Box position={"absolute"} height={"100%"} width={"100%"} zIndex={1} backgroundColor={startBackColor} />
            <Box position={"absolute"} height={"9px"} width={"100%"} zIndex={2} backgroundColor={startFrontColor} />
            <Box
                position={"absolute"}
                height={"100%"}
                width={"100%"}
                background={`linear-gradient(110deg, transparent 37%, ${theme.colors[finishColor]} 37% 100%)}`}
                zIndex={3}
            />
        </Box>
    );
};
