import { formatter } from "./formatter";
import _ from "lodash/fp";

export const getProfitMonthlyPaymentValue = (profitTotalCost, profitPaymentOption, couponAmount = 0) => {
    return formatter.format(
        _.round(
            (profitTotalCost / profitPaymentOption.value) * profitPaymentOption.fee -
                couponAmount / profitPaymentOption.value
        )
    );
};
