import React from "react";
import _ from "lodash/fp";
import { Box } from "../styledSystemUtilities";

export const WebsiteSamples = ({ samples }) => {
    return _.map(
        (Sample) => (
            <>
                <Sample />
                <Box pb={6} />
            </>
        ),
        samples
    );
};
