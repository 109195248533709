import slices from "../slices";
import { api } from "../utilityActions";
import { routerHistory } from "../../routerHistory";

const createAccount = (clientInfo) =>
    api({
        call: {
            url: "/account/createAccount",
            method: "post",
            data: clientInfo,
        },
        success: (accountInfo) => [slices.account.actions.set(accountInfo), routerHistory.push("/checkout")],
    });

export default createAccount;
