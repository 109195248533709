import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { hydrateState } from "./utilityActions";
import middleware from "./middleware";

const store = configureStore({
    reducer: makeHydratable(rootReducer),
    middleware: [middleware.multi, thunk, middleware.noop, middleware.history, middleware.api],
});

export default store;

function makeHydratable(reducer) {
    return function (state, action) {
        if (action.type === hydrateState.type) {
            return reducer(action.payload, action);
        }
        return reducer(state, action);
    };
}
