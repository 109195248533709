import { useDispatch } from "react-redux";
import { slices } from "../redux";

export const useSetPopup = (popupId) => {
    const dispatch = useDispatch();
    return () => {
        window.scrollTo(0, 0);
        dispatch(slices.popup.actions.setPopup(popupId));
    };
};
