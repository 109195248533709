import cart from "./cart";
import popup from "./popup";
import onlineOptions from "./onlineOptions";
import growthAverageType from "./growthAverageType";
import perPatientValue from "./perPatientValue";
import profitOptions from "./profitOptions";
import invoice from "./invoice";
import recurlyToken from "./recurlyToken";
import profitPaymentOption from "./profitPaymentOption";
import error from "./error";
import sessionId from "./sessionId";
import loading from "./loading";
import places from "./places";
import report from "./report";
import user from "./user";
import growthGoal from "./growthGoal";
import couponCode from "./couponCode";
import account from "./account";
import strategist from "./strategist";
import practiceExists from "./practiceExists";
import isBestForDentistry from "./isBestForDentistry";
import tasks from "./tasks";
import isBurkhart from "./isBurkhart";
import isHpa from "./isHpa";

const slices = {
    cart,
    popup,
    onlineOptions,
    growthAverageType,
    perPatientValue,
    profitOptions,
    invoice,
    recurlyToken,
    profitPaymentOption,
    error,
    sessionId,
    loading,
    places,
    report,
    user,
    growthGoal,
    couponCode,
    account,
    strategist,
    practiceExists,
    isBestForDentistry,
    tasks,
    isBurkhart,
    isHpa,
};

export default slices;
