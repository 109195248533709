import React from "react";
import { ProfitPageTemplate } from "./PageTemplate";
import { ProfitHeader, ProfitSubHeader } from "../Atoms/Headers";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { CircleHeader } from "../Molecules/CircleHeader";
import { Button, Text } from "../Atoms";
import { BigCheckbox } from "../Atoms/Checkbox";
import { profitOptions } from "../../constants/profitOptions";
import { routerHistory } from "../../routerHistory";
import { useProfitOptions } from "../../hooks/useProfitOptions";
import { useSelector } from "react-redux";
import _ from "lodash/fp";

export const ProfitOne = () => {
    const [checked, setChecked] = useProfitOptions();
    const report = useSelector((state) => state.report);

    return (
        <ProfitPageTemplate hideSummary={true}>
            <Box pb={"24px"} />
            <ProfitHeader textAlign={"center"}>Profit Focused Marketing</ProfitHeader>
            <ProfitSubHeader>{_.get("user.practiceName", report)}</ProfitSubHeader>
            <Box pb={"15px"} />
            <CircleHeader color={"nauticBlue"} number={1} text={"How does marketing increase your profitability?"} />
            <Box pb={"16px"} />
            <Box backgroundColor={"white"} pt={"11px"} pb={"19px"} px={"30px"}>
                <Text fontSize={"22px"} fontFamily={"montserrat"} color={"sienna"}>
                    The 7 Profit Centers of Practice Marketing
                </Text>
                <Box pb={"4px"} />
                <Text variant={"body3"} color={"balticBlue"}>
                    Which areas are you interested in improving?
                </Text>
                <Box pb={"16px"} />
                <Text variant={"body3"} color={"amaranthRed"}>
                    Check all that apply:
                </Text>
                <Box pb={"9px"} />
                {profitOptions.map(({ option, label }, index) => (
                    <Box key={index} pb={"11px"}>
                        <ListItem checked={checked} setChecked={setChecked} option={option} label={label} />
                    </Box>
                ))}
            </Box>
            <Box pb={"12px"} />
            <Text variant={"body3"} color={"nauticBlue"} fontWeight={"medium"} lineHeight={"19px"} px={"22px"}>
                Most practice marketing companies overlook these critical areas. Ascent doesn’t. We take pride in
                increasing per patient value and profitability.
            </Text>
            <Box pb={"12px"} />
            <Button backgroundColor={"balticBlue"} onClick={() => routerHistory.push("/profit-two")}>
                continue
            </Button>
            <Box pb={"100px"} />
        </ProfitPageTemplate>
    );
};

const ListItem = ({ setChecked, checked, label, option }) => (
    <FlexRowAlignCenter cursor={"pointer"} onClick={() => setChecked(option)}>
        <BigCheckbox fill={"sienna"} isChecked={checked[option]} />
        <Box pl={"13px"} />
        <Text fontSize={"13px"} color={"nauticBlue"} fontWeight={"medium"}>
            {label}
        </Text>
    </FlexRowAlignCenter>
);
