import React from "react";
import { NumberCircle } from "../Atoms/NumberCircle";
import { Box } from "../styledSystemUtilities";
import { Text } from "../Atoms";

export const CircleHeader = ({ text, number, color }) => {
    return (
        <Box display={"flex"} alignItems={"center"} pl={"28px"}>
            <NumberCircle color={color} number={number} />
            <Box pl={"12px"} />
            <Text variant={"body3"} color={color} fontWeight={"bold"} lineHeight={"18px"} width={"248px"}>
                {text}
            </Text>
        </Box>
    );
};
