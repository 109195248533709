import { useDispatch, useSelector } from "react-redux";
import _ from "lodash/fp";
import { slices } from "../redux";
import { useState } from "react";

export const useCart = (product) => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const productInCart = _.find(["id", product.id], cart);
    const isAdded = Boolean(productInCart);

    const [watchedProduct, setWatchedProduct] = useState(productInCart || product);

    return [
        isAdded,
        () => {
            dispatch(slices.cart.actions.toggleProduct(watchedProduct));
        },
        (update) => {
            if (isAdded) dispatch(slices.cart.actions.updateProduct(update));
            setWatchedProduct(update);
        },
        watchedProduct,
    ];
};
