import React from "react";
import { Box } from "../../styledSystemUtilities";
import {
    LogoHero,
    LogoSample1,
    LogoSample2,
    LogoSample3,
    LogoSample4,
    LogoSample5,
    LogoSample6,
} from "../../Atoms/assets";
import { GrayBackground, PricingNotes, SamplesHeader, SellingPoint } from "../../Molecules";
import { ProfitHeader } from "../../Molecules/PopupComponents";
import _ from "lodash/fp";

export const LogoDesign = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <ProfitHeader setPopup={setPopup} Hero={LogoHero} chalkHeight={"41px"} />
            <GrayBackground>
                <SellingPoint
                    header={"How will you be remembered?"}
                    body={
                        "There’s a reason almost every major company from Apple to Nike to Coca-Cola end their commercials by showing their logo. It’s how people remember you. If your logo doesn’t convey your brand properly, it could damage your practice’s perceptions."
                    }
                />
                <SellingPoint
                    header={"It’s worth doing it right"}
                    body={
                        "Your logo should stand the test of time. Paying for a logo means you’re paying for 2 things: time and expertise. The cheaper the logo, the less time and/or expertise can be applied to the process. You can trust the Ascent design team to spend the time and apply our decade-plus expertise in dental specific logo design to get it right."
                    }
                />
                <SamplesHeader color={"balticBlue"} />
            </GrayBackground>
            {_.map(
                (Sample) => (
                    <>
                        <Sample />
                        <Box height={3} backgroundColor={"chalk"} />
                    </>
                ),
                [LogoSample1, LogoSample2, LogoSample3, LogoSample4, LogoSample5, LogoSample6]
            )}
            <PricingNotes>
                Logos are purchased on a per-item basis and must be paid in full. We offer the choice of a 1-time
                payment or 12 payments at a monthly interest rate of 12%.
            </PricingNotes>
        </Box>
    );
};
