import _ from "lodash/fp";

export function getVisibilityLevel(report) {
    const numCompetitors = _.getOr(0, "seo.numCompetitors", report);
    const ideal = numCompetitors * 0.1;
    const average = numCompetitors * 0.35;
    const averageRank = (_.getOr(0, "seo.overallRank", report) + _.getOr(0, "reviews.reviewsRank", report)) / 2;
    if (averageRank <= ideal) {
        return "ideal";
    }
    if (averageRank <= average) {
        return "average";
    }
    return "low";
}
