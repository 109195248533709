import React from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { Button, Gradient, Text } from "../Atoms";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";

export const LocationDetected = () => {
    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"74px"} />
                    <Text variant={"header2"} textAlign={"center"} color={"graphiteGray"}>
                        Location Detected
                    </Text>
                    <Box pb={6} />
                    <Text textAlign={"center"} variant={"body2"} color={"graphiteGray"} fontWeight={"medium"}>
                        It looks like you have already made a purchase for this practice.
                    </Text>
                    <Box pb={3} />
                    <Text textAlign={"center"} variant={"body4"} color={"graphiteGray"}>
                        Click below to log in and make additional purchases.
                    </Text>
                    <Box pb={5} />
                    <Button
                        backgroundColor={"graphiteGray"}
                        onClick={() => {
                            window.open("https://reporting.ascentpgs.com/", "_blank");
                        }}
                    >
                        login to your reporting
                    </Button>
                </Box>
            </Grid>
        </Box>
    );
};
