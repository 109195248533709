const coupons = {
    ppc3free: {
        type: "growth",
        amount: 60,
        duration: 3,
        color: "petrolBlue",
        message: "PPC call tracking fee waived for 3 months of Google Ads.",
    },
    seoreduce6: {
        type: "online",
        duration: 6,
        percentOff: 0.05,
        color: "auratiumGreen",
        message: "5% off SEO for 6 months.",
    },
    unitasppc3free: {
        type: "growth",
        amount: 60,
        duration: 3,
        color: "petrolBlue",
        message: "PPC call tracking fee waived for 3 months of Google Ads.",
    },
    dmppc3free: {
        type: "growth",
        amount: 60,
        duration: 3,
        color: "petrolBlue",
        message: "PPC call tracking fee waived for 3 months of Google Ads.",
    },
    unitasweb250: {
        type: "online",
        amount: 250,
        duration: 1,
        color: "auratiumGreen",
        message: "$250 off first month of website.",
    },
    dmweb250: {
        type: "online",
        amount: 250,
        duration: 1,
        color: "auratiumGreen",
        message: "$250 off first month of website.",
    },
    unitasonline500: {
        type: "subscription",
        amount: 500,
        duration: 1,
        color: "graphiteGray",
        message: "$500 discount on subscription products.",
    },
    dmonline500: {
        type: "subscription",
        amount: 500,
        duration: 1,
        color: "graphiteGray",
        message: "$500 discount on subscription products.",
    },
    patterson180: {
        type: "growth",
        amount: 60,
        duration: 3,
        color: "petrolBlue",
        message: "PPC call tracking fee waived for 3 months of Google Ads.",
    },
    patterson500: {
        type: "subscription",
        amount: 500,
        duration: 1,
        color: "graphiteGray",
        message: "$500 discount on online visibility subscription products.",
    },
    best: {
        type: "best",
        percentOff: 0.05,
        duration: "forever",
        message: "Best discount applied.",
    },
    hpafive: {
        type: "hpa",
        percentOff: 0.05,
        duration: "forever",
        message: "HPA discount applied.",
    },
};

export default coupons;
