import React from "react";
import { Box } from "../../styledSystemUtilities";
import { MinizineHero } from "../../Atoms/assets";
import {
    DirectMailCallsSample,
    DirectMailCampaignSummarySample,
    DirectMailCostPerPatientSample,
    DirectMailGoalPlanningSample,
    DirectMailMonthToDateSample,
    DirectMailTableSample,
    DynamicCallTracking,
    ReviewsTracking,
    Strategy,
    Text,
} from "../../Atoms";
import {
    Disclaimers,
    GrayBackground,
    GrowthHeader,
    IconContainer,
    NumberedParagraph,
    Paragraph,
    PricingNotes,
    ReportingCard,
    ReportingHeader,
    SellingPoint,
} from "../../Molecules";
import { Filter } from "../../Atoms/Icons";

export const Minizine = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <GrowthHeader setPopup={setPopup} Hero={MinizineHero} />
            <GrayBackground>
                <SellingPoint
                    header={"The mini-zine difference"}
                    body={
                        "The expansive size of the mini-zine allows us to tell a more robust story and better convey your brand. Additionally, The larger size stands out in the mailbox and is much more likely to be noticed in the first place. It all adds up to improve response rates and build brand equity."
                    }
                />
                <SellingPoint
                    header={"Win the numbers game"}
                    body={
                        "As we work with a practice and compile data, we get better and better at deploying winning campaigns for you. A winning campaign is one that is profitable, predictable, and sustainable. To understand how we win the game for you, scroll down and view our reporting."
                    }
                />
                <SellingPoint
                    header={"More high revenue patients"}
                    body={
                        "Successful direct mail campaigns depend on targeting the right message to the right audience. Our 10 plus years of running direct mail campaigns in the dental industry allow our in-house team to generate more high revenue patients."
                    }
                />
                <SellingPoint
                    header={"Fewer price shoppers"}
                    body={
                        "Our branded and strategic mailers focus on communicating the right message to ideal prospects. Our mailing lists target the right demographics for quality new patients or to sell specific services such as dental implants. The end result are patients that are looking for a dental relationship and not a one-time deal."
                    }
                />
                <SellingPoint
                    header={"Intelligent adjustments"}
                    body={
                        "Our active reporting begins with a potential client seeing your mailer, ends with a converted patient, and conveys key data points along the way. These key data points highlight areas for improvement. For example, If your call conversion rates are below average, call conversion training could help. "
                    }
                />
                <IconContainer name={"Dimensions"} />
                <Text fontSize={"20px"} fontWeight={"bold"} pl={3} pb={7}>
                    11”x17” (Folded to 8.5”x11”)
                </Text>
                <IconContainer name={"Technology"}>
                    <Filter name={"Intelligent Targeting"} />
                    <DynamicCallTracking name={"Dynamic Call Tracking"} />
                    <Box />
                    <Strategy name={"Predictive Analytics"} fill={"ipanemaBlue"} />
                    <ReviewsTracking name={"Active Reporting"} fill={"ipanemaBlue"} />
                </IconContainer>
                <ReportingHeader product={"Direct Mail"} color={"ipanemaBlue"} />
            </GrayBackground>
            <ReportingCard>
                <NumberedParagraph header={"1. ROI Summary"} body={"Is this Campaign worth the cost?"} />
                <Paragraph>
                    At a glance, see your average budget per mailing, estimated revenue*, Return On Investment (ROI)**,
                    and average per patient acquisition costs. With this data, it’s easy to tell if your campaign is
                    working.
                </Paragraph>
                <DirectMailCampaignSummarySample name={"Summary — Campaign Averages"} />
                <Disclaimers />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"What will it cost to reach your new patient goals?"}
                    header={"2. Predictive goal planning"}
                />
                <Paragraph>
                    Goal planning is as simple as entering a new patient goal, and the recommended number of mailers and
                    budget per mailer is automatically calculated based on your cumulative averages. Compare your
                    averages to Ascent’s national averages for our clients.
                </Paragraph>
                <DirectMailGoalPlanningSample />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"What should you know to make confident adjustments?"}
                    header={"3. Trusted data for actionable insights"}
                />
                <Paragraph>
                    We track the client journey from first seeing your mailer all the way through to patient conversion.
                    Our data is sourced directly from advanced, dynamic call tracking. The call data is even recorded
                    and available for you to listen to. The full gamut of trusted data allows us to determine where
                    improvements can be made confidently.
                </Paragraph>
                <DirectMailMonthToDateSample name={"Month-to-date Analytics — Updated Daily"} />
                <DirectMailTableSample name={"Direct Mail Monthly Results"} />
                <DirectMailCallsSample name={"Calls Generated"} />
                <DirectMailCostPerPatientSample name={"Cost Per Patient"} />
            </ReportingCard>
            <PricingNotes>
                This campaign can be canceled every 15,000 mailers with 60 days advance notice (before printing the next
                quantity of 15,000). All months (including setup and printing) are billed at the campaign rate and are
                billed monthly on a subscription basis. We recommend allowing at least 2 - 15,000 mailer campaigns to
                run to collect meaningful data.
            </PricingNotes>
        </Box>
    );
};
