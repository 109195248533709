import {
    ANCILLARY_BROCHURE,
    ANCILLARY_SERVICES_BURKHART,
    BRAND_DEFINITION,
    BUSINESS_CARDS,
    CASE_ACCEPTANCE_BURKHART,
    ENVELOPES,
    LETTERHEAD,
    LOGO_DESIGN,
    ONE_YEAR_E_DENTAL,
    PATIENT_REFERRAL_BURKHART,
    PATIENT_REFERRAL_CARDS,
    PHONE_SKILLS_BURKHART,
    PRACTICE_VIDEO,
    PRO_E_DENTAL,
    PROMOTIONAL_CARDS,
    PROMOTIONAL_FLYERS,
    REACTIVATION_BURKHART,
} from "./productList";

export const burkhartProfitOptions = [
    {
        option: "perceptions",
        label: "Influence Perceptions",
        shortLabel: "Perceptions",
        subtitle: "Improve and unify your brand",
        products: [BRAND_DEFINITION, LOGO_DESIGN, PRACTICE_VIDEO],
        printOptions: [BUSINESS_CARDS, ENVELOPES, LETTERHEAD, PROMOTIONAL_FLYERS],
    },
    {
        option: "conversion",
        label: "Improve Phone Conversion",
        shortLabel: "Phone Skills",
        subtitle: "The most important skill in practice marketing. ",
        products: [PHONE_SKILLS_BURKHART],
    },
    {
        option: "referrals",
        label: "Increase Patient Referrals",
        shortLabel: "Referrals",
        subtitle: "The most effective way to bring in new patients. ",
        products: [PATIENT_REFERRAL_BURKHART, PATIENT_REFERRAL_CARDS],
    },
    {
        option: "ancillary",
        label: "Sell More Ancillary Services",
        shortLabel: "Ancillary",
        subtitle: "Occlusal guards, whitening, cosmetic options, sleep apnea, electric toothbrushes, Botox, etc. ",
        products: [ANCILLARY_SERVICES_BURKHART, ANCILLARY_BROCHURE, PROMOTIONAL_CARDS],
    },
    {
        option: "acceptance",
        label: "Improve Case Acceptance",
        shortLabel: "Case Acceptance",
        subtitle: "Increase per patient value.",
        products: [CASE_ACCEPTANCE_BURKHART],
    },
    {
        option: "reactivation",
        label: "Reactivate Patients",
        shortLabel: "Reactivation",
        subtitle: "It’s more effective than finding new ones.",
        products: [REACTIVATION_BURKHART],
    },
    {
        option: "reimbursement",
        label: "Improve Reimbursement Rates",
        shortLabel: "Insurance",
        subtitle: "Get the most out of your insurance relationship.",
        products: [ONE_YEAR_E_DENTAL, PRO_E_DENTAL],
    },
];
