import React from "react";
import { Box } from "../../styledSystemUtilities";
import { PpcHero } from "../../Atoms/assets";
import {
    CampaignSummarySample,
    CostPerClickSample,
    DynamicCallTracking,
    GoalPlanningSample,
    Google,
    MonthToDateSample,
    OptimizedLandingPage,
    PpcCallSample,
    PpcCostPerPatientSample,
    PpcTableSample,
    ReviewsTracking,
    Strategy,
} from "../../Atoms";
import {
    Disclaimers,
    GrayBackground,
    GrowthHeader,
    IconContainer,
    NumberedParagraph,
    Paragraph,
    PricingNotes,
    ReportingCard,
    ReportingHeader,
    SellingPoint,
} from "../../Molecules";

export const Ppc = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <GrowthHeader setPopup={setPopup} Hero={PpcHero} />
            <GrayBackground>
                <SellingPoint
                    header={"Win the numbers game"}
                    body={
                        "As we work with a practice and compile data, we get better and better at deploying winning campaigns for you. A winning campaign is one that is profitable, predictable, and sustainable. To understand how we win the game for you, scroll down and view our reporting."
                    }
                />
                <SellingPoint
                    header={"More high revenue patients"}
                    body={
                        "Successful Pay Per Click (PPC) campaigns depend on aligning the right keywords to the right audience. Our 10 plus years of running PPC campaigns in the dental industry allow our in-house team to generate more high revenue patients."
                    }
                />
                <SellingPoint
                    header={"Fewer price shoppers"}
                    body={
                        "Our strategic landing pages target demographics that are likely to be ideal patients. Additionally, unlike most PPC companies who sell their value in generating leads, our value is proven in quality new patients. The difference is that if the goal is to generate leads (phone calls), then price shoppers are the target. Since our goal is quality patients, we eliminate as many price shoppers as possible."
                    }
                />
                <SellingPoint
                    header={"Intelligent adjustments"}
                    body={
                        "Our active reporting begins with a potential client seeing your ad, ends with a converted patient, and conveys key data points along the way. These key data points highlight areas for improvement. For example, If your call conversion rates are below average, call conversion training could help. "
                    }
                />
                <IconContainer name={"Technology"}>
                    <Google name={"Google Integration"} fill={"ipanemaBlue"} />
                    <DynamicCallTracking name={"Dynamic Call Tracking"} />
                    <OptimizedLandingPage name={"Optimized Landing Page"} />
                    <Strategy name={"Predictive Analytics"} fill={"ipanemaBlue"} />
                    <ReviewsTracking name={"Active Reporting"} fill={"ipanemaBlue"} />
                </IconContainer>
                <ReportingHeader product={"Pay Per Click"} color={"ipanemaBlue"} />
            </GrayBackground>
            <ReportingCard>
                <NumberedParagraph header={"1. ROI Summary"} body={"Is this Campaign worth the cost?"} />
                <Paragraph>
                    At a glance, see your average ad spend budget, estimated revenue*, Return On Investment (ROI)**, and
                    average per patient acquisition costs. With this data, it’s easy to tell if your campaign is
                    working.
                </Paragraph>
                <CampaignSummarySample name={"Summary — Campaign Averages"} />
                <Disclaimers />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"What will it cost to reach your new patient goals?"}
                    header={"2. Predictive goal planning"}
                />
                <Paragraph>
                    Goal planning is as simple as entering a new patient goal, and your recommended budget is
                    automatically calculated based on your cumulative averages. Compare your averages to Ascent’s
                    national averages for our clients.
                </Paragraph>
                <GoalPlanningSample />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"What should you know to make confident adjustments?"}
                    header={"3. Trusted data for actionable insights"}
                />
                <Paragraph>
                    We track the client journey from first seeing your ad all the way through to patient conversion. Our
                    data is sourced directly from Google and advanced, dynamic call tracking. The call data is even
                    recorded and available for you to listen to. The full gamut of trusted data allows us to determine
                    where improvements can be made confidently.
                </Paragraph>
                <MonthToDateSample name={"Month-to-date Analytics — Updated Daily"} />
                <PpcTableSample name={"PPC Google Sourced Monthly Results"} />
                <CostPerClickSample name={"Cost Per Click"} />
                <PpcCallSample name={"PPC Monthly Call Performance & Conversion"} />
                <PpcCostPerPatientSample name={"Cost Per Patient"} />
            </ReportingCard>
            <PricingNotes>
                This campaign is billed monthly as a subscription and may be canceled anytime after month 2. We
                recommend allowing the campaign to run at least 4 months to collect meaningful data.
            </PricingNotes>
        </Box>
    );
};
