import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Text } from "./Text";
import { ArrowRight, DynamicCart } from "./assets";
import React from "react";

export const LearnMore = ({ color, setPopup, learnMoreText = "learn more" }) => (
    <FlexRowAlignCenter cursor={"pointer"} onClick={setPopup}>
        <ButtonText color={color}>{learnMoreText}</ButtonText> <Box pl={"10px"} /> <ArrowRight fill={color} />
    </FlexRowAlignCenter>
);

export const AddToCart = ({ color, toggleProduct, isAdded }) => (
    <FlexRowAlignCenter cursor={"pointer"} onClick={toggleProduct}>
        {isAdded ? (
            <ButtonText color={color}>remove from cart</ButtonText>
        ) : (
            <ButtonText color={color}>add to cart</ButtonText>
        )}
        <Box pl={"8px"} /> <DynamicCart fill={color} isChecked={isAdded} />
    </FlexRowAlignCenter>
);

const ButtonText = ({ color, children }) => (
    <Text variant={"label1"} fontWeight={"bold"} color={color} uppercase>
        {children}
    </Text>
);
