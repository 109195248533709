import slices from "../slices";
import { api } from "../utilityActions";
import { routerHistory } from "../../routerHistory";

const login = (loginCredentials) =>
    api({
        call: {
            url: "/account/login",
            method: "post",
            data: loginCredentials,
        },
        success: (accountInfo) => [slices.account.actions.set(accountInfo), routerHistory.push("/checkout")],
    });

export default login;
