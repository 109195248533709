import { api } from "../utilityActions";

const updateSession = () => (dispatch, getState) => {
    const store = getState();
    dispatch(
        api({
            call: {
                url: "/updateSession",
                method: "post",
                data: store,
            },
        })
    );
};

export default updateSession;
