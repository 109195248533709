import React from "react";
import { SeoBoxLabel, Text } from "../Atoms";
import { DotLabel, LocalRankingPlot } from "../Molecules";
import { Box } from "../styledSystemUtilities";
import { Chart } from "./Chart";

export const SeoChartCard = ({ seoChartData }) => {
    return (
        <Box pl={9} pb={7} pt={5} display={"flex"} backgroundColor={"white"} flexDirection={"column"}>
            <Text variant={"body3"} fontWeight={"medium"} color={"auratiumGreen"}>
                Current Results vs. Your Competition
            </Text>
            <Box mb={3} />
            <Text variant={"body4"}>Moving up this graph requires out-performing those ahead.</Text>
            <Box mb={3} />
            <Box display={"flex"} alignItems={"center"}>
                <DotLabel fill={"sienna"}>Your Practice</DotLabel>
                <Box ml={11} />
                <DotLabel fill={"auratiumGreen"}>Top Competitor</DotLabel>
                <Box ml={11} />
                <DotLabel fill={"slateGray"}>Top 30</DotLabel>
            </Box>
            <Chart
                xLabel={"Overall Rank"}
                yLabel={"Avg. Keyword Rank"}
                renderPlot={() => <LocalRankingPlot data={seoChartData} />}
            />
            <Box mb={6} />
            <Box display={"flex"} justifyContent={"center"}>
                <SeoBoxLabel fill={"seaGreen"}>LOW</SeoBoxLabel>
                <Box ml={14} />
                <SeoBoxLabel fill={"hsl(149, 21%, 76%, .5)"}>AVERAGE</SeoBoxLabel>
                <Box ml={14} />
                <SeoBoxLabel fill={"white"}>IDEAL</SeoBoxLabel>
            </Box>
        </Box>
    );
};
