import styled from "styled-components";
import { all } from "../styledSystemUtilities";

export const ContentContainer = styled.div`
    display: grid;
    justify-content: center;
    height: 92vh;

    @media (max-width: ${(props) => props.theme.breakpoints[2]}px) {
        grid-template-columns: minmax(320px, 375px);
    }

    @media (min-width: ${(props) => props.theme.breakpoints[2]}px) {
        grid-template-columns: 375px 375px;
        column-gap: 12px;
    }
    ${all}
`;
