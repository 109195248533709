import React from "react";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Text } from "./Text";
import { LionSpeakProductCardLogo } from "./assets";
import { ProductCardSelect } from "./ProductCardSelect";
import { ProductDetails } from "./ProductCard";
import { useCart } from "../../hooks/useCart";
import { useSetPopup } from "../../hooks/useSetPopup";
import { ProductBottomRow } from "./ProductBottomRows";

export const LionSpeakProductCard = ({ product }) => {
    const [isAdded, toggleProduct, updateProduct, watchedProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    return (
        <Box data-testid={watchedProduct.recurlyCode}>
            <FlexRowAlignCenter justifyContent={"center"} backgroundColor={"catskillWhite"} pt={"14px"} pb={"6px"}>
                <LionSpeakProductCardLogo />
            </FlexRowAlignCenter>
            <ProductDetails
                price={watchedProduct.price}
                description={watchedProduct.description}
                name={watchedProduct.name}
            />
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                backgroundColor={"catskillWhite"}
                pb={"13px"}
                px={"13px"}
            >
                <Text variant={"label2"} color={"balticBlue"}>
                    Choose Training Level:
                </Text>
                <Box width={"192px"} data-testid={`trainingLevelSelect${product.id}`}>
                    <ProductCardSelect
                        onChange={(option) => {
                            updateProduct({
                                ...watchedProduct,
                                defaultValue: option,
                                price: option.price,
                                recurlyCode: option.recurlyCode,
                            });
                        }}
                        defaultValue={watchedProduct.defaultValue}
                        options={watchedProduct.options}
                        classNamePrefix={"trainingLevel"}
                    />
                </Box>
            </FlexRowAlignCenter>
            <ProductBottomRow
                color={"nauticBlue"}
                backgroundColor={"pattensBlue"}
                isAdded={isAdded}
                toggleProduct={toggleProduct}
                setPopup={setPopup}
            />
        </Box>
    );
};
