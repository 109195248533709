import React from "react";
import { Box } from "../styledSystemUtilities";
import { MenuLogo, Text } from "../Atoms";
import theme from "../../theme";
import { ArrowRight, CartIcon, ComputerIcon, EmptyCart, GrowthIcon, PhoneIcon, ProfitIcon } from "../Atoms/assets";
import { useMeasure, useWindowSize } from "react-use";
import { routerHistory } from "../../routerHistory";
import { useSelector } from "react-redux";
import _ from "lodash/fp";
import { formatter } from "../../utils/formatter";
import { useOnlineEstimates } from "../../hooks/useOnlineEstimates";
import { useGrowthEstimates } from "../../hooks/useGrowthEstimates";

export const OnlineMenu = ({ hideSummary }) => (
    <Menu
        backColor={"forrestGreen"}
        frontColor={"seaGreen"}
        Icon={ComputerIcon}
        bannerColor={"rgba(153, 194, 173, .35)"}
        summaryColor={"#F0F6F5"}
        hideSummary={hideSummary}
        menuItems={[
            { name: "online", link: "/online-one", color: "seaGreen" },
            { name: "growth", link: "/growth-one", color: "ipanemaBlue" },
            { name: "profit", link: "/profit-one", color: "balticBlue" },
        ]}
    />
);

export const GrowthMenu = ({ hideSummary }) => (
    <Menu
        backColor={"deepPetrol"}
        frontColor={"ipanemaBlue"}
        Icon={GrowthIcon}
        bannerColor={"rgba(50, 153, 152, .35)"}
        summaryColor={"#E9F4F7"}
        hideSummary={hideSummary}
        menuItems={[
            { name: "growth", link: "/growth-one", color: "ipanemaBlue" },
            { name: "online", link: "/online-one", color: "seaGreen" },
            { name: "profit", link: "/profit-one", color: "balticBlue" },
        ]}
    />
);

export const ProfitMenu = ({ hideSummary }) => (
    <Menu
        backColor={"nauticBlue"}
        frontColor={"balticBlue"}
        Icon={ProfitIcon}
        bannerColor={"rgba(82, 121, 145, .35)"}
        summaryColor={"#E9F1F7"}
        hideSummary={hideSummary}
        menuItems={[
            { name: "profit", link: "/profit-one", color: "balticBlue" },
            { name: "online", link: "/online-one", color: "seaGreen" },
            { name: "growth", link: "/growth-one", color: "ipanemaBlue" },
        ]}
    />
);

const Menu = ({ frontColor, backColor, Icon, menuItems, bannerColor, summaryColor, hideSummary }) => {
    const cart = useSelector((state) => state.cart);
    const { width: windowWidth } = useWindowSize();
    const [ref, { width }] = useMeasure();
    const isBestForDentistry = useSelector((state) => state.isBestForDentistry);

    const onlineProductsInCart = _.filter(["type", "online"], cart);
    const onlineEstimates = useOnlineEstimates(onlineProductsInCart);

    const growthProducts = _.filter(["type", "growth"], cart);
    const growthEstimates = useGrowthEstimates(growthProducts);

    const totalPatientRange =
        onlineEstimates.highEstimate === 0
            ? "0"
            : `${onlineEstimates.lowEstimate + growthEstimates.estimate}-${
                  onlineEstimates.highEstimate + growthEstimates.estimate
              }`;

    const isDesktop = windowWidth > 1000;
    const isTablet = windowWidth > 630;

    return (
        <Box position={"fixed"} zIndex={4}>
            <Box position={"absolute"}>
                <Box zIndex={-1} position={"absolute"} height={"52px"} backgroundColor={backColor} width={"100%"} />
                <Box height={"21px"} width={"100vw"} />
                {/*<Gradient />*/}
                {/*<Box height={5} backgroundColor={frontColor} />*/}
                <Box display={"grid"} gridTemplateColumns={`1fr ${isTablet ? "543px" : "minmax(320px, 630px)"} 1fr`}>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        gridColumn={2}
                        transform={isTablet ? "translate(-44px, 0)" : "translate(0,0)"}
                    >
                        <Box display={"flex"}>
                            {isTablet ? (
                                <Box
                                    width={"142px"}
                                    height={"112px"}
                                    background={`linear-gradient(110deg, transparent 23%, ${theme.colors[frontColor]} 23% 77%, transparent 77% 100%)}`}
                                >
                                    <Text
                                        pt={"10px"}
                                        ml={"64px"}
                                        variant={"label1"}
                                        color={backColor}
                                        fontWeight={"bold"}
                                        uppercase
                                        cursor={"pointer"}
                                        onClick={() => routerHistory.push(menuItems[0].link)}
                                    >
                                        {menuItems[0].name}
                                    </Text>
                                    <Box pl={"52px"} pt={"38px"}>
                                        <Icon fill={backColor} />
                                    </Box>
                                </Box>
                            ) : null}
                            {isTablet ? null : <Box width={"13px"} backgroundColor={frontColor} />}

                            <MenuItem
                                backgroundColor={menuItems[1].color}
                                name={menuItems[1].name}
                                link={menuItems[1].link}
                                transform={"translate(-13px, 0)"}
                            />
                            <MenuItem
                                backgroundColor={menuItems[2].color}
                                name={menuItems[2].name}
                                link={menuItems[2].link}
                                transform={"translate(-26px, 0)"}
                            />
                        </Box>
                        <Box display={"flex"} height={"31px"} alignItems={"center"}>
                            <Box
                                cursor={"pointer"}
                                onClick={() => routerHistory.push("/cart")}
                                data-testid={"menuCartIcon"}
                            >
                                {cart.length > 0 ? (
                                    <>
                                        <Text
                                            variant={"label2"}
                                            fontWeight={"bold"}
                                            color={"white"}
                                            position={"absolute"}
                                            transform={`translate(${cart.length > 9 ? 5 : 8}px, -4px)`}
                                        >
                                            {cart.length}
                                        </Text>
                                        <EmptyCart />
                                    </>
                                ) : (
                                    <CartIcon />
                                )}
                            </Box>
                            <Box pl={"20px"} />
                            <Box
                                cursor={"pointer"}
                                onClick={() => {
                                    window.open(
                                        isBestForDentistry
                                            ? "https://calendly.com/mike-sieber/consultation"
                                            : "https://calendly.com/mike-s-5/ascent-marketing-consultation",
                                        "_blank"
                                    );
                                }}
                            >
                                <PhoneIcon />
                            </Box>

                            <Box pl={isTablet ? "68px" : "20px"} />
                        </Box>
                    </Box>
                    <Box width={"100%"} ref={ref} />
                </Box>
            </Box>
            <Box pb={"52px"} />
            <Box display={"flex"} position={"absolute"} zIndex={-1}>
                {isTablet ? (
                    <Box
                        backgroundColor={bannerColor}
                        height={"81px"}
                        width={`${width + 56}px`}
                        display={"flex"}
                        alignItems={"center"}
                        pl={"30px"}
                    >
                        {isDesktop ? <MenuLogo /> : null}
                    </Box>
                ) : null}

                <Box
                    background={`linear-gradient(110deg, ${summaryColor} ${
                        isTablet ? "93%, transparent 93% 100%" : "0 100%"
                    })}`}
                    width={isTablet ? "436px" : `${windowWidth}px`}
                    pl={isTablet ? "50px" : 0}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={isTablet ? "flex-start" : "center"}
                    justifyContent={"center"}
                    height={hideSummary ? "17px" : "81px"}
                >
                    {hideSummary ? null : (
                        <>
                            <Row addMargin={!isTablet}>
                                <Text variant={"body4"} fontWeight={"medium"}>
                                    ROI Estimate Totals:
                                </Text>
                                <Text variant={"label1"} justifySelf={"end"} color={"slateGray"}>
                                    New Patients:
                                </Text>
                                <Text variant={"label1"} justifySelf={"end"} color={"slateGray"}>
                                    ROI:
                                </Text>
                            </Row>
                            <Row addMargin={!isTablet}>
                                <Text variant={"label3"} color={"auratiumGreen"} fontWeight={"medium"}>
                                    ONLINE
                                </Text>
                                <Text
                                    fontFamily={"montserrat"}
                                    fontSize={"11px"}
                                    fontWeight={"medium"}
                                    justifySelf={"end"}
                                    color={"auratiumGreen"}
                                >
                                    {onlineEstimates.estimate}
                                </Text>
                                <Text
                                    fontFamily={"montserrat"}
                                    fontSize={"11px"}
                                    fontWeight={"medium"}
                                    justifySelf={"end"}
                                    color={"auratiumGreen"}
                                >
                                    {formatter.format(_.round(onlineEstimates.roi))}
                                </Text>
                            </Row>
                            <Row addMargin={!isTablet}>
                                <Text variant={"label3"} color={"ipanemaBlue"} fontWeight={"medium"}>
                                    GROWTH
                                </Text>
                                <Text
                                    fontFamily={"montserrat"}
                                    fontSize={"11px"}
                                    fontWeight={"medium"}
                                    justifySelf={"end"}
                                    color={"ipanemaBlue"}
                                >
                                    {growthEstimates.estimate}
                                </Text>
                                <Text
                                    fontFamily={"montserrat"}
                                    fontSize={"11px"}
                                    fontWeight={"medium"}
                                    justifySelf={"end"}
                                    color={"ipanemaBlue"}
                                >
                                    {formatter.format(growthEstimates.roi)}
                                </Text>
                            </Row>
                            <Row borderTop={"mid"} addMargin={!isTablet}>
                                <Text variant={"label3"} fontWeight={"medium"}>
                                    TOTAL
                                </Text>
                                <Text
                                    fontFamily={"montserrat"}
                                    fontSize={"11px"}
                                    fontWeight={"medium"}
                                    justifySelf={"end"}
                                    color={"graphiteGray"}
                                >
                                    {totalPatientRange}
                                </Text>
                                <Text
                                    fontFamily={"montserrat"}
                                    fontSize={"11px"}
                                    fontWeight={"medium"}
                                    justifySelf={"end"}
                                    color={"graphiteGray"}
                                >
                                    {formatter.format(growthEstimates.roi + onlineEstimates.roi)}
                                </Text>
                            </Row>
                        </>
                    )}
                </Box>
                <Box
                    width={`${width + 80}px`}
                    transform={"translate(-30px, 0)"}
                    height={"17px"}
                    backgroundColor={"rgba(231, 239, 237, .64)"}
                    position={"relative"}
                    zIndex={-1}
                />
            </Box>
        </Box>
    );
};

const MenuItem = ({ backgroundColor, name, link, ...props }) => {
    return (
        <Box
            width={"106px"}
            height={"31px"}
            background={`linear-gradient(110deg, transparent 10%, ${theme.colors[backgroundColor]} 10% 90%, transparent 90% 100%)}`}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            cursor={"pointer"}
            onClick={() => routerHistory.push(link)}
            {...props}
        >
            <Text variant={"label1"} color={"white"} fontWeight={"bold"} uppercase>
                {name}
            </Text>
            <Box pl={3} />
            <ArrowRight fill={"white"} />
        </Box>
    );
};

const Row = ({ children, addMargin, ...props }) => {
    return (
        <Box
            display={"grid"}
            height={"15px"}
            maxWidth={"326px"}
            gridTemplateColumns={"minmax(140px, 178px) 68px 80px"}
            alignItems={"center"}
            mx={addMargin ? "20px" : 0}
            {...props}
        >
            {children}
        </Box>
    );
};
