import { Text } from "./Text";
import { Box, FlexCol, FlexRowAlignCenter, Grid } from "../styledSystemUtilities";
import { formatter } from "../../utils/formatter";
import { QuestionIcon } from "./assets";
import React from "react";
import { useSetPopup } from "../../hooks/useSetPopup";

export const EstimateRow = ({ estimates, color, questionColor, text, roiPopup }) => {
    const setRoiPopup = useSetPopup(roiPopup);
    return (
        <Box px={"24px"}>
            <Text color={color} variant={"body3"} fontWeight={"bold"}>
                {text}
            </Text>
            <Box pb={"8px"} />
            <Grid gridTemplateColumns={"1fr 1fr 1fr"}>
                <EstimateCell label={"New Patients/Mo."} estimate={estimates.estimate} color={color} />
                <EstimateCell
                    label={"Revenue Per Month"}
                    estimate={formatter.format(estimates.revenue)}
                    color={color}
                />
                <EstimateCell
                    label={"ROI"}
                    estimate={formatter.format(estimates.roi)}
                    color={color}
                    questionIcon={
                        <QuestionIcon fill={questionColor} ml={"7px"} onClick={setRoiPopup} data-testid={"roiIcon"} />
                    }
                />
            </Grid>
        </Box>
    );
};

const EstimateCell = ({ estimate, label, questionIcon, color }) => (
    <FlexCol alignItems={"center"}>
        <Text color={"sienna"} fontFamily={"montserrat"} fontSize={"20px"}>
            {estimate}
        </Text>
        <Box pb={"2px"} />
        <FlexRowAlignCenter>
            <Text variant={"body4"} color={color}>
                {label}
            </Text>
            {questionIcon}
        </FlexRowAlignCenter>
    </FlexCol>
);
