import { Box, Span } from "../../styledSystemUtilities";
import { LeftCornerLine, MiddleLine, Operator, RightCornerLine, Row, Text } from "../../Atoms";
import lodash from "lodash";
import _ from "lodash/fp";
import React from "react";
import { formatter } from "../../../utils/formatter";
import { BlueCell, BoldWhiteCell, WhiteCell } from "../../Atoms/GrowthDetails";
import { X } from "../../Atoms/assets";
import { useWindowSize } from "react-use";
import { useSelector } from "react-redux";
import { getDirectMailDetails } from "../../../utils/getGrowthDetails";
import { directMailAverages } from "../../../constants/directMailAverages";

const Header = ({ setPopup }) => {
    const { width } = useWindowSize();
    return (
        <>
            <Box
                backgroundColor={"petrolBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup((prevState) => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Box pb={"31px"} />
        </>
    );
};

export const MinizineDataPath = ({ setPopup }) => {
    return <DataPath setPopup={setPopup} averages={directMailAverages.minizine} />;
};

export const PostcardDataPath = ({ setPopup }) => {
    return <DataPath setPopup={setPopup} averages={directMailAverages.postcard} />;
};

const DataPath = ({ setPopup, averages }) => {
    const growthGoal = useSelector((state) => state.growthGoal);
    const details = getDirectMailDetails(growthGoal, averages);

    return (
        <>
            <Header setPopup={setPopup} />
            <Box backgroundColor={"#E0F1F1"} pl={"24px"} pt={"40px"} pb={"14px"}>
                <Text variant={"body3"} color={"deepPetrol"} fontWeight={"medium"}>
                    Data Path
                </Text>
            </Box>
            <Box backgroundColor={"white"} pt={9} pb={8} px={4}>
                <Box display={"flex"}>
                    <Box width={6} />
                    <Row>
                        <BoldWhiteCell label={"New patient goal"} value={details.monthlyGoal} />
                        <Operator operator={"/"} />
                        <WhiteCell
                            value={`${details.callConversion * 100}%`}
                            color={"ipanemaBlue"}
                            label={"Call Conversion %"}
                        />
                        <Operator operator={"="} />
                        <WhiteCell value={lodash.round(details.neededCalls, 1)} label={"Needed calls"} />
                    </Row>
                    <RightCornerLine />
                </Box>
                <MiddleLine />
                <Box display={"flex"}>
                    <LeftCornerLine operator={"/"} />
                    <Row>
                        <WhiteCell
                            value={`${details.mailerToCall * 100}%`}
                            color={"ipanemaBlue"}
                            label={"Mailer to call %"}
                        />
                        <Operator operator={"="} />
                        <WhiteCell value={_.round(details.mailersNeeded)} label={"Mailers needed"} />
                        <Operator operator={"="} />
                        <WhiteCell
                            value={formatter.format(_.round(details.mailersNeeded * details.costPerMailer))}
                            label={"Price per mailing"}
                        />
                    </Row>
                    <RightCornerLine />
                </Box>
                <MiddleLine />
                <Box display={"flex"}>
                    <LeftCornerLine operator={"+"} />
                    <Row>
                        <WhiteCell value={formatter.format(details.managementFee)} label={"Ascent management"} />
                        <Operator operator={"="} />
                        <BlueCell value={formatter.format(_.round(details.monthlyPrice))} label={"Monthly cost"} />
                        <Operator operator={"="} />
                        <WhiteCell value={details.requiredMailings} label={"Required mailings"} color={"sienna"} />
                    </Row>
                    <Box width={4} />
                </Box>
                <Box pb={"3px"} />
                <Box display={"flex"}>
                    <Box width={4} />
                    <Row>
                        <Text color={"petrolBlue"} variant={"label2"} alignSelf={"flex-start"}>
                            Fixed monthly fee
                        </Text>
                        <Box gridColumn={4}>
                            <Operator operator={"+"} />
                        </Box>

                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            gridColumn={5}
                            width={"100%"}
                            mt={"2px"}
                        >
                            <Text variant={"label2"} lineHeight={"14px"} textAlign={"center"} color={"petrolBlue"}>
                                Final Mailing
                                <br />
                                (If Cancelled)
                            </Text>
                            <Box mb={"-2px"} />
                            <WhiteCell value={formatter.format(_.round(details.finalMailing))} />
                        </Box>
                    </Row>
                    <Box width={4} />
                </Box>
                <Box mb={7} />
                <Box mx={7}>
                    <Text variant={"label2"} lineHeight={"14px"}>
                        Numbers in{" "}
                        <Span color={"ipanemaBlue"} fontWeight={"medium"}>
                            GREEN
                        </Span>{" "}
                        are conservative estimates based on our clients' national averages. After engagement, we will
                        track your actual conversion percentages for greater accuracy and predictability. Over time,
                        your data enables ever improving predictability in reaching goals.
                    </Text>
                    <Box mb={4} />
                    <Text variant={"label2"} lineHeight={"14px"}>
                        Required mailings are in{" "}
                        <Span fontWeight={"medium"} color={"sienna"}>
                            ORANGE
                        </Span>{" "}
                        and based off a minimum print run of 15,000 mailers. Once printed, all 15,000 must be mailed and
                        all associated costs must be paid in full. Unless cancelled, mailings will continue at the rate
                        listed under the “Total monthly cost” as a subscription service.
                    </Text>
                    <Box mb={4} />
                    <Text variant={"label2"} lineHeight={"14px"}>
                        A note on our <Span fontWeight={"medium"}>MANAGEMENT FEE</Span>: Unlike most marketing
                        companies, our management fee is a fixed rate as opposed to a % of the total spend. Our
                        competitors approach incentivizes increasing your ad spend. Ours incentivizes decreasing your ad
                        spend to prolong the relationship.
                    </Text>
                    <Box mb={3} />
                    <Text color={"ipanemaBlue"} variant={"body3"}>
                        Cancellation
                    </Text>
                    <Box mb={3} />
                    <Text variant={"label2"} lineHeight={"14px"}>
                        This campaign can be canceled every 15,000 mailers with 60 days advance notice (prior to
                        printing the next quantity of 15,000). All months (including setup and printing) are billed at
                        the campaign rate, and are billed monthly on a subscription basis. We recommend allowing at
                        least 2 - 15,000 mailer campaigns to run to collect meaningful data.
                    </Text>
                </Box>
            </Box>
        </>
    );
};
