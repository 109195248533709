import React from "react";
import { Box } from "../styledSystemUtilities";
import { Text } from "./Text";
import {
    CampaignSummaryRaw,
    CostPerClickChartRaw,
    DirectMailCallsRaw,
    DirectMailCampaignSummaryRaw,
    DirectMailCostPerPatientRaw,
    DirectMailGoalPlanningRaw,
    DirectMailMonthToDateRaw,
    DirectMailTableRaw,
    GaGraphRaw,
    GmbGraphRaw,
    GoalPlanningRaw,
    MonthToDateRaw,
    PpcCallTableRaw,
    PpcCostPerPatientRaw,
    PpcTableRaw,
    ReviewsOverTimeRaw,
    ReviewsOverTimeTableRaw,
    ReviewsPlotRaw,
    ReviewsTableRaw,
    SeoOverTimeRaw,
    SeoPlotRaw,
    SeoTableRaw,
    VisibilityTableRaw,
    VisibilityWheelRaw,
} from "./assets";

export const GaGraphSample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <GaGraphRaw />
        </SampleChartTemplate>
    );
};

export const GmbGraphSample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <GmbGraphRaw />
        </SampleChartTemplate>
    );
};

export const ReviewsPlotSample = () => {
    return (
        <SampleChartTemplate>
            <ReviewsPlotRaw />
        </SampleChartTemplate>
    );
};

export const ReviewsOverTimeSample = () => {
    return (
        <SampleChartTemplate>
            <ReviewsOverTimeRaw />
        </SampleChartTemplate>
    );
};

export const ReviewsOverTimeTableSample = () => {
    return (
        <SampleTableTemplate>
            <ReviewsOverTimeTableRaw />
        </SampleTableTemplate>
    );
};

export const ReviewsTableSample = () => {
    return (
        <SampleTableTemplate>
            <ReviewsTableRaw />
        </SampleTableTemplate>
    );
};

export const SeoOverTimeSample = () => {
    return (
        <SampleChartTemplate>
            <SeoOverTimeRaw />
        </SampleChartTemplate>
    );
};

export const SeoPlotSample = () => {
    return (
        <SampleChartTemplate>
            <SeoPlotRaw />
        </SampleChartTemplate>
    );
};

export const SeoTableSample = () => {
    return (
        <SampleTableTemplate>
            <SeoTableRaw />
        </SampleTableTemplate>
    );
};

export const VisibilityWheelSample = () => {
    return (
        <SampleChartTemplate>
            <Box ml={"-50px"}>
                <VisibilityWheelRaw />
            </Box>
        </SampleChartTemplate>
    );
};

export const VisibilityTableSample = () => {
    return (
        <SampleTableTemplate>
            <VisibilityTableRaw />
        </SampleTableTemplate>
    );
};

export const CampaignSummarySample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <CampaignSummaryRaw />
        </SampleChartTemplate>
    );
};

export const GoalPlanningSample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <GoalPlanningRaw />
        </SampleChartTemplate>
    );
};

export const MonthToDateSample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <MonthToDateRaw />
        </SampleChartTemplate>
    );
};

export const CostPerClickSample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <CostPerClickChartRaw />
        </SampleChartTemplate>
    );
};

export const PpcTableSample = ({ name }) => {
    return (
        <SampleTableTemplate name={name}>
            <PpcTableRaw />
        </SampleTableTemplate>
    );
};

export const PpcCallSample = ({ name }) => {
    return (
        <SampleTableTemplate name={name}>
            <PpcCallTableRaw />
        </SampleTableTemplate>
    );
};

export const PpcCostPerPatientSample = ({ name }) => {
    return (
        <SampleTableTemplate name={name}>
            <PpcCostPerPatientRaw />
        </SampleTableTemplate>
    );
};

export const DirectMailCampaignSummarySample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <DirectMailCampaignSummaryRaw />
        </SampleChartTemplate>
    );
};

export const DirectMailGoalPlanningSample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <DirectMailGoalPlanningRaw />
        </SampleChartTemplate>
    );
};

export const DirectMailMonthToDateSample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <DirectMailMonthToDateRaw />
        </SampleChartTemplate>
    );
};

export const DirectMailCallsSample = ({ name }) => {
    return (
        <SampleChartTemplate name={name}>
            <DirectMailCallsRaw />
        </SampleChartTemplate>
    );
};

export const DirectMailTableSample = ({ name }) => {
    return (
        <SampleTableTemplate name={name}>
            <DirectMailTableRaw />
        </SampleTableTemplate>
    );
};

export const DirectMailCostPerPatientSample = ({ name }) => {
    return (
        <SampleTableTemplate name={name}>
            <DirectMailCostPerPatientRaw />
        </SampleTableTemplate>
    );
};

function SampleChartTemplate({ children, name }) {
    return (
        <Box mb={6}>
            {name ? (
                <Text variant={"body3"} fontWeight={"medium"} mb={2}>
                    {name}
                </Text>
            ) : null}
            {children}
            <Text variant={"label3"} fontWeight={"medium"}>
                (Sample of Ascent Reporting)
            </Text>
        </Box>
    );
}

function SampleTableTemplate({ children, name }) {
    return (
        <>
            {name ? (
                <Text variant={"body3"} fontWeight={"medium"} mb={2}>
                    {name}
                </Text>
            ) : null}
            <Box mb={6} display={"flex"} justifyContent={"center"}>
                {children}
            </Box>
        </>
    );
}
