import React from "react";
import { Box } from "../../styledSystemUtilities";
import { TriFoldHero } from "../../Atoms/assets";
import { CopyWriting, Pen, Printer, Shipping, Strategy } from "../../Atoms";
import { Dimensions, GrayBackground, IconContainer, PricingNotes, SellingPoint } from "../../Molecules";
import { ProfitHeader } from "../../Molecules/PopupComponents";

export const TriFoldBrochure = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <ProfitHeader setPopup={setPopup} Hero={TriFoldHero} />
            <GrayBackground>
                <SellingPoint
                    header={"The right brochure can make a big difference"}
                    body={
                        "We recommend using the try-fold brochure to educate patients about your ancillary services, or as an overview of your practice to use externally. You can even do one for each use!"
                    }
                />
                <Dimensions bold={"8.5” x 11”"} light={"– Tri-folded"} />
                <SellingPoint
                    header={"Why Ascent for your brochure?"}
                    body={
                        "• Better brand Integration\n" +
                        "• High-end, original design \n" +
                        "• We specialize in dental – It’s all we do\n" +
                        "• Smart strategy\n" +
                        "• Professional copywriting\n" +
                        "• It’s easier – We’re a one-stop-shop\n" +
                        "• Timely delivery"
                    }
                    lineHeight={"24px"}
                />
                <IconContainer name={"What's Included"}>
                    <Pen name={"Design"} />
                    <Strategy name={"Strategy"} fill={"balticBlue"} />
                    <CopyWriting name={"Copywriting"} fill={"balticBlue"} />
                    <Printer name={"Printing"} />
                    <Shipping name={"Shipping"} />
                </IconContainer>
            </GrayBackground>
            <PricingNotes>
                Print materials are purchased on a per-item basis and must be paid in full. We offer the choice of a
                1-time payment or 12 payments at a monthly interest rate of 12%.
            </PricingNotes>
        </Box>
    );
};
