import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "user",
    initialState: {
        firstName: "",
        lastName: "",
        practiceName: "",
        email: "",
        zipCode: "",
        practiceType: "",
        isCanada: false,
    },
    reducers: {
        set: (state, action) => action.payload,
        update: (state, action) => ({ ...state, ...action.payload }),
    },
});
