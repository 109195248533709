const colors = {
    forrestGreen: "#5e986f",
    auratiumGreen: "#74AD85",
    seaGreen: "#99C2AD",
    deepPetrol: "#0d6877",
    petrolBlue: "#107889",
    ipanemaBlue: "#329998",
    amaranthRed: "#B94F4F",
    sienna: "#E27867",
    nauticBlue: "#28415C",
    balticBlue: "#527991",
    graphiteGray: "#343434",
    agateGray: "#7F7F7F",
    slateGray: "#A7A9AC",
    midGray: "#CCCCCC",
    pearlGray: "#E6E7E8",
    chalk: "#F8F8F9",
    slateBlue: "#90A5B7",
    veniceBlue: "#B3C0CD",
    meissenBlue: "#E7F0F5",
    apricot: "#F6D6D1",
    lightApricot: "#FCF1EF",
    white: "#ffffff",
    backgroundGreen: "#C5DBD0",
    backgroundBlue: "#B2DDDC",
    backgroundGray: "#B9C6CE",
    cardGreen: "#EEF5F1",
    summaryGreen: "#F0F6F5",
    rightBannerGreen: "rgba(231, 239, 237, .64)",
    bannerGreen: "rgba(153, 194, 173, .35)",
    blueLine: "#88C4C3",
    cellBlue: "#E9F4F4",
    pattensBlue: "#DEE7ED",
    catskillWhite: "#F2F6F9",
    surfGreen: "rgba(214, 231, 222, .4)",
    zigguratBlue: "rgba(178, 221, 220, .4)",
    mysticBlue: "#ECF1F3",
};

const fontWeights = {
    thin: 100,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
};

const breakpoints = [762, 900, 1118];

const theme = {
    colors,
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    space: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    sizes: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    fonts: {
        roboto: "Roboto",
        montserrat: "Montserrat",
    },
    fontWeights,
    //         [0, 1,  2,  3,  4,  5,  6,  7,  8,  9 ]
    fontSizes: [9, 10, 11, 12, 14, 16, 18, 20, 27, 44],
    borders: {
        gray: `1px solid ${colors.pearlGray}`,
        mid: `1px solid ${colors.midGray}`,
        dark: `1px solid ${colors.agateGray}`,
        green: `1px solid #CCE1D6`,
        productGreen: `1px solid #80AB88`,
        thickGreen: `2px solid ${colors.seaGreen}`,
        auratiumGreen: `1px solid ${colors.auratiumGreen}`,
        petrol: `1px solid #1A7888`,
        petrolBlue: `1px solid ${colors.petrolBlue}`,
        ipanema: `1px solid ${colors.ipanemaBlue}`,
        blueLine: `1px solid ${colors.blueLine}`,
        cart: `1px solid #707070`,
        graphiteGray: `1px solid ${colors.graphiteGray}`,
    },
    breakpoints,
};

export default theme;
