import {
    AccordianBrochure,
    AncillaryBurkhart,
    AncillaryServices,
    BrandDefinition,
    BusinessCards,
    CaseAcceptance,
    CaseAcceptanceBurkhart,
    DirectMail,
    EightPageBrochure,
    EnhancedSeo,
    EnhancedWebsite,
    Envelopes,
    EssentialSeo,
    EssentialWebsite,
    GrowthRoiExplanation,
    InsuranceManagement,
    Letterhead,
    LogoDesign,
    Minizine,
    MySocialPracticePro,
    MySocialPracticeSelf,
    OneYearEDent,
    PatientReferrals,
    PhoneSkills,
    PhoneSkillsBurkhart,
    Ppc,
    PremiumDentalMenu,
    PresentationFolders,
    ProEDent,
    PromotionalCards,
    PromotionalFlyers,
    Reactivation,
    ReactivationBurkhart,
    ReferralCards,
    ReferralsBurkhart,
    ReviewGeneration,
    RoiExplanation,
    SocialForSeo,
    StandardDentalMenu,
    TriFoldBrochure,
} from "../Components/Organisms/Popups";
import { MinizineDataPath, PostcardDataPath } from "../Components/Organisms/Popups/DataPath";

export const popups = {
    essentialWebsite: EssentialWebsite,
    reviewGeneration: ReviewGeneration,
    essentialSeo: EssentialSeo,
    enhancedSeo: EnhancedSeo,
    socialForSeo: SocialForSeo,
    enhancedWebsite: EnhancedWebsite,
    googleAds: Ppc,
    postcard: DirectMail,
    minizine: Minizine,
    brandDefinition: BrandDefinition,
    logoDesign: LogoDesign,
    insuranceManagement: InsuranceManagement,
    phoneSkillsTraining: PhoneSkills,
    patientReferralTraining: PatientReferrals,
    caseAcceptanceTraining: CaseAcceptance,
    ancillaryServicesTraining: AncillaryServices,
    reactivationTraining: Reactivation,
    businessCards: BusinessCards,
    envelopes: Envelopes,
    letterhead: Letterhead,
    triFoldBrochure: TriFoldBrochure,
    eightPageBrochure: EightPageBrochure,
    accordionBrochure: AccordianBrochure,
    presentationFolders: PresentationFolders,
    promotionalFlyers: PromotionalFlyers,
    promotionalCards: PromotionalCards,
    patientReferralCards: ReferralCards,
    ancillaryServicesBrochure: AncillaryServices,
    reactivationCards: Reactivation,
    growthRoiExplanation: GrowthRoiExplanation,
    onlineRoiExplanation: RoiExplanation,
    minizineDataPath: MinizineDataPath,
    postcardDataPath: PostcardDataPath,
    reactivationBurkhart: ReactivationBurkhart,
    phoneSkillsBurkhart: PhoneSkillsBurkhart,
    referralsBurkhart: ReferralsBurkhart,
    ancillaryBurkhart: AncillaryBurkhart,
    caseAcceptanceBurkhart: CaseAcceptanceBurkhart,
    oneYearEDent: OneYearEDent,
    proEDent: ProEDent,
    standardDentalMenu: StandardDentalMenu,
    premiumDentalMenu: PremiumDentalMenu,
    mySocialPracticeSelf: MySocialPracticeSelf,
    mySocialPracticePro: MySocialPracticePro,
};
