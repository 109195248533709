import { Box } from "../styledSystemUtilities";
import { Checkbox, Text } from "../Atoms";
import _ from "lodash/fp";
import React from "react";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export const PaymentTypeSelect = ({ setPaymentType, paymentType, singlePayment, monthlyPayment }) => {
    return (
        <>
            <Box display={"flex"} pl={"7px"} mt={"5px"}>
                <Checkbox
                    onClick={() => setPaymentType((prevState) => "singlePayment")}
                    fill={"nauticBlue"}
                    backgroundColor={"white"}
                    isChecked={paymentType === "singlePayment"}
                    data-testid={"singlePayment"}
                />
                <Box ml={"7px"} />
                <Box width={"294px"}>
                    <Text variant={"body3"}>
                        I would like to pay for the these profitability items in one payment up front.
                    </Text>
                </Box>
            </Box>
            <Box mb={4} />
            <Box
                display={"grid"}
                gridTemplateColumns={"auto 85px"}
                alignItems={"center"}
                backgroundColor={"white"}
                height={"34px"}
                pl={"9px"}
            >
                <Text variant={"body3"} color={"nauticBlue"}>
                    1 Payment Total
                </Text>
                <Text variant={"body3"} fontWeight={"bold"} data-testid={"total"}>
                    {formatter.format(_.round(singlePayment))}
                </Text>
            </Box>
            <Box mb={8} />
            <Box display={"flex"} pl={"7px"}>
                <Checkbox
                    onClick={() => setPaymentType((prevState) => "monthlyPayment")}
                    fill={"nauticBlue"}
                    backgroundColor={"white"}
                    isChecked={paymentType === "monthlyPayment"}
                    data-testid={"monthlyPayment"}
                />
                <Box ml={"7px"} />
                <Box width={"294px"}>
                    <Text variant={"body3"}>
                        I would like to finance these profitability items over 12 months. This will be paid monthly with
                        a monthly interest rate of 12%.
                    </Text>
                </Box>
            </Box>
            <Box mb={4} />
            <Box
                display={"grid"}
                gridTemplateColumns={"auto 85px"}
                alignItems={"center"}
                backgroundColor={"white"}
                height={"34px"}
                pl={"9px"}
            >
                <Text variant={"body3"} color={"nauticBlue"}>
                    Monthly Payment (x12)
                </Text>
                <Text variant={"body3"} fontWeight={"bold"}>
                    {formatter.format(_.round(monthlyPayment))}
                </Text>
            </Box>
            <Box mb={"7px"} />
        </>
    );
};
