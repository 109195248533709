import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { DentalMenuLogo, HPALogo, UnitasProductCardLogo } from "./assets";
import React from "react";

export const DentalMenu = () => {
    return (
        <FlexRowAlignCenter backgroundColor={"catskillWhite"} pt={"14px"} pl={"11px"}>
            <DentalMenuLogo />
        </FlexRowAlignCenter>
    );
};

export const Unitas = () => {
    return (
        <FlexRowAlignCenter justifyContent={"center"} backgroundColor={"catskillWhite"} pt={"14px"} pb={"6px"}>
            <UnitasProductCardLogo />
        </FlexRowAlignCenter>
    );
};

export const Hpa = () => {
    return (
        <FlexRowAlignCenter backgroundColor={"catskillWhite"} pt={"14px"} justifyContent={"center"}>
            <HPALogo />
        </FlexRowAlignCenter>
    );
};
