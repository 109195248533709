import { api } from "../utilityActions";
import slices from "../slices";

const checkIfPracticeExists = (placeId) =>
    api({
        call: {
            url: `/account/checkIfPracticeExists/${placeId}`,
            method: "get",
        },
        success: slices.practiceExists.actions.set,
    });

export default checkIfPracticeExists;
