import { PAY_PER_CLICK } from "./productList";

export const ppcAverages = {
    ascentAverages: {
        cpc: 8.25,
        ctr: 0.06,
        ctc: 0.21,
        conversionRate: 0.2,
    },
    conservativeAverages: {
        cpc: 8.25,
        ctr: 0.048,
        ctc: 0.168,
        conversionRate: 0.16,
    },
    managementFee: PAY_PER_CLICK.managementFee,
    callTracking: 60,
};
