import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box } from "../styledSystemUtilities";
import _ from "lodash/fp";
import { Text } from "./Text";
import { phases } from "../../constants/phases";
import { ArrowRight } from "./assets";
import theme from "../../theme";

const PhaseBox = styled(Box)`
    height: 32px;
    width: 100px;
    background: ${(props) =>
        `linear-gradient(110deg, ${props.theme.colors[props.backgroundColor]} 91%, transparent 91% 100%)}`};
    display: flex;
    align-items: center;
    padding-left: 18px;
`;

export const Banner = ({ phaseName, hideLink }) => {
    const containerRef = useRef(null);
    const [width, setWidth] = useState(null);
    const isDesktop = width >= theme.breakpoints[2];

    const handleWindowResize = () => {
        setWidth(getWidth(containerRef));
    };

    useEffect(() => {
        setWidth((prevState) => getWidth(containerRef));
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <Box
            height={"38px"}
            backgroundColor={phases[phaseName].backColor}
            ref={containerRef}
            display={"grid"}
            gridTemplateColumns={isDesktop ? "1fr 762px 1fr" : "1fr minmax(320px, 375px) 1fr"}
        >
            <Box height={"32px"} backgroundColor={phases[phaseName].frontColor} />
            <Box
                backgroundColor={phases[phaseName].frontColor}
                width={"100%"}
                height={"9px"}
                display={"flex"}
                gridColumn={" 2 "}
                justifyContent={"space-between"}
            >
                <PhaseBox backgroundColor={phases[phaseName].frontColor} flexShrink={"0"}>
                    <Text variant={"body2"} fontWeight={"bold"} color={"white"}>
                        {_.capitalize(phaseName)}
                    </Text>
                </PhaseBox>
                {hideLink ? null : (
                    <Box
                        height={"41px"}
                        display={"flex"}
                        alignItems={"center"}
                        pt={2}
                        mr={"11px"}
                        cursor={"pointer"}
                        onClick={() => {
                            window.open("https://ascentpgs.com/consultation-20minutes.html", "_blank");
                        }}
                    >
                        <Text variant={"body4"} fontWeight={"medium"} color={"white"}>
                            Questions? Schedule a consultation
                        </Text>
                        <Box ml={1} />
                        <ArrowRight fill={"white"} />
                    </Box>
                )}
            </Box>
            <Box backgroundColor={phases[phaseName].frontColor} width={"100%"} height={"9px"} gridColumn={" 3 "} />
        </Box>
    );
};

function getWidth(containerRef) {
    return _.get("width", _.invoke("current.getBoundingClientRect", containerRef));
}
