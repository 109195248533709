import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash/fp";

export default createSlice({
    name: "profitOptions",
    initialState: {
        perceptions: false,
        conversion: false,
        referrals: false,
        ancillary: false,
        acceptance: false,
        reactivation: false,
        reimbursement: false,
    },
    reducers: {
        toggleOption: (state, action) => _.set(action.payload, !state[action.payload], state),
    },
});
