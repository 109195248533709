import React from "react";
import styled from "styled-components";

const ChartContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto;
`;

const BaseLabel = styled.div`
    color: #7f7f7f;
    text-align: center;
    font-size: 10px;
`;

const YLabel = styled(BaseLabel)`
    position: relative;
    grid-column: 1;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    grid-row: 1;
`;

const XLabel = styled(BaseLabel)`
    grid-column: 2;
    grid-row: 2;
    position: relative;
`;

const ChartWrap = styled.div`
    grid-column: 2;
    grid-row: 1;
`;

export const Chart = ({ renderPlot, xLabel, yLabel }) => {
    return (
        <ChartContainer>
            <YLabel>{yLabel}</YLabel>
            <XLabel>{xLabel}</XLabel>
            <ChartWrap>{renderPlot()}</ChartWrap>
        </ChartContainer>
    );
};
