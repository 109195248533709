import React from "react";
import styled from "styled-components";
import { all, Box } from "../styledSystemUtilities";
import { ArrowLeft, ArrowRight, CartIcon } from "./assets";

const BaseButton = styled("button")(
    ({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        fontSize: theme.fontSizes[2],
        textTransform: "uppercase",
        fontFamily: theme.fonts.roboto,
        fontWeight: theme.fontWeights.bold,
        color: theme.colors.white,
        height: theme.sizes[13],
        minWidth: "100%",
        ":focus": {
            outline: "none",
        },
        ":hover": {
            cursor: "pointer",
        },
    }),
    all
);

export const Button = (props) => {
    return props.direction === "left" ? (
        <BaseButton {...props}>
            <ArrowLeft fill={"white"} />
            <Box mr={"9px"} />
            {props.children}
        </BaseButton>
    ) : (
        <BaseButton {...props}>
            {props.children}
            <Box mr={"9px"} />
            <ArrowRight fill={"white"} />
        </BaseButton>
    );
};

export const IconButton = ({ Icon, children, ...props }) => (
    <BaseButton {...props}>
        {children}
        <Box pl={"8px"} />
        <Icon />
    </BaseButton>
);

const SmallBaseButton = ({ children, Icon, ...props }) => (
    <BaseButton height={"28px"} minWidth={"48%"} {...props}>
        {children}
        <Box mr={"6px"} />
        <Icon fill={"white"} />
    </BaseButton>
);

export const SmallButton = ({ children, backgroundColor, onClick }) => (
    <SmallBaseButton Icon={ArrowRight} backgroundColor={backgroundColor} onClick={onClick}>
        {children}
    </SmallBaseButton>
);
export const ShoppingCartButton = ({ backgroundColor, onClick }) => (
    <SmallBaseButton Icon={CartIcon} backgroundColor={backgroundColor} onClick={onClick}>
        view cart
    </SmallBaseButton>
);
