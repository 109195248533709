import React from "react";
import { OnlinePageTemplate } from "./PageTemplate";
import { OnlineHeader, OnlineSubHeader } from "../Atoms/Headers";
import { Box, FlexRow } from "../styledSystemUtilities";
import { Button, Text } from "../Atoms";
import { OnlineOneSummaryRow } from "../Atoms/OnlineSummaryRows";
import { CircleHeader } from "../Molecules/CircleHeader";
import { BigCheckbox } from "../Atoms/Checkbox";
import { routerHistory } from "../../routerHistory";
import { useOnlineOptions } from "../../hooks/useOnlineOptions";
import { onlineOptions } from "../../constants/onlineOptions";
// import { report } from "./data";
import { useSelector } from "react-redux";
import _ from "lodash/fp";
import { getVisibilityLevel } from "../../utils/getVisibilityLevel";

export const OnlineTwo = () => {
    const [option, setOption] = useOnlineOptions();
    const report = useSelector((state) => state.report);

    const visibilityLevel = getVisibilityLevel(report);

    return (
        <OnlinePageTemplate hideSummary={"true"}>
            <Box pt={"26px"} />
            <OnlineHeader textAlign={"center"}>Online Visibility Goals</OnlineHeader>
            <OnlineSubHeader>{_.get("user.practiceName", report)}</OnlineSubHeader>
            <Box pt={"20px"} />
            <OnlineOneSummaryRow
                rank={_.get("seo.overallRank", report)}
                competitionLevel={`${_.round(_.get("competitionLevel", report) * 100)}%`}
                visibilityLevel={visibilityLevel}
            />
            <Box pt={"20px"} />
            <CircleHeader
                number={2}
                color={"forrestGreen"}
                text={"Considering your current visibility data, what best describes you?"}
            />
            <Box pt={"20px"} />
            <OptionRow option={onlineOptions.improve} checked={option} setChecked={setOption} />
            <Box pt={"16px"} />
            <OptionRow option={onlineOptions.maintain} checked={option} setChecked={setOption} />
            <Box pt={"16px"} />
            <OptionRow option={onlineOptions.reduceCosts} checked={option} setChecked={setOption} />
            <Box pt={"20px"} />
            <Text variant={"body4"} color={"forrestGreen"} textAlign={"center"}>
                (You’ll be able to change this on the next screen.)
            </Text>
            <Box pt={"16px"} />
            <Button backgroundColor={"auratiumGreen"} onClick={() => routerHistory.push("/online-three")}>
                continue
            </Button>
            <Box pb={"100px"} />
        </OnlinePageTemplate>
    );
};

const OptionRow = ({ checked, option, setChecked }) => (
    <FlexRow
        alignItems={"center"}
        backgrounColor={"white"}
        py={"9px"}
        px={"34px"}
        backgroundColor={"white"}
        cursor={"pointer"}
        onClick={() => setChecked(option)}
    >
        <BigCheckbox isChecked={checked.value === option.value} fill={"sienna"} border={"auratiumGreen"} />
        <Box pl={"15px"} />
        <Box>
            <Text variant={"body3"} color={"auratiumGreen"}>
                {option.description}
            </Text>
            <Box pb={"2px"} />
            <Text fontFamily={"montserrat"} fontSize={"14px"} fontWeight={"medium"} color={"sienna"}>
                I'd like to
            </Text>
            <Text fontFamily={"montserrat"} fontSize={"22px"} color={"sienna"} pl={"32px"}>
                {option.label}.
            </Text>
        </Box>
    </FlexRow>
);
