import React, { useEffect, useState } from "react";
import { Box, FlexRow, FlexRowAlignCenter, Grid, Span } from "../styledSystemUtilities";
import { Button, Checkbox, Gradient, Input, ProductCardSelect, Text } from "../Atoms";
import {
    ArrowLeft,
    BigCart,
    BigSubscriptionIcon,
    ComputerIcon,
    CreditCardIcon,
    GrowthIcon,
    PhoneIcon,
    ProfitIcon,
    SendIcon,
} from "../Atoms/assets";
import { formatter } from "../../utils/formatter";
import styled from "styled-components";
import theme from "../../theme";
import _ from "lodash/fp";
import onlineClouds from "../Atoms/assets/onlineClouds.jpg";
import growthClouds from "../Atoms/assets/growthClouds.jpg";
import profitClouds from "../Atoms/assets/profitClouds.jpg";
import { IconButton } from "../Atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { useOnlineEstimates } from "../../hooks/useOnlineEstimates";
import { useGrowthEstimates } from "../../hooks/useGrowthEstimates";
import { routerHistory } from "../../routerHistory";
import { getIsChecked } from "../../utils/getIsChecked";
import { useCallbackRef } from "../../hooks/useCallbackRef";
import { ProfitTotal, SubscriptionTotal } from "../Molecules/SubscriptionTotal";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";
import { paymentOptions } from "../../constants/paymentOptions";
import { productCards } from "../../constants/productCards";
import { slices } from "../../redux";
import useCoupon from "../../hooks/useCoupon";
import coupons from "../../constants/coupons";

export const Cart = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const practiceExists = useSelector((state) => state.practiceExists);
    const profitPaymentOption = useSelector((state) => state.profitPaymentOption);
    const isBestForDentistry = useSelector((state) => state.isBestForDentistry);

    const [checkedProducts, setChecked] = useState(cart);
    const [couponCode, setCouponCode] = useState("");
    const [couponError, setCouponError] = useState("");

    const groupedCart = _.groupBy("type", cart);
    const groupedCheckedProducts = _.groupBy("type", checkedProducts);

    const onlineEstimates = useOnlineEstimates(groupedCheckedProducts.online);
    const growthEstimates = useGrowthEstimates(groupedCheckedProducts.growth);
    const profitTotalCost = _.sumBy("price", groupedCheckedProducts.profit);

    const [coupon] = useCoupon(
        groupedCheckedProducts.online,
        groupedCheckedProducts.profit,
        groupedCheckedProducts.growth
    );

    const [onlineRef, onlinePosition] = useCallbackRef(coupon);
    const [growthRef, growthPosition] = useCallbackRef(coupon);
    const [growthFinishRef, growthFinishPosition] = useCallbackRef(coupon);
    const [profitRef, profitPosition] = useCallbackRef(coupon);
    const [profitFinishRef, profitFinishPosition] = useCallbackRef(coupon);

    useEffect(() => {
        if (onlineEstimates.cost < 1000 && coupon.type === "subscription") {
            dispatch(slices.couponCode.actions.set(""));
        }
    }, [coupon.type, onlineEstimates.cost, dispatch]);

    const handleSubmit = () => {
        dispatch(slices.cart.actions.set(checkedProducts));
        routerHistory.push(practiceExists ? "/location-detected" : "/account");
    };
    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Banner
                Icon={ComputerIcon}
                frontColor={"seaGreen"}
                backColor={"forrestGreen"}
                phaseName={"online"}
                top={`${onlinePosition}px`}
                path={"/online-three"}
            />
            <Banner
                Icon={GrowthIcon}
                frontColor={"ipanemaBlue"}
                backColor={"deepPetrol"}
                phaseName={"growth"}
                top={`${growthPosition}px`}
                path={"/growth-three"}
            />
            <Banner
                Icon={ProfitIcon}
                frontColor={"balticBlue"}
                backColor={"nauticBlue"}
                phaseName={"profit"}
                top={`${profitPosition}px`}
                path={"/profit-two"}
            />
            <Box
                position={"absolute"}
                width={"100vw"}
                backgroundImage={`url(${onlineClouds})`}
                backgroundPosition={"center"}
                top={`${onlinePosition + 39}px`}
                backgroundSize={"cover"}
                zIndex={-1}
                height={`${growthPosition - onlinePosition}px`}
            />
            <Box
                position={"absolute"}
                width={"100vw"}
                display={"flex"}
                alignItems={"flex-end"}
                backgroundImage={`url(${growthClouds})`}
                backgroundPosition={"center"}
                top={`${growthPosition + 39}px`}
                backgroundSize={"cover"}
                zIndex={-1}
                height={`${growthFinishPosition - growthPosition - 39}px`}
            >
                <Box height={"12px"} width={"100%"} backgroundColor={"cellBlue"} />
            </Box>
            <Box
                position={"absolute"}
                width={"100vw"}
                display={"flex"}
                alignItems={"flex-end"}
                backgroundImage={`url(${profitClouds})`}
                backgroundPosition={"center"}
                top={`${profitPosition + 39}px`}
                backgroundSize={"cover"}
                zIndex={-1}
                height={`${profitFinishPosition - profitPosition - 39}px`}
            >
                <Box height={"12px"} width={"100%"} backgroundColor={"mysticBlue"} />
            </Box>
            <Box pb={"18px"} />
            <FlexRow justifyContent={"center"}>
                <FlexRowAlignCenter>
                    <BigCart />
                    <Box pl={"14px"} />
                    <Text variant={"header2"} color={"graphiteGray"}>
                        Your Shopping Cart
                    </Text>
                </FlexRowAlignCenter>
            </FlexRow>
            <Box pb={"20px"} />
            <Grid gridTemplateColumns={"1fr minmax(320px, 525px) 1fr"}>
                <Grid
                    border={"cart"}
                    backgroundColor={"rgba(255, 255, 255, .26)"}
                    borderRadius={"20px"}
                    gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}
                    pt={"9px"}
                    gridColumn={2}
                >
                    <FlexRowAlignCenter gridColumn={"1 / 4"} justifySelf={"center"}>
                        <BigSubscriptionIcon />
                        <Box pl={"9px"} />
                        <Text variant={"body2"} color={"graphiteGray"}>
                            <Span fontWeight={"medium"}>Your Subscriptions </Span>– Cancel Anytime
                        </Text>
                    </FlexRowAlignCenter>

                    <Box pb={"10px"} gridColumn={2} />
                    <Box
                        pb={"40px"}
                        gridColumn={2}
                        ref={(node) => {
                            onlineRef(node);
                        }}
                    />
                    <Box gridColumn={2} backgroundColor={"rgba(255, 255, 255, .5)"} pt={"7px"} px={"16px"}>
                        {groupedCart.online ? (
                            <>
                                <FlexRowAlignCenter justifyContent={"space-between"} pr={"15px"}>
                                    <ContainerLabel color={"seaGreen"}>remove?</ContainerLabel>
                                    <ContainerLabel color={"seaGreen"}>monthly</ContainerLabel>
                                </FlexRowAlignCenter>
                                <Box pb={"4px"} />
                                {groupedCart.online.map((product) => {
                                    return (
                                        <Box key={product.id} pb={"2px"}>
                                            <Product
                                                product={product}
                                                boxBorder={"productGreen"}
                                                checkColor={"auratiumGreen"}
                                                descriptionColor={"seaGreen"}
                                                nameColor={"forrestGreen"}
                                                checkedProducts={checkedProducts}
                                                setChecked={setChecked}
                                            />
                                        </Box>
                                    );
                                })}
                                <Box pb={"8px"} />
                                <CouponTotalRow
                                    color={"forrestGreen"}
                                    text={"Online total due each month:"}
                                    total={onlineEstimates.cost}
                                    coupon={coupon}
                                    type={"online"}
                                    data-testid={"onlineTotal"}
                                />
                                <Box pb={"4px"} />
                                <DetailsRow
                                    text={"Estimated New Patient Range"}
                                    number={onlineEstimates.estimate}
                                    color={"auratiumGreen"}
                                />
                                <Box pb={"7px"} />
                                <DetailsRow
                                    color={"auratiumGreen"}
                                    text={"Estimated Revenue Average"}
                                    number={formatter.format(onlineEstimates.revenue)}
                                />
                                <Box pb={"7px"} />
                                <RoiRow
                                    color={"forrestGreen"}
                                    text={"Estimated ROI Average"}
                                    total={
                                        coupon.type === "online"
                                            ? onlineEstimates.roi + coupon.amount
                                            : coupon.type === "freeTrial"
                                            ? onlineEstimates.roi + coupon.onlineAmount
                                            : onlineEstimates.roi
                                    }
                                    border={"auratiumGreen"}
                                />
                            </>
                        ) : (
                            <NoProductsText color={"forrestGreen"}>
                                There are no online products on this plan.
                            </NoProductsText>
                        )}
                        <Box pb={"15px"} />
                        <Box pb={"46px"} ref={growthRef} />
                        {groupedCart.growth ? (
                            <>
                                <FlexRowAlignCenter justifyContent={"space-between"} pr={"15px"}>
                                    <ContainerLabel color={"ipanemaBlue"}>remove?</ContainerLabel>
                                    <ContainerLabel color={"ipanemaBlue"}>monthly</ContainerLabel>
                                </FlexRowAlignCenter>
                                <Box pb={"4px"} />
                                {groupedCart.growth.map((product) => {
                                    return (
                                        <Box key={product.id} pb={"2px"}>
                                            <Product
                                                nameColor={"deepPetrol"}
                                                descriptionColor={"ipanemaBlue"}
                                                boxBorder={"1px solid #519797"}
                                                checkColor={"ipanemaBlue"}
                                                product={product}
                                                checkedProducts={checkedProducts}
                                                setChecked={setChecked}
                                            />
                                        </Box>
                                    );
                                })}
                                <Box pb={"8px"} />
                                <CouponTotalRow
                                    color={"deepPetrol"}
                                    text={"Growth total due each month:"}
                                    total={growthEstimates.cost}
                                    coupon={coupon}
                                    type={"growth"}
                                    data-testid={"growthTotal"}
                                />
                                <Box pb={"4px"} />
                                <Box data-testid={"growthGoal"}>
                                    <DetailsRow
                                        text={"New Patient Goal"}
                                        number={growthEstimates.estimate}
                                        color={"petrolBlue"}
                                    />
                                </Box>

                                <Box pb={"7px"} />
                                <DetailsRow
                                    color={"petrolBlue"}
                                    text={"Estimated Revenue"}
                                    number={formatter.format(growthEstimates.revenue)}
                                />
                                <Box pb={"7px"} />
                                <RoiRow
                                    color={"deepPetrol"}
                                    text={"Estimated ROI"}
                                    total={
                                        coupon.type === "growth"
                                            ? growthEstimates.roi + coupon.amount
                                            : coupon.type === "freeTrial"
                                            ? growthEstimates.roi + coupon.growthAmount
                                            : growthEstimates.roi
                                    }
                                    border={"petrolBlue"}
                                />
                            </>
                        ) : (
                            <NoProductsText color={"deepPetrol"}>
                                There are no growth products on this plan.
                            </NoProductsText>
                        )}
                        <Box pb={"35px"} />
                        <Box pb={"18px"} ref={growthFinishRef} />
                        <SubscriptionTotal
                            growthCost={growthEstimates.cost}
                            onlineCost={onlineEstimates.cost}
                            groupedCart={groupedCheckedProducts}
                            coupon={coupon}
                        />
                        <Box pb={"20px"} />
                    </Box>
                </Grid>
                <Box pb={"20px"} gridColumn={2} />
                <Grid
                    border={"cart"}
                    backgroundColor={"rgba(255, 255, 255, .26)"}
                    borderRadius={"20px"}
                    gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}
                    pt={"9px"}
                    gridColumn={2}
                >
                    <Text variant={"body2"} color={"graphiteGray"} gridColumn={"1/4"} justifySelf={"center"}>
                        <Span fontWeight={"bold"}>Product Purchases </Span>— Must Be Paid In Full
                    </Text>
                    <Box pb={"10px"} gridColumn={2} />
                    <Box pb={"40px"} gridColumn={2} ref={profitRef} />
                    <Box gridColumn={2} backgroundColor={"rgba(255, 255, 255, .5)"} pt={"7px"} px={"16px"}>
                        {groupedCart.profit ? (
                            <>
                                <FlexRowAlignCenter justifyContent={"space-between"} pr={"15px"}>
                                    <ContainerLabel color={"balticBlue"}>remove?</ContainerLabel>
                                    <ContainerLabel color={"balticBlue"}>price</ContainerLabel>
                                </FlexRowAlignCenter>
                                <Box pb={"4px"} />
                                {groupedCart.profit.map((product) => {
                                    const CheckoutCard = productCards[product.checkoutCard];
                                    return (
                                        <Box key={product.id} pb={"2px"}>
                                            <CheckoutCard
                                                product={product}
                                                checkedProducts={checkedProducts}
                                                setChecked={setChecked}
                                            />
                                        </Box>
                                    );
                                })}
                                <Box pb={"8px"} />
                                <TotalRow
                                    total={profitTotalCost}
                                    text={"Profit Total"}
                                    color={"nauticBlue"}
                                    data-testid={"profitTotal"}
                                />
                                <Box pb={"11px"} />
                                <Box pl={"10px"} pr={"15px"} data-testid={"paymentPlan"}>
                                    <Text color={"nauticBlue"} variant={"body3"} fontWeight={"bold"}>
                                        Payment Options
                                    </Text>
                                    <Box pb={"4px"} />
                                    <Text color={"balticBlue"} variant={"body4"}>
                                        Choose a payment plan:
                                    </Text>
                                    <Box pb={"6px"} />
                                    <FlexRowAlignCenter justifyContent={"space-between"}>
                                        <Box width={"192px"} data-testid={"paymentOptionSelect"}>
                                            <ProductCardSelect
                                                onChange={(option) =>
                                                    dispatch(slices.profitPaymentOption.actions.set(option))
                                                }
                                                defaultValue={profitPaymentOption}
                                                options={paymentOptions}
                                            />
                                        </Box>
                                        <Text variant={"body3"} fontWeight={"bold"}>
                                            {formatter.format(
                                                _.round(
                                                    (profitTotalCost / profitPaymentOption.value) *
                                                        profitPaymentOption.fee
                                                )
                                            )}
                                            {profitPaymentOption.value > 1 && "/mo."}
                                        </Text>
                                    </FlexRowAlignCenter>
                                    <Box pb={"6px"} />
                                    <Text variant={"label1"} color={"balticBlue"} height={"11px"}>
                                        {profitPaymentOption.disclaimer}
                                    </Text>
                                </Box>
                            </>
                        ) : (
                            <NoProductsText color={"nauticBlue"}>
                                There are no profit products on this plan.
                            </NoProductsText>
                        )}

                        <Box pb={"33px"} />
                        <Box pb={"13px"} ref={profitFinishRef} />
                        <ProfitTotal
                            profitPaymentOption={profitPaymentOption}
                            groupedCart={groupedCart}
                            profitTotalCost={profitTotalCost}
                            coupon={coupon}
                        />
                        <Box pb={"19px"} />
                    </Box>
                </Grid>
                <Box pb={"32px"} gridColumn={2} />
                <FlexRowAlignCenter
                    gridColumn={2}
                    justifyContent={"space-between"}
                    width={"100%"}
                    maxWidth={"375px"}
                    justifySelf={"center"}
                >
                    <Input
                        width={"48%"}
                        placeholder={"Enter Coupon Code"}
                        value={couponCode}
                        onChange={(event) => setCouponCode(event.target.value)}
                    />
                    <Button
                        minWidth={"48%"}
                        backgroundColor={"agateGray"}
                        onClick={() => {
                            setCouponCode("");
                            if (
                                _.get("type", coupons[_.toLower(couponCode)]) === "subscription" &&
                                onlineEstimates.cost < 1000
                            ) {
                                dispatch(slices.couponCode.actions.set(""));
                                setCouponError("$1,000 online spend minimum to qualify for this code.");
                            } else if (coupons[_.toLower(couponCode)] || _.toLower(couponCode) === "freetrial") {
                                dispatch(slices.couponCode.actions.set(_.toLower(couponCode)));
                                setCouponError("");
                            } else {
                                dispatch(slices.couponCode.actions.set(""));
                                setCouponError("Invalid Code!");
                            }
                        }}
                    >
                        submit code
                    </Button>
                </FlexRowAlignCenter>
                <FlexRowAlignCenter
                    maxWidth={"375px"}
                    gridColumn={2}
                    justifySelf={"center"}
                    justifyContent={"center"}
                    py={6}
                >
                    {coupon.message ? (
                        <Text variant={"body3"} color={"amaranthRed"} textAlign={"center"}>
                            {coupon.message}
                        </Text>
                    ) : (
                        <Text variant={"body3"} color={"amaranthRed"} textAlign={"center"}>
                            {couponError}
                        </Text>
                    )}
                </FlexRowAlignCenter>
                <Box gridColumn={"2"} width={"100%"} maxWidth={"375px"} justifySelf={"center"}>
                    <Box pb={"8px"} />
                    <ButtonHeader>Purchase this plan:</ButtonHeader>
                    <IconButton Icon={CreditCardIcon} backgroundColor={"graphiteGray"} onClick={handleSubmit}>
                        checkout with credit card
                    </IconButton>
                    <Box pb={"27px"} />
                    <ButtonHeader>Discuss this plan:</ButtonHeader>
                    <IconButton
                        Icon={PhoneIcon}
                        backgroundColor={"agateGray"}
                        onClick={() => {
                            window.open(
                                isBestForDentistry
                                    ? "https://calendly.com/mike-sieber/consultation"
                                    : "https://calendly.com/mike-s-5/ascent-marketing-consultation",
                                "_blank"
                            );
                        }}
                    >
                        talk to a marketing specialist about this plan
                    </IconButton>
                    <Box pb={"27px"} />
                    <ButtonHeader>Save this plan:</ButtonHeader>
                    <IconButton
                        Icon={SendIcon}
                        backgroundColor={"agateGray"}
                        onClick={() => routerHistory.push("/email-link")}
                    >
                        email yourself a link to this plan
                    </IconButton>
                </Box>
                <Box pb={"100px"} gridColumn={"2"} />
            </Grid>
        </Box>
    );
};

const ButtonHeader = ({ children }) => (
    <FlexRowAlignCenter justifyContent={"center"} pb={"6px"}>
        <Text fontFamily={"montserrat"} fontSize={"20px"} color={"graphiteGray"}>
            {children}
        </Text>
    </FlexRowAlignCenter>
);

const ContainerLabel = ({ children, color }) => (
    <Text color={color} fontWeight={"medium"} variant={"label3"} uppercase>
        {children}
    </Text>
);

const Product = ({ product, checkColor, nameColor, descriptionColor, boxBorder, checkedProducts, setChecked }) => {
    const isChecked = getIsChecked(product, checkedProducts);

    return (
        <FlexRowAlignCenter
            py={"4px"}
            pl={"10px"}
            pr={"15px"}
            justifyContent={"space-between"}
            backgroundColor={"white"}
            data-testid={product.recurlyCode}
        >
            <FlexRowAlignCenter>
                <Checkbox
                    fill={checkColor}
                    border={boxBorder}
                    isChecked={isChecked}
                    onClick={() =>
                        isChecked
                            ? setChecked(_.reject((checkedProduct) => checkedProduct.id === product.id))
                            : setChecked((prevState) => prevState.concat(product))
                    }
                />
                <Box pl={"20px"} />
                <Box>
                    <Text color={nameColor} variant={"body3"} opacity={isChecked ? 1 : 0.31}>
                        {product.name}
                    </Text>
                    <Text variant={"label1"} color={descriptionColor} opacity={isChecked ? 1 : 0.27}>
                        {product.description}
                    </Text>
                </Box>
            </FlexRowAlignCenter>
            {isChecked ? (
                <Text variant={"body3"} color={"agateGray"}>
                    {formatter.format(_.round(product.monthlyPrice))}
                </Text>
            ) : null}
        </FlexRowAlignCenter>
    );
};

const CouponTotalRow = ({ text, total, color, type, coupon, ...props }) => {
    const discount = coupon.type === "freeTrial" ? coupon[type + "Amount"] : coupon.amount;

    return (
        <Box {...props}>
            <TotalRow text={text} total={total} color={color} />
            {_.get("type", coupon) === type ||
            _.get("type", coupon) === "freeTrial" ||
            ((_.get("type", coupon) === "best" || _.get("type", coupon) === "hpa") && type !== "growth") ? (
                <>
                    <FlexRowAlignCenter
                        justifyContent={"space-between"}
                        pl={"10px"}
                        height={"16px"}
                        pr={"15px"}
                        backgroundColor={"white"}
                    >
                        <Text color={coupon.color} fontSize={"12px"}>
                            {coupon.duration === 1
                                ? "Discount (First month)"
                                : coupon.duration === "forever"
                                ? "Discount"
                                : `Discount (First ${coupon.duration} months)`}
                        </Text>
                        <Text fontSize={"14px"} color={"amaranthRed"} fontWeight={"bold"}>
                            - {formatter.format(_.round(discount))}
                        </Text>
                    </FlexRowAlignCenter>
                    <TotalRow
                        color={color}
                        total={total - discount}
                        text={
                            coupon.duration === 1
                                ? "Total due first month:"
                                : coupon.duration === "forever"
                                ? "Total due:"
                                : `Total due first ${coupon.duration} months:`
                        }
                    />
                </>
            ) : null}
        </Box>
    );
};

const TotalRow = ({ text, total, color, ...props }) => (
    <FlexRowAlignCenter
        justifyContent={"space-between"}
        pl={"10px"}
        height={"32px"}
        pr={"15px"}
        backgroundColor={"white"}
        {...props}
    >
        <Text variant={"body3"} fontWeight={"bold"} color={color}>
            {text}
        </Text>
        <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
            {formatter.format(_.round(total))}
        </Text>
    </FlexRowAlignCenter>
);

const DetailsRow = ({ text, number, color }) => (
    <FlexRowAlignCenter justifyContent={"space-between"} pl={"10px"} pr={"15px"}>
        <Text variant={"body4"} color={color}>
            {text}
        </Text>
        <Text variant={"body3"}>{number}</Text>
    </FlexRowAlignCenter>
);

const RoiRow = ({ text, total, color, border }) => (
    <FlexRowAlignCenter justifyContent={"space-between"} pl={"10px"} height={"29px"} pr={"15px"} border={border}>
        <Text variant={"body3"} fontWeight={"bold"} color={color}>
            {text}
        </Text>
        <Text variant={"body3"}>{formatter.format(total)}</Text>
    </FlexRowAlignCenter>
);

export const Banner = ({ frontColor, backColor, Icon, phaseName, top, path }) => {
    const dispatch = useDispatch();
    return (
        <FlexRow position={"absolute"} width={"100vw"} top={top}>
            <LeftBanner frontColor={frontColor} backColor={backColor} />
            <Box position={"relative"} width={"375px"} height={"40px"}>
                <PhaseBox
                    backgroundColor={frontColor}
                    position={"absolute"}
                    zIndex={3}
                    onClick={() => {
                        dispatch(slices.couponCode.actions.set(""));
                        routerHistory.push(path);
                    }}
                >
                    <ArrowLeft fill={"white"} />
                    <Box pl={"7px"} />
                    <Text color={"white"} variant={"label1"} fontWeight={"bold"} uppercase>
                        {phaseName}
                    </Text>
                    <Box pl={"11px"} />
                    <Icon fill={"white"} />
                </PhaseBox>
                <Box backgroundColor={frontColor} height={"9px"} width={"100%"} position={"absolute"} zIndex={2} />
                <Box backgroundColor={backColor} height={"39px"} width={"100%"} position={"absolute"} zIndex={1} />
            </Box>
            <RightBanner frontColor={frontColor} backColor={backColor} />
        </FlexRow>
    );
};

const LeftBanner = ({ frontColor, backColor }) => {
    return (
        <Box position={"relative"} height={"39px"} display={"flex"} justifyContent={"flex-end"} flexGrow={1}>
            <Box
                position={"absolute"}
                height={"9px"}
                width={"100%"}
                zIndex={2}
                mt={"21px"}
                backgroundColor={backColor}
            />
            <Box
                position={"absolute"}
                height={"9px"}
                width={"100%"}
                zIndex={2}
                mt={"30px"}
                backgroundColor={frontColor}
            />
            <Box
                position={"absolute"}
                height={"100%"}
                width={"20px"}
                background={`linear-gradient(110deg, transparent 37%, ${theme.colors[frontColor]} 37% 100%)}`}
                zIndex={3}
            />
        </Box>
    );
};

const RightBanner = ({ frontColor, backColor }) => {
    return (
        <Box flexGrow={1} height={"40px"}>
            <Box height={"9px"} backgroundColor={frontColor} />
            <Box height={"30px"} backgroundColor={backColor} />
        </Box>
    );
};

const NoProductsText = ({ color, children }) => (
    <Text variant={"body3"} fontWeight={"bold"} color={color} pt={"10px"} pl={"10px"}>
        {children}
    </Text>
);

export const PhaseBox = styled(Box)`
    height: 39px;
    width: 131px;
    background: ${(props) =>
        `linear-gradient(110deg, ${props.theme.colors[props.backgroundColor]} 91%, transparent 91% 100%)}`};
    display: flex;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
`;
