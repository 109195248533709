import React from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { Gradient, Text } from "../Atoms";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";
import { IconButton } from "../Atoms/Button";
import { CartIcon } from "../Atoms/assets";
import { routerHistory } from "../../routerHistory";

export const SentLink = () => {
    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"70px"} />
                    <Text>A link to your plan has been sent to your email!</Text>
                    <Box pb={"20px"} />
                    <IconButton
                        Icon={CartIcon}
                        backgroundColor={"graphiteGray"}
                        onClick={() => routerHistory.push("/cart")}
                    >
                        back to cart
                    </IconButton>
                </Box>
            </Grid>
        </Box>
    );
};
