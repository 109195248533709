import React from "react";
import { Box } from "../styledSystemUtilities";
import { Text } from "./Text";

export const SeoBoxLabel = ({ fill, children }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box width={"30px"} height={"18px"} backgroundColor={fill} border={"gray"} />
            <Box mb={3} />
            <Text variant={"label2"} fontWeight={"black"}>
                {children}
            </Text>
        </Box>
    );
};

export const ReviewsBoxLabel = ({ fill, opacity, children }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box width={"24px"} height={"21px"} backgroundColor={fill} opacity={opacity} border={"gray"} />
            <Box mb={3} />
            <Text variant={"label2"} fontWeight={"black"}>
                {children}
            </Text>
        </Box>
    );
};

export const ReviewsDoubleBoxLabel = ({ children }) => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box display={"flex"}>
                <Box width={"24px"} height={"21px"} backgroundColor={"slateGray"} opacity={".3"} border={"gray"} />
                <Box ml={2} />
                <Box width={"24px"} height={"21px"} backgroundColor={"seaGreen"} opacity={".17"} border={"gray"} />
            </Box>
            <Box mb={3} />
            <Text variant={"label2"} fontWeight={"black"}>
                {children}
            </Text>
        </Box>
    );
};
