import { useSelector } from "react-redux";
import coupons from "../constants/coupons";
import _ from "lodash/fp";
import { useCallback, useEffect, useState } from "react";
import { subscriptionProductsExcludedFromFreeTrial } from "../constants/subscriptionProductsExcludedFromFreeTrial";
import { ppcAverages } from "../constants/ppcAverages";

const useCoupon = (onlineProducts, profitProducts, growthProducts) => {
    const couponCode = useSelector((state) => state.couponCode);
    const [coupon, setCoupon] = useState({});

    const seoPrice = _.sumBy(
        "monthlyPrice",
        _.filter((product) => product.recurlyCode === "seoe-01" || product.recurlyCode === "seoe-02", onlineProducts)
    );
    const seoDiscount = _.round(seoPrice * coupons.seoreduce6.percentOff);
    const onlineItemsWithoutReviewGeneration = _.filter((product) => product.recurlyCode !== "revg-01", onlineProducts);
    const onlineItemsWithDiscounts = onlineItemsWithoutReviewGeneration.map(
        (product) => product.monthlyPrice * coupons.best.percentOff
    );
    const onlineDiscount = _.sum(onlineItemsWithDiscounts);

    const freeTrialOnlineDiscount =
        onlineProducts?.reduce(
            (sum, product) =>
                sum +
                (!subscriptionProductsExcludedFromFreeTrial.includes(product.recurlyCode) ? product.monthlyPrice : 0),
            0
        ) ?? 0;
    const freeTrialGrowthDiscount =
        growthProducts?.reduce(
            (sum, product) =>
                sum + (product.recurlyCode === "gooa-01" ? ppcAverages.managementFee + ppcAverages.callTracking : 0),
            0
        ) ?? 0;

    const profitCodesNotToInclude = new Set([
        "trea-01",
        "trea-02",
        "tanc-01",
        "tanc-02",
        "tanc-03",
        "tcas-01",
        "tcas-02",
        "tcas-03",
        "tref-01",
        "tref-02",
        "tpho-01",
        "tpho-02",
    ]);
    const filteredProfitItems = _.filter(
        (product) => !profitCodesNotToInclude.has(product.recurlyCode),
        profitProducts
    );
    const profitItemsDiscounts = filteredProfitItems.map((product) => product.price * coupons.best.percentOff);
    const profitDiscount = _.sum(profitItemsDiscounts);

    const changeCoupon = useCallback(() => {
        const couponToBe = coupons[couponCode] || {};
        if (couponCode === "freetrial") {
            setCoupon({
                type: "freeTrial",
                duration: 3,
                message:
                    "First 3 months of subscription products free except for Google Ad Spend and My Social Practice products.",
                amount: freeTrialGrowthDiscount + freeTrialOnlineDiscount,
                onlineAmount: freeTrialOnlineDiscount,
                growthAmount: freeTrialGrowthDiscount,
            });
        } else if (couponCode) {
            if (couponCode === "best" || couponCode === "hpafive") {
                setCoupon({
                    ...couponToBe,
                    amount: _.round(onlineDiscount),
                    profitAmount: _.round(profitDiscount),
                });
            } else {
                couponToBe.percentOff
                    ? setCoupon({
                          ...couponToBe,
                          amount: seoDiscount,
                      })
                    : setCoupon(couponToBe);
            }
        } else {
            setCoupon({});
        }
    }, [couponCode, freeTrialGrowthDiscount, freeTrialOnlineDiscount, onlineDiscount, profitDiscount, seoDiscount]);

    useEffect(() => {
        changeCoupon();
    }, [changeCoupon]);

    return [coupon, couponCode];
};

export default useCoupon;
