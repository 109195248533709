import React, { useEffect } from "react";
import { Gradient, Text } from "../Atoms";
import { Box } from "../styledSystemUtilities";
import { useTimeout } from "react-use";
import { AdsLogo, BigCheck, GoogleLogo, LocalLogo, ReviewsLogo } from "../Atoms/assets";
import { routerHistory } from "../../routerHistory";

export const ReportLoading = ({ isLoading }) => {
    const [renderCheck1] = useTimeout(1000);
    const [renderLocal] = useTimeout(2000);
    const [renderCheck2] = useTimeout(3000);
    const [renderReviews] = useTimeout(4000);
    const [renderCheck3] = useTimeout(5000);
    const [renderAds] = useTimeout(6000);
    const [renderCheck4] = useTimeout(7000);
    const [renderFinalText] = useTimeout(8000);
    const [renderPage] = useTimeout(9000);
    const shouldRender = renderPage();

    useEffect(() => {
        if (!isLoading && shouldRender) {
            routerHistory.push("/description");
        }
    }, [isLoading, shouldRender]);

    return (
        <>
            <Gradient />
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Box>
                    <Box pb={"105px"} />
                    <Text variant={"header2"} color={"graphiteGray"}>
                        Gathering Data...
                    </Text>
                    <Box pb={10} />
                    <Text variant={"body3"}>Compiling your area competition information</Text>
                    <Box pb={7} />
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <GoogleLogo data-testid={"google"} />
                        {renderCheck1() ? <BigCheck data-testid={"bigCheck1"} fill={"sienna"} /> : null}
                    </Box>
                    <Box pb={10} />
                    {renderLocal() ? <Text variant={"body3"}>Running local keyword searches</Text> : null}
                    <Box pb={7} />
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        {renderLocal() ? <LocalLogo data-testid={"local"} /> : null}
                        {renderCheck2() ? <BigCheck data-testid={"bigCheck2"} fill={"sienna"} /> : null}
                    </Box>
                    <Box pb={10} />
                    {renderReviews() ? <Text variant={"body3"}>Collecting review information</Text> : null}
                    <Box pb={7} />
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        {renderReviews() ? <ReviewsLogo data-testid={"reviews"} /> : null}
                        {renderCheck3() ? <BigCheck data-testid={"bigCheck3"} fill={"sienna"} /> : null}
                    </Box>
                    <Box pb={10} />
                    {renderAds() ? <Text variant={"body3"}>Estimating your area’s price per click</Text> : null}
                    <Box pb={7} />
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        {renderAds() ? <AdsLogo data-testid={"ads"} /> : null}
                        {renderCheck4() ? <BigCheck data-testid={"bigCheck4"} fill={"sienna"} /> : null}
                    </Box>
                    <Box pb={13} />
                    {renderFinalText() ? (
                        <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
                            Customizing plan and recommendations...
                        </Text>
                    ) : null}
                </Box>
            </Box>
        </>
    );
};
