import React from "react";
import { Box, Span } from "../../styledSystemUtilities";
import {
    Handshake,
    ConversationBubbles,
    ComputerWithRing,
    DesktopComputer,
    PuzzlePieces,
    DentalMenuPremiumHero,
} from "../../Atoms/assets";
import { Text } from "../../Atoms";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { ProfitHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const PremiumDentalMenu = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <ProfitHeader setPopup={setPopup} Hero={DentalMenuPremiumHero} chalkHeight={"60px"} />
            <GrayBackground>
                <SellingPoint
                    header={"An automated, flexible, in-house membership plan that’s proven to increase retention."}
                    body={
                        "88% of new patients without dental insurance don’t come back! Don’t let your uninsured patients flow through your practice! Leverage our expertise and software to create a unique solution for you uninsured patients.\n\nEasily create a ‘menu’ of services for your patients, empowering them to select and build a plan that suits them. What does that mean? A simple path to more patients on your plans! Finally a solid alternative to dental insurance for the uninsured."
                    }
                />
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={ConversationBubbles}
                    body={"Ensure your plans are set up correctly."}
                    header={"Plan Consultation"}
                />
                <IconBulletPoint
                    Icon={ComputerWithRing}
                    body={"Help your front office team be proficient."}
                    header={"Software Training"}
                />
                <IconBulletPoint
                    Icon={DesktopComputer}
                    body={"Streamline your enrollment process."}
                    header={"Branded Landing Page & Online Setup"}
                />
                <Box display={"flex"} pt={5} pb={10}>
                    <Box width={"36px"} display={"flex"} justifyContent={"center"}>
                        <PuzzlePieces />
                    </Box>

                    <Box ml={"13px"} />
                    <Box>
                        <Text variant={"body2"} fontWeight={"bold"}>
                            Dental Menu Software Solutions
                        </Text>
                        <Text variant={"body3"} pt={3} pb={7} ml={7}>
                            • <Span fontWeight={"bold"}>Member Management</Span> - All pertinent membership info at your
                            fingertips
                        </Text>
                        <Text variant={"body3"} pb={7} ml={7}>
                            • <Span fontWeight={"bold"}>Automated Payments</Span> - Automatically collect membership
                            payments
                        </Text>
                        <Text variant={"body3"} pb={7} ml={7}>
                            • <Span fontWeight={"bold"}>Churn Management</Span> - Automatically follow up with
                            delinquent payments
                        </Text>
                        <Text variant={"body3"} pb={7} ml={7}>
                            • <Span fontWeight={"bold"}>Patient Rewards Platform</Span> - Incentivize timely preventive
                            visits to increase retention and treatment acceptance
                        </Text>
                        <Text variant={"body3"} pb={7} ml={7}>
                            • <Span fontWeight={"bold"}>Benefit Usage and Eligibility</Span> - Keep track of patients
                            benefits and eligibility
                        </Text>
                        <Text variant={"body3"} pb={7} ml={7}>
                            • <Span fontWeight={"bold"}>Ledger Reconciliation</Span> - Keep your ledger accurate
                        </Text>
                        <Text variant={"body3"} pb={7} ml={7}>
                            • <Span fontWeight={"bold"}>Provider Compensation</Span> - Ensure providers are receiving
                            credit for services rendered
                        </Text>
                        <Text variant={"body3"} pb={7} ml={7}>
                            • <Span fontWeight={"bold"}>Plan Usage and Profitability</Span> - Accurate reporting on plan
                            profitability
                        </Text>
                        <Text variant={"body3"} ml={7}>
                            • <Span fontWeight={"bold"}>Practice Management Integration</Span> - Save time and human
                            error profitability
                        </Text>
                    </Box>
                </Box>
                <IconBulletPoint Icon={Handshake} body={"Always here for peace of mind."} header={"Friendly Support"} />
                <IconContainer name={"Payment Information"} />
                <Text variant={"body3"} ml={8} pb={10}>
                    Your initial payment of $299 will be charged through the insight app. All future monthly payments
                    and a $2 per member fee will be charged directly through Dental Menu.
                </Text>
            </GrayBackground>
            <WhiteBackground>
                <Text variant={"body2"} color={"balticBlue"} lineHeight={"21px"}>
                    “We have been using Dental Menu for a long time. It has generated so many new patients over the
                    years. Their membership solution has been great for my front office staff. If you are in need of
                    starting or improving a membership program, or need new patients, I would highly recommend them.”
                </Text>
                <Text variant={"body3"} fontWeight={"bold"} textAlign={"end"}>
                    Brent S. DDS
                </Text>
            </WhiteBackground>
            <GrayBackground>
                <Box pb={9} />
                <SellingPoint
                    header={"About Dental Menu"}
                    body={
                        "Dental Menu is a complete platform that allows dental providers to offer subscription plans directly to their patients. They help offices create a unique menu of services that allows patients to customize their membership plan. This increases the number of patients that enroll in your plans, the length of time they are members, and the amount of revenue they spend on service each month."
                    }
                />
                <Box pb={6} />
            </GrayBackground>
        </Box>
    );
};
