import { useSelector } from "react-redux";
import { BestForDentistryWhiteLogo, HPALogo, Logo } from "./assets";
import React from "react";

export const MenuLogo = () => {
    const isBestForDentistry = useSelector((state) => state.isBestForDentistry);
    const isHpa = useSelector((state) => state.isHpa);

    return isBestForDentistry ? <BestForDentistryWhiteLogo /> : isHpa ? <HPALogo /> : <Logo width={"158px"} />;
};
