import React from "react";
import { Box } from "../styledSystemUtilities";
import { Dot, Text } from "../Atoms";

export const DotLabel = ({ fill, children }) => {
    return (
        <Box display={"flex"} alignItems={"center"}>
            <Dot fill={fill} />
            <Box ml={3} />
            <Text variant={"label2"}>{children}</Text>
        </Box>
    );
};
