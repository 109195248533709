import React from "react";
import { Box } from "../../styledSystemUtilities";
import { BluePhone, Pdf } from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { BurkhartHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const CaseAcceptanceBurkhart = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <BurkhartHeader setPopup={setPopup} name={"Case Acceptance"} tagline={"Sell value, increase profits."} />
            <GrayBackground>
                <SellingPoint
                    header={"Increase revenue and improve profitability"}
                    body={
                        "If you’re not closing as many treatment plans as you’d like or maybe just not as much of the plan as you’d like, learning to be more effective in your case presentation could have a major impact on your bottom line. Let us help you engage your patients and help you sell the value of the plan."
                    }
                />
            </GrayBackground>
            <WhiteBackground>
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={BluePhone}
                    header={"30 Minute Coaching Call"}
                    body={"Key to our customized approach"}
                />
                <IconBulletPoint
                    Icon={Pdf}
                    header={"PDF Scripting and Resources"}
                    body={"Supports team implementation"}
                />
            </WhiteBackground>
        </Box>
    );
};
