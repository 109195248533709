import { api } from "../utilityActions";

const sendSessionLinkEmail = (input, sessionLink) =>
    api({
        call: {
            url: "/sendSessionLinkEmail",
            method: "post",
            data: {
                input,
                sessionLink,
            },
        },
    });

export default sendSessionLinkEmail;
