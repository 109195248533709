function createTaskObject(task, productName) {
    return {
        taskKey: task.key,
        completed: false,
        parentProduct: productName,
        taskName: task.name,
    };
}

export default createTaskObject;
