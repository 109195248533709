import React from "react";
import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { Text } from "./Text";

export const MiniNumberCircle = ({ isDark, number }) => {
    return (
        <FlexRowAlignCenter
            justifyContent={"center"}
            width={"22px"}
            height={"22px"}
            borderRadius={"50%"}
            border={isDark ? "graphiteGray" : "mid"}
        >
            <Text variant={"body3"} fontWeight={"bold"} color={isDark ? "graphiteGray" : "midGray"}>
                {number}
            </Text>
        </FlexRowAlignCenter>
    );
};
