import { Box } from "../../styledSystemUtilities";
import { X } from "../../Atoms/assets";
import { Text } from "../../Atoms";
import React from "react";
import { RoiCalculator } from "../../Molecules/PopupComponents";
import { useWindowSize } from "react-use";

export function GrowthRoiExplanation({ setPopup }) {
    const { width } = useWindowSize();

    return (
        <>
            <Box
                backgroundColor={"petrolBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box
                    onClick={() => setPopup((prevState) => null)}
                    cursor={"pointer"}
                    display={"flex"}
                    alignItems={"center"}
                    data-testid={"close"}
                >
                    <X />
                    <Box ml={5} />
                    <Text variant={"body3"} color={"white"}>
                        Close
                    </Text>
                </Box>
            </Box>
            <Box pb={"31px"} />
            <Box
                backgroundColor={"zigguratBlue"}
                height={"91px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                px={"21px"}
            >
                <Text variant={"header2"} color={"deepPetrol"}>
                    How do we estimate your ROI average?
                </Text>
            </Box>
            <Box backgroundColor={"chalk"} px={"21px"} py={"20px"}>
                <Text variant={"body3"}>1. We use the number of patients chosen with the growth campaign slider.</Text>
                <Box pb={6} />
                <Text variant={"body3"}>
                    2. We multiply the number of new patients by your per-patient value. (If you’d like to calculate
                    your own per-patient value to improve the accuracy of our ROI predictions, use the calculator
                    below.)
                </Text>
                <Box pb={6} />
                <Text variant={"body3"}>
                    3. We subtract the growth total from the products selected under the slider.
                </Text>
                <Box pb={3} />
                <Box pl={"19px"}>
                    <Text variant={"label2"} lineHeight={"14px"}>
                        Pay per click ad spend costs are based on the estimated per-click pricing from the competitive
                        area analysis we’ve just performed for your practice. Per-click pricing, combined with our
                        client’s average conversion rates, allow us to calculate the ad spend budget required to reach
                        new patient goals.
                    </Text>
                    <Box pb={3} />
                    <Text variant={"label2"} lineHeight={"14px"}>
                        Direct mail fulfillment costs are based on pricing associated with the printing, mailing, and
                        strategy of the campaign. Our client’s average conversion rates allow us to estimate conversion
                        costs per mailer.
                    </Text>
                    <Box pb={3} />
                    <Text variant={"label2"} lineHeight={"14px"}>
                        The total includes all ad spend or fulfillment costs, and our management fee. There are no
                        additional or hidden costs.
                    </Text>
                </Box>
                <Box pb={6} />
                <Text variant={"body3"} fontWeight={"bold"}>
                    The result is your estimated ROI Average
                </Text>
            </Box>
            <RoiCalculator
                buttonColor={"ipanemaBlue"}
                textColor={"deepPetrol"}
                backgroundColor={"zigguratBlue"}
                setPopup={setPopup}
            />
        </>
    );
}
