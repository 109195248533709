import React, { useEffect, useState } from "react";
import { ProfitPageTemplate } from "./PageTemplate";
import { ProfitHeader, ProfitSubHeader } from "../Atoms/Headers";
import { Box, FlexRow, FlexRowAlignCenter, Span } from "../styledSystemUtilities";
import { CircleHeader } from "../Molecules/CircleHeader";
import _ from "lodash/fp";
import { Text, Checkbox } from "../Atoms";
import { profitOptions as ascentProfitOptions } from "../../constants/profitOptions";
import { ArrowDown, ArrowRight } from "../Atoms/assets";
import { ShoppingCartButton, SmallButton } from "../Atoms/Button";
import { routerHistory } from "../../routerHistory";
import { useProfitOptions } from "../../hooks/useProfitOptions";
import { useSelector } from "react-redux";
import { productCards } from "../../constants/productCards";
import { burkhartProfitOptions } from "../../constants/burkhartProfitOptions";
import { hpaProfitOptions } from "../../constants/hpaProfitOptions";

export const ProfitTwo = () => {
    const [checked, setChecked] = useProfitOptions();
    const report = useSelector((state) => state.report);
    const isBurkhart = useSelector((state) => state.isBurkhart);
    const isHpa = useSelector((state) => state.isHpa);

    const profitOptions = isBurkhart ? burkhartProfitOptions : isHpa ? hpaProfitOptions : ascentProfitOptions;

    const orderedProfitOptions = _.flow(
        _.groupBy((option) => checked[option.option]),
        (groupedOptions) => [..._.getOr([], "true", groupedOptions), ..._.getOr([], "false", groupedOptions)]
    )(profitOptions);

    return (
        <ProfitPageTemplate>
            <Box pb={"24px"} />
            <ProfitHeader textAlign={"center"}>Profitability Solutions</ProfitHeader>
            <ProfitSubHeader>{_.get("user.practiceName", report)}</ProfitSubHeader>
            <Box pb={"17px"} />
            <FlexRow
                justifyContent={"center"}
                flexWrap={"wrap"}
                backgroundColor={"rgba(255, 255, 255, .4)"}
                pt={"8px"}
                data-testid={"optionsRow"}
            >
                {profitOptions.map(({ option, shortLabel }, index) => {
                    return (
                        <Box key={index} pr={"8px"} pb={"7px"}>
                            <ListItem
                                shortLabel={shortLabel}
                                option={option}
                                setChecked={setChecked}
                                checked={checked}
                            />
                        </Box>
                    );
                })}
            </FlexRow>
            <Box pb={"19px"} />
            <CircleHeader
                color={"nauticBlue"}
                number={2}
                text={"Select from our list of proven profitability solutions."}
            />
            <Box pb={"27px"} />
            <DropdownContainer checked={checked} profitOptions={orderedProfitOptions} />
            <Box pb={"20px"} />
            <FlexRow justifyContent={"space-between"}>
                <SmallButton backgroundColor={"auratiumGreen"} onClick={() => routerHistory.push("/online-one")}>
                    to online
                </SmallButton>
                <SmallButton backgroundColor={"ipanemaBlue"} onClick={() => routerHistory.push("/growth-one")}>
                    to growth
                </SmallButton>
            </FlexRow>
            <Box pb={"10px"} />
            <FlexRow justifyContent={"center"}>
                <ShoppingCartButton backgroundColor={"balticBlue"} onClick={() => routerHistory.push("/cart")} />
            </FlexRow>
            <Box pb={"100px"} />
        </ProfitPageTemplate>
    );
};

const DropdownContainer = ({ profitOptions, checked }) => {
    return profitOptions.map(({ label, subtitle, products, option, printOptions }, index) => {
        return (
            <Box pb={"20px"} key={index}>
                <Dropdown title={label} subtitle={subtitle} option={option} checked={checked}>
                    {products.map((product) => {
                        const ProductCard = productCards[product.productCard];
                        return (
                            <Box key={product.id} pb={"10px"}>
                                <ProductCard product={product} />
                            </Box>
                        );
                    })}
                    {printOptions ? (
                        <SubDropdown>
                            {printOptions.map((product) => {
                                const ProductCard = productCards[product.productCard];
                                return (
                                    <Box key={product.id} pb={"10px"}>
                                        <ProductCard product={product} />
                                    </Box>
                                );
                            })}
                        </SubDropdown>
                    ) : null}
                </Dropdown>
            </Box>
        );
    });
};

const Dropdown = ({ title, children, subtitle, option, checked }) => {
    const [isOpen, setOpen] = useState(checked[option]);
    useEffect(() => setOpen(checked[option]), [checked, option]);

    return (
        <Box px={"22px"} pt={"15px"} backgroundColor={"white"}>
            <FlexRowAlignCenter pl={"9px"} onClick={() => setOpen(!isOpen)} cursor={"pointer"}>
                <Text fontFamily={"montserrat"} fontSize={"19px"} color={"sienna"}>
                    {title}
                </Text>
                <Box pl={"13px"} />
                {isOpen ? <ArrowDown fill={"sienna"} /> : <ArrowRight fill={"sienna"} />}
            </FlexRowAlignCenter>
            {isOpen ? (
                <Box pb={"8px"}>
                    <Text variant={"body3"} color={"nauticBlue"} pl={"9px"} pt={"4px"} pb={"16px"}>
                        {subtitle}
                    </Text>
                    {children}
                </Box>
            ) : (
                <Box pb={"15px"} />
            )}
        </Box>
    );
};

const SubDropdown = ({ children }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <FlexRowAlignCenter
                backgroundColor={"catskillWhite"}
                height={"40px"}
                px={"13px"}
                cursor={"pointer"}
                onClick={() => setOpen((state) => !state)}
            >
                {isOpen ? (
                    <>
                        <ArrowDown fill={"nauticBlue"} />
                        <Box pl={"6px"} />
                        <Text variant={"body3"} color={"nauticBlue"}>
                            <Span fontWeight={"bold"}>Hide</Span> print collateral options
                        </Text>
                    </>
                ) : (
                    <>
                        <ArrowRight fill={"nauticBlue"} />
                        <Box pl={"6px"} />
                        <Text variant={"body3"} color={"nauticBlue"}>
                            <Span fontWeight={"bold"}>Show</Span> print collateral options
                        </Text>
                    </>
                )}
            </FlexRowAlignCenter>
            <Box pb={"10px"} />
            {isOpen ? children : null}
        </>
    );
};

const ListItem = ({ setChecked, checked, shortLabel, option }) => (
    <FlexRowAlignCenter cursor={"pointer"} onClick={() => setChecked(option)}>
        <Text variant={"label1"} color={"balticBlue"}>
            {shortLabel}
        </Text>
        <Box pl={"4px"} />
        <Checkbox fill={"sienna"} isChecked={checked[option]} border={"1px solid #5A788F"} />
    </FlexRowAlignCenter>
);
