import React, { useState } from "react";
import { Box, FlexRowAlignCenter, Grid, Span } from "../styledSystemUtilities";
import { Button, Checkbox, Gradient, Input, Text } from "../Atoms";
import { VideoPlaybackIcon } from "../Atoms/assets";
import { bool, object, ref, string } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormError } from "../Atoms/FormError";
import { useDispatch } from "react-redux";
import { routerHistory } from "../../routerHistory";
import { CheckoutHeader } from "../Molecules/CheckoutHeader";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";
import { Terms } from "../Organisms/Terms";
import { actions } from "../../redux";
import useError from "../../hooks/useError";

const schema = object().shape({
    firstName: string().required("Please enter your first name.").trim(),
    lastName: string().required("Please enter your last name.").trim(),
    email: string().required("Please enter your email.").email("Please enter a valid email.").trim(),
    phone: string().required("Please enter your phone number."),
    accountName: string().required("Please enter an account name"),
    password: string()
        .required("Please enter a password")
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        ),
    confirmPassword: string()
        .required("Please confirm your password")
        .when("password", {
            is: (password) => !!(password && password.length > 0),
            then: string().oneOf([ref("password")], "Password doesn't match"),
        }),
    agreedToTerms: bool().oneOf([true], "You must agree to the terms of service."),
});

export const Account = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(schema),
    });
    const error = useError();

    const [termsAreOpen, setTermsAreOpen] = useState(false);

    const onSubmit = (accountInfo) => {
        dispatch(
            actions.createAccount({
                firstName: accountInfo.firstName,
                lastName: accountInfo.lastName,
                email: accountInfo.email,
                phone: accountInfo.phone,
                password: accountInfo.password,
                accountName: accountInfo.accountName,
            })
        );
    };

    return (
        <Box>
            {termsAreOpen ? <Terms setTermsAreOpen={setTermsAreOpen} /> : null}
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"44px"} />
                    <CheckoutHeader darkNumber={1} header={"Create your account"} />
                    <Box pb={"20px"} />
                    <FlexRowAlignCenter justifyContent={"center"}>
                        <VideoPlaybackIcon fill={"graphiteGray"} />
                        <Box pl={"9px"} />
                        <Text color={"graphiteGray"} variant={"body2"} fontWeight={"medium"}>
                            Subscription based marketing
                        </Text>
                    </FlexRowAlignCenter>
                    <Box pb={"6px"} />
                    <Text textAlign={"center"} variant={"body2"} color={"graphiteGray"}>
                        The future of digital marketing.
                    </Text>
                    <Box pb={8} />
                    <Text variant={"body4"}>
                        Already have an account?{" "}
                        <Span
                            color={"ipanemaBlue"}
                            cursor={"pointer"}
                            onClick={() => routerHistory.push("/login")}
                            data-testid={"loginLink"}
                        >
                            Login to add this new practice
                        </Span>
                    </Text>
                    <Box pb={"19px"} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            data-testid={"firstName"}
                            ref={register}
                            name={"firstName"}
                            placeholder="First Name"
                            hasError={errors.firstName}
                        />
                        <FormError error={errors.firstName} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"lastName"}
                            ref={register}
                            name={"lastName"}
                            placeholder="Last Name"
                            hasError={errors.lastName}
                        />
                        <FormError error={errors.lastName} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"email"}
                            ref={register}
                            name={"email"}
                            placeholder="Email Address"
                            hasError={errors.email}
                        />
                        <FormError error={errors.email} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"phone"}
                            ref={register}
                            name={"phone"}
                            placeholder="Phone Number"
                            hasError={errors.phone}
                        />
                        <FormError error={errors.phone} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"accountName"}
                            ref={register}
                            name={"accountName"}
                            placeholder="Create Account Name"
                            hasError={errors.accountName}
                        />
                        <FormError error={errors.accountName} />
                        <Box pt={3} pl={3}>
                            <Text variant={"label3"}>
                                The account name is used to identify your main account. (i.e., Family Dentistry)
                            </Text>
                            <Text variant={"label3"}>
                                It will be the primary account name for multiple locations where applicable.
                            </Text>
                        </Box>

                        <Box pb={"13px"} />
                        <Input
                            data-testid={"password"}
                            ref={register}
                            name={"password"}
                            placeholder="Create Password"
                            hasError={errors.password}
                        />
                        <FormError error={errors.password} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"confirmPassword"}
                            ref={register}
                            name={"confirmPassword"}
                            placeholder="Confirm Password"
                            hasError={errors.confirmPassword}
                        />
                        <FormError error={errors.confirmPassword} />
                        <Box pb={"26px"} />
                        <FlexRowAlignCenter>
                            <Controller
                                name={"agreedToTerms"}
                                control={control}
                                defaultValue={false}
                                render={({ onChange, value }) => (
                                    <Checkbox
                                        isChecked={value}
                                        fill={"sienna"}
                                        onClick={() => onChange(!value)}
                                        data-testid={"agreeToTerms"}
                                    />
                                )}
                            />

                            <Box pl={"13px"} />
                            <Text variant={"body4"}>
                                I agree to the Ascent{" "}
                                <Span color={"ipanemaBlue"} cursor={"pointer"} onClick={() => setTermsAreOpen(true)}>
                                    Terms of Use
                                </Span>
                            </Text>
                        </FlexRowAlignCenter>
                        <FormError error={errors.agreedToTerms} />
                        <Box pb={"26px"} />
                        <Text variant={"body3"}>
                            Creating an account will allow us to generate your reporting. Your reporting will enable us
                            to track your monthly results. You will review your results regularly with your Strategist.
                            Your Strategist will be assigned to you at the end of this purchase process.
                        </Text>
                        <Box pb={"22px"} />
                        <Button backgroundColor={"graphiteGray"} onClick={handleSubmit}>
                            create account
                        </Button>
                        <FormError error={error} />
                        <Box pb={15} />
                    </form>
                </Box>
            </Grid>
        </Box>
    );
};
