import { Box } from "../styledSystemUtilities";
import { Text } from "./Text";
import React from "react";

export const FormError = ({ error }) => {
    return error ? (
        <Box mt={5} ml={5}>
            <Text variant={"body3"} color={"sienna"}>
                {error.message}
            </Text>
        </Box>
    ) : null;
};
