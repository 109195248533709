import { routerHistory } from "../routerHistory";

export const confirmBack = (e) => {
    const value = window.confirm(
        "Your order has been processed, click ok if you would like to restart the assessment."
    );

    if (value) {
        window.location.reload(true);
    } else {
        routerHistory.go(1);
    }
};
