import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "profitPaymentOption",
    initialState: {
        value: 12,
        label: "12 payments*",
        disclaimer: "*12 months financing includes 12% security fee.",
        fee: 1.12,
        planCode: "12mos",
    },
    reducers: {
        set: (state, action) => action.payload,
    },
});
