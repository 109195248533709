import React from "react";
import ReactSelect from "react-select";
import { ArrowLeft, ArrowUp } from "./assets";

export const Select = (props) => {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: "1px solid #7F7F7F",
            height: "50px",
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: "500",
            color: "#7F7F7F",
            paddingLeft: "10px",
            paddingRight: "20px",
            borderRadius: 0,
            boxShadow: state.isFocused ? 0 : 0,
            ":hover": {
                cursor: "pointer",
            },
        }),
        singleValue: (base, state) => ({
            ...base,
            color: "#7F7F7F",
        }),
        placeholder: (base, state) => ({
            ...base,
            color: props.hasError ? "#E27867" : "#7F7F7F",
        }),
        menu: (base, state) => ({
            ...base,
            padding: 0,
            borderRadius: 0,
            margin: 0,
            bottom: "450px",
        }),
        menuList: (base, state) => ({
            ...base,
            maxHeight: "500px",
            padding: 0,
            bottom: "450px",
            boxShadow: "0 -2px 8px 0 rgba(0, 0, 0, 0.2)",
        }),
        input: (base, state) => ({
            ...base,
            color: "#7F7F7F",
        }),
        option: (base, state) => ({
            ...base,
            paddingLeft: "20px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "normal",
            color: "#7F7F7F",
            backgroundColor: "white",
            height: "50px",
            "&:hover": {
                backgroundColor: "#F8F8F9",
            },
        }),
    };
    return (
        <ReactSelect
            styles={customStyles}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: ({ selectProps }) => {
                    return selectProps.menuIsOpen ? (
                        <ArrowUp fill={props.hasError ? "sienna" : "agateGray"} />
                    ) : (
                        <ArrowLeft fill={props.hasError ? "sienna" : "agateGray"} />
                    );
                },
            }}
            {...props}
        />
    );
};

export const CheckoutSelect = (props) => {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: "1px solid #7F7F7F",
            height: "50px",
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: "500",
            color: "#7F7F7F",
            paddingLeft: "10px",
            paddingRight: "20px",
            borderRadius: 0,
            boxShadow: state.isFocused ? 0 : 0,
            ":hover": {
                cursor: "pointer",
            },
        }),
        singleValue: (base, state) => ({
            ...base,
            color: "#7F7F7F",
        }),
        placeholder: (base, state) => ({
            ...base,
            color: props.hasError ? "#E27867" : "#7F7F7F",
        }),
        menu: (base, state) => ({
            ...base,
            padding: 0,
            borderRadius: 0,
            margin: 0,
        }),
        menuList: (base, state) => ({
            ...base,
            maxHeight: "500px",
            padding: 0,
            boxShadow: "0 -2px 8px 0 rgba(0, 0, 0, 0.2)",
        }),
        input: (base, state) => ({
            ...base,
            color: "#7F7F7F",
        }),
        option: (base, state) => ({
            ...base,
            paddingLeft: "20px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "normal",
            color: "#7F7F7F",
            backgroundColor: "white",
            height: "50px",
            "&:hover": {
                backgroundColor: "#F8F8F9",
            },
        }),
    };
    return (
        <ReactSelect
            styles={customStyles}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: ({ selectProps }) => {
                    return selectProps.menuIsOpen ? (
                        <ArrowUp fill={props.hasError ? "sienna" : "agateGray"} />
                    ) : (
                        <ArrowLeft fill={props.hasError ? "sienna" : "agateGray"} />
                    );
                },
            }}
            {...props}
        />
    );
};
