import { Text } from "../Atoms";
import { formatter } from "../../utils/formatter";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import React from "react";
import _ from "lodash/fp";
import { getProfitMonthlyPaymentValue } from "../../utils/getProfitMonthlyPaymentValue";

export const SubscriptionTotal = ({ growthCost, groupedCart, onlineCost, coupon }) => {
    return (
        <Box
            border={"dark"}
            pl={"10px"}
            pr={"15px"}
            pb={"4px"}
            pt={"6px"}
            gridColumn={2}
            data-testid={"monthlySubscriptionSummary"}
        >
            <Text variant={"body4"}>{_.size(groupedCart.online) + _.size(groupedCart.growth)} Total Subscriptions</Text>
            <Box pb={"2px"} />
            {coupon.type && coupon.duration !== "forever" ? (
                <>
                    <TotalRow
                        text={
                            coupon.duration === 1
                                ? "Subscriptions First Month"
                                : `Subscriptions First ${coupon.duration} Months`
                        }
                        total={growthCost + onlineCost - coupon.amount}
                    />
                    <Box pb={"2px"} />
                    <TotalRow text={"Monthly Subscriptions Thereafter"} total={growthCost + onlineCost} />
                </>
            ) : (
                <>
                    <TotalRow
                        text={"Monthly Subscriptions Total"}
                        total={
                            coupon.type === "best" || coupon.type === "hpa"
                                ? growthCost + onlineCost - coupon.amount
                                : growthCost + onlineCost
                        }
                    />
                </>
            )}
        </Box>
    );
};

const TotalRow = ({ total, text }) => (
    <FlexRowAlignCenter justifyContent={"space-between"}>
        <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
            {text}
        </Text>
        <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
            {formatter.format(total)}
        </Text>
    </FlexRowAlignCenter>
);

export const ProfitTotal = ({ groupedCart, profitPaymentOption, profitTotalCost, coupon }) => {
    return (
        <Box border={"dark"} pl={"10px"} pr={"15px"} pb={"4px"} pt={"6px"} data-testid={"productsTotal"}>
            <Text variant={"body4"}>
                {_.size(groupedCart.profit) > 1 ? `${_.size(groupedCart.profit)} Total Products` : "1 Product"}
            </Text>
            <Box pb={"4px"} />
            <Text variant={"body4"}>
                {profitPaymentOption.value > 1 ? `${profitPaymentOption.value} Total Payments` : "1 Total Payment"}
            </Text>
            <Box pb={"2px"} />
            <FlexRowAlignCenter justifyContent={"space-between"}>
                <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
                    {profitPaymentOption.value > 1 ? "Monthly Products Total" : "Single Payment Total"}
                </Text>
                <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
                    {getProfitMonthlyPaymentValue(profitTotalCost, profitPaymentOption)}
                </Text>
            </FlexRowAlignCenter>
            {(_.get("type", coupon) === "best" || _.get("type", coupon)) && _.get("profitAmount", coupon) ? (
                <>
                    <FlexRowAlignCenter justifyContent={"space-between"}>
                        <Text variant={"body4"}>Discount</Text>
                        <Text variant={"body3"} fontWeight={"bold"} color={"amaranthRed"}>
                            - {formatter.format(_.round(coupon.profitAmount / profitPaymentOption.value))}
                        </Text>
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter justifyContent={"space-between"}>
                        <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
                            {profitPaymentOption.value > 1 ? "Monthly Products Total" : "Single Payment Total"}
                        </Text>
                        <Text variant={"body3"} fontWeight={"bold"} color={"graphiteGray"}>
                            {getProfitMonthlyPaymentValue(profitTotalCost, profitPaymentOption, coupon.profitAmount)}
                        </Text>
                    </FlexRowAlignCenter>
                </>
            ) : null}
        </Box>
    );
};
