import React from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { useSetPopup } from "../../hooks/useSetPopup";
import { popups } from "../../constants/popups";

export const PopupContainer = ({ popupId }) => {
    const setPopup = useSetPopup(null);
    const Popup = popups[popupId];

    return (
        <Grid
            position={"absolute"}
            gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}
            width={"100vw"}
            zIndex={8}
            pt={"21px"}
        >
            <Box
                gridColumn={2}
                style={{ "box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}
                mb={popupId ? "100px" : 0}
                backgroundColor={"white"}
            >
                {popupId ? <Popup setPopup={setPopup} /> : null}
            </Box>
        </Grid>
    );
};
