import { useSelector } from "react-redux";
import { BestForDentistryLogo, BigGrayLogo, HPALogo } from "./assets";
import React from "react";

export const WelcomeLogo = () => {
    const isBestForDentistry = useSelector((state) => state.isBestForDentistry);
    const isHpa = useSelector((state) => state.isHpa);

    return isBestForDentistry ? <BestForDentistryLogo /> : isHpa ? <HPALogo /> : <BigGrayLogo />;
};
