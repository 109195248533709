import { api } from "../utilityActions";

const savePlan = (leadId, plan) =>
    api({
        call: {
            url: "/savePlan",
            method: "post",
            data: {
                id: leadId,
                plan,
            },
        },
    });

export default savePlan;
