import React from "react";
import { GrowthPageTemplate } from "./PageTemplate";
import { Box, FlexCol, FlexRow, FlexRowAlignCenter, Grid } from "../styledSystemUtilities";
import { GrowthHeader, GrowthSubHeader } from "../Atoms/Headers";
import { routerHistory } from "../../routerHistory";
import { AdsIcon, ArrowLeft, ArrowRight, FullDownArrow, QuestionIcon, VideoPlaybackIcon } from "../Atoms/assets";
import {
    Cell,
    Checkbox,
    GrowthSlider,
    LeftCornerLine,
    MiddleLine,
    Operator,
    RightCornerLine,
    Row,
    Text,
} from "../Atoms";
import { CircleHeader } from "../Molecules/CircleHeader";
import lodash from "lodash";
import _ from "lodash/fp";
import { formatter } from "../../utils/formatter";
import { AddToCart, LearnMore } from "../Atoms/ProductButtons";
import { ShoppingCartButton, SmallButton } from "../Atoms/Button";
import { DIRECT_MAIL_MINIZINE, DIRECT_MAIL_POSTCARD, PAY_PER_CLICK } from "../../constants/productList";
import { useCart } from "../../hooks/useCart";
import { ppcAverages } from "../../constants/ppcAverages";
import { useGrowthAverageType } from "../../hooks/useGrowthAverageType";
import { EstimateRow } from "../Atoms/EstimateRow";
import { useGrowthEstimates } from "../../hooks/useGrowthEstimates";
import { useSetPopup } from "../../hooks/useSetPopup";
import { directMailAverages } from "../../constants/directMailAverages";
import { useSelector } from "react-redux";
import { getDirectMailDetails, getPpcDetails } from "../../utils/getGrowthDetails";
// import { report } from "./data";

export const GrowthThree = () => {
    const [type, setType] = useGrowthAverageType();
    const report = useSelector((state) => state.report);

    const cpc = _.get("ppc.keywords.0.cpc", report);
    const newPatientGoal = useSelector((state) => state.growthGoal);
    const ppcDetails = getPpcDetails(cpc, ppcAverages[type], newPatientGoal);
    const postcardDetails = getDirectMailDetails(newPatientGoal, directMailAverages.postcard);
    const minizineDetails = getDirectMailDetails(newPatientGoal, directMailAverages.minizine);

    const ppcProduct = {
        ...PAY_PER_CLICK,
        monthlyPrice: ppcDetails.totalMonthlyCost,
        newPatientEstimate: newPatientGoal,
    };

    const [ppcIsAdded, togglePpc, updatePpc] = useCart(ppcProduct);
    const [postcardIsAdded, togglePostcard, updatePostcard, watchedPostcard] = useCart({
        ...DIRECT_MAIL_POSTCARD,
        ...postcardDetails,
        newPatientEstimate: newPatientGoal,
    });
    const [minizineIsAdded, toggleMinizine, updateMinizine, watchedMinizine] = useCart({
        ...DIRECT_MAIL_MINIZINE,
        ...minizineDetails,
        newPatientEstimate: newPatientGoal,
    });
    const setPopup = useSetPopup(ppcProduct.popup);

    const updateGrowthProducts = (newGoal) => {
        updatePpc({
            ...PAY_PER_CLICK,
            monthlyPrice: getPpcDetails(cpc, ppcAverages[type], newGoal).totalMonthlyCost,
            newPatientEstimate: newGoal,
        });
        updatePostcard({
            ...DIRECT_MAIL_POSTCARD,
            ...getDirectMailDetails(newGoal, directMailAverages.postcard),
            newPatientEstimate: newGoal,
        });
        updateMinizine({
            ...DIRECT_MAIL_MINIZINE,
            ...getDirectMailDetails(newGoal, directMailAverages.minizine),
            newPatientEstimate: newGoal,
        });
    };

    const estimates = useGrowthEstimates([ppcProduct]);

    return (
        <GrowthPageTemplate>
            <Box pb={"24px"} />
            <GrowthHeader textAlign={"center"}>Your Growth Goals</GrowthHeader>
            <GrowthSubHeader>{_.get("user.practiceName", report)}</GrowthSubHeader>
            <Box pb={"17px"} />
            <FlexRow backgroundColor={"rgba(255, 255, 255, .5)"} justifyContent={"center"} py={"6px"}>
                <Box>
                    <SummaryRow>
                        <BackNumber number={1} path={"/growth-one"} />
                        <Ipanema10>Click Through</Ipanema10>
                        <Ipanema10>Click to Call</Ipanema10>
                        <Ipanema10>Call Conversion</Ipanema10>
                    </SummaryRow>
                    <Box pb={"2px"} />
                    <SummaryRow>
                        <Ipanema9>current</Ipanema9>
                        <BlueMontserratNumber>{`${ppcAverages.ascentAverages.ctr * 100}%`}</BlueMontserratNumber>
                        <BlueMontserratNumber>{`${ppcAverages.ascentAverages.ctc * 100}%`}</BlueMontserratNumber>
                        <BlueMontserratNumber>{`${
                            ppcAverages.ascentAverages.conversionRate * 100
                        }%`}</BlueMontserratNumber>
                    </SummaryRow>
                    <Box pb={"2px"} />
                    <SummaryRow>
                        <Ipanema9>20% below</Ipanema9>
                        <BlueMontserratNumber>{`${ppcAverages.conservativeAverages.ctr * 100}%`}</BlueMontserratNumber>
                        <BlueMontserratNumber>{`${ppcAverages.conservativeAverages.ctc * 100}%`}</BlueMontserratNumber>
                        <BlueMontserratNumber>{`${
                            ppcAverages.conservativeAverages.conversionRate * 100
                        }%`}</BlueMontserratNumber>
                    </SummaryRow>
                </Box>
                <FlexCol
                    backgroundColor={"white"}
                    width={"76px"}
                    height={"49px"}
                    px={"3px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Ipanema10 textAlign={"center"}>Your Area's Price Per Click</Ipanema10>
                    <Box pb={"2px"} />
                    <OrangeMontserratNumber>{formatter.format(cpc)}</OrangeMontserratNumber>
                </FlexCol>
            </FlexRow>
            <Box pb={"2px"} />
            <FlexRowAlignCenter
                backgroundColor={"rgba(255, 255, 255, .5)"}
                px="18px"
                py={"8px"}
                justifyContent={"space-between"}
                data-testid={"optionsRow"}
            >
                <BackNumber path={"/growth-two"} number={2} />
                <FlexRowAlignCenter onClick={() => setType("ascentAverages")}>
                    <Montserrat11>Ascent averages</Montserrat11>
                    <Box pl={"7px"} />
                    <Checkbox isChecked={type === "ascentAverages"} fill={"sienna"} border={"ipanema"} />
                </FlexRowAlignCenter>
                <FlexRowAlignCenter onClick={() => setType("conservativeAverages")}>
                    <Montserrat11>Conservative averages</Montserrat11>
                    <Box pl={"7px"} />
                    <Checkbox isChecked={type === "conservativeAverages"} fill={"sienna"} border={"ipanema"} />
                </FlexRowAlignCenter>
            </FlexRowAlignCenter>
            <Box pb={"16px"} />
            <CircleHeader
                number={3}
                color={"petrolBlue"}
                text={"Use the slider to set a new patient goal that matches your budget tolerance."}
            />
            <Box pb={"16px"} />
            <FlexRowAlignCenter pl={"28px"}>
                <Text variant={"body3"} fontWeight={"bold"} color={"petrolBlue"}>
                    Your Detailed Data Path:
                </Text>
                <Box pl={"10px"} />
                <QuestionIcon fill={"ipanemaBlue"} />
            </FlexRowAlignCenter>
            <Box pb={"16px"} />
            <Text variant={"body3"} color={"petrolBlue"} pl={"28px"}>
                How many patients per campaign?
            </Text>
            <Box pb={"12px"} />
            <Box pl={"28px"} pr={"36px"}>
                <GrowthSlider defaultValue={newPatientGoal} updateProducts={updateGrowthProducts} />
            </Box>
            <Box pb={"10px"} />
            <Box px={"10px"}>
                <Box display={"flex"}>
                    <Box width={6} />
                    <Row>
                        <Box width={"100%"} data-testid={"newPatientGoal"}>
                            <Text variant={"label2"} fontWeight={"bold"} color={"petrolBlue"} textAlign={"center"}>
                                New Patient Goal
                            </Text>
                            <Box pb={"6px"} />
                            <FlexRowAlignCenter
                                backgroundColor={"white"}
                                justifyContent={"center"}
                                height={"38px"}
                                width={"100%"}
                            >
                                <Text variant={"body2"} fontWeight={"medium"} color={"sienna"}>
                                    {newPatientGoal}
                                </Text>
                            </FlexRowAlignCenter>
                        </Box>
                        <Operator operator={"/"} />
                        <Cell
                            value={`${ppcDetails.callConversionRate * 100}%`}
                            color={"ipanemaBlue"}
                            label={"Call Conversion %"}
                        />
                        <Operator operator={"="} />
                        <Cell value={lodash.round(ppcDetails.neededCalls, 1)} label={"Needed calls"} />
                    </Row>
                    <RightCornerLine />
                </Box>
                <MiddleLine />
                <Box display={"flex"}>
                    <LeftCornerLine operator={"/"} />
                    <Row>
                        <Cell
                            value={`${ppcDetails.clickToCall * 100}%`}
                            color={"ipanemaBlue"}
                            label={"Click to call %"}
                        />
                        <Operator operator={"="} />
                        <Cell value={_.round(ppcDetails.neededClicks)} label={"Needed clicks"} />
                        <Operator operator={"x"} />
                        <Cell value={formatter.format(ppcDetails.cpc)} color={"sienna"} label={"Cost per click"} />
                    </Row>
                    <RightCornerLine />
                </Box>
                <MiddleLine />
                <Box display={"flex"}>
                    <LeftCornerLine operator={"="} height={"10px"} />
                    <Row>
                        <FlexRowAlignCenter
                            backgroundColor={"ipanemaBlue"}
                            height={"24px"}
                            width={"100%"}
                            justifyContent={"center"}
                        >
                            <Text color={"white"} variant={"body4"} fontWeight={"bold"}>
                                {formatter.format(lodash.round(ppcDetails.adSpend))}
                            </Text>
                        </FlexRowAlignCenter>
                        <FlexRowAlignCenter gridColumn={"2 / 5"} height={"24px"}>
                            <Text variant={"label2"} fontWeight={"bold"} color={"petrolBlue"} textAlign={"center"}>
                                Google Ads monthly spend
                            </Text>
                        </FlexRowAlignCenter>
                    </Row>
                    <Box width={"8px"} />
                </Box>

                <FlexRow alignItems={"flex-end"} mt={"-30px"}>
                    <Operator operator={"+"} />
                    <Box pl={"4px"} />
                    <Row>
                        <Cell value={formatter.format(ppcDetails.managementFee)} label={"Ascent Management"} />
                        <Operator operator={"+"} />
                        <Cell value={formatter.format(ppcDetails.callTrackingFee)} label={"Call Tracking"} />
                        <Operator operator={"="} />
                        <Box width={"100%"} transform={"translate(0, 56px)"}>
                            <FlexCol
                                backgroundColor={"#A6D8D8"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                height={"42px"}
                                width={"100%"}
                            >
                                <Text variant={"label2"} fontWeight={"bold"} color={"petrolBlue"}>
                                    Total Cost
                                </Text>
                                <Box pb={"5px"} />
                                <FullDownArrow />
                            </FlexCol>
                            <FlexRowAlignCenter justifyContent={"center"} height={"38px"} border={"1px solid #A6D8D8"}>
                                <Text
                                    variant={"body3"}
                                    fontWeight={"medium"}
                                    color={"deepPetrol"}
                                    data-testid={"total"}
                                >
                                    {formatter.format(lodash.round(ppcDetails.totalMonthlyCost))}/mo.
                                </Text>
                            </FlexRowAlignCenter>
                        </Box>
                    </Row>
                    <Box width={"8px"} />
                </FlexRow>
                <FlexRow>
                    <Box width={"13px"} />
                    <Row>
                        <FlexRowAlignCenter
                            height={"19px"}
                            justifyContent={"space-between"}
                            gridColumn={"1 / 4"}
                            width={"100%"}
                        >
                            <Box height={"1px"} backgroundColor={"blueLine"} flexGrow={1} />
                            <Text variant={"label2"} color={"petrolBlue"} px={"8px"}>
                                Fixed monthly fees
                            </Text>
                            <Box height={"1px"} backgroundColor={"blueLine"} flexGrow={1} />
                        </FlexRowAlignCenter>
                    </Row>
                    <Box width={"8px"} />
                </FlexRow>
            </Box>

            <Box backgroundColor={"white"} pl={"33px"} pt={"11px"} pr={"30px"}>
                <FlexRowAlignCenter>
                    <AdsIcon />
                    <Box pl={"5px"} />
                    <Text fontFamily={"montserrat"} fontSize={"20px"} color={"sienna"}>
                        Google Ads
                    </Text>
                </FlexRowAlignCenter>
                <Box pb={"3px"} />
                <Ipanema12>Controlled, Predictable Growth</Ipanema12>
                <Box pb={"15px"} />
                <FlexRowAlignCenter>
                    <Box width={"14px"}>
                        <VideoPlaybackIcon fill={"ipanemaBlue"} />
                    </Box>
                    <Box pl={"18px"} />
                    <Ipanema12>
                        This product is purchased as a subscription and can be cancelled anytime with 30 days notice.
                    </Ipanema12>
                </FlexRowAlignCenter>
                <Box pb={"10px"} />
            </Box>
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                backgroundColor={"cellBlue"}
                height={"28px"}
                px={"35px"}
                data-testid={ppcProduct.recurlyCode}
            >
                <LearnMore color={"petrolBlue"} setPopup={setPopup} />
                <AddToCart color={"petrolBlue"} isAdded={ppcIsAdded} toggleProduct={togglePpc} />
            </FlexRowAlignCenter>
            <Box pb={"13px"} />
            <EstimateRow
                questionColor={"ipanemaBlue"}
                estimates={estimates}
                text={"With this campaign, we estimate these averages:"}
                color={"deepPetrol"}
                roiPopup={"growthRoiExplanation"}
            />
            <Box pb={"18px"} />
            <Box height={"20px"} backgroundColor={"ipanemaBlue"} />
            <Box pb={"16px"} />
            <Text variant={"body3"} color={"petrolBlue"} lineHeight={"18px"} px={"22px"}>
                To achieve your goal of {newPatientGoal} patients per campaign, Consider these additional/alternative
                campaigns:
            </Text>
            <Box pb={"12px"} />
            <AdditionalProductCard product={watchedPostcard} toggleProduct={togglePostcard} isAdded={postcardIsAdded} />
            <Box pb={"10px"} />
            <AdditionalProductCard product={watchedMinizine} toggleProduct={toggleMinizine} isAdded={minizineIsAdded} />
            <Box pb={"15px"} />
            <FlexRowAlignCenter mx={"22px"} px={"17px"} py={"5px"} backgroundColor={"#F1F6F6"}>
                <Box width={"14px"}>
                    <VideoPlaybackIcon fill={"ipanemaBlue"} />
                </Box>

                <Box pl={"14px"} />
                <Ipanema12>
                    Direct mail is purchased as a subscription and can be cancelled anytime with 60 days notice.
                </Ipanema12>
            </FlexRowAlignCenter>
            <Box pb={"19px"} />
            <Text color={"petrolBlue"} variant={"body4"} px={"24px"}>
                The ROI estimate totals at the top of the page reflect the estimate totals for all products added to
                your cart.
            </Text>
            <Box pb={"16px"} />
            <FlexRow justifyContent={"space-between"}>
                <SmallButton backgroundColor={"auratiumGreen"} onClick={() => routerHistory.push("/online-one")}>
                    to online
                </SmallButton>
                <SmallButton backgroundColor={"balticBlue"} onClick={() => routerHistory.push("/profit-one")}>
                    to profit
                </SmallButton>
            </FlexRow>
            <Box pb={"10px"} />
            <FlexRow justifyContent={"center"}>
                <ShoppingCartButton backgroundColor={"ipanemaBlue"} onClick={() => routerHistory.push("/cart")} />
            </FlexRow>
            <Box pb={"100px"} />
        </GrowthPageTemplate>
    );
};

const AdditionalProductCard = ({ product, isAdded, toggleProduct }) => {
    const setPopup = useSetPopup(product.popup);
    const setDataPathPopup = useSetPopup(`${product.popup}DataPath`);

    return (
        <Box mx={"22px"} data-testid={product.recurlyCode}>
            <Box backgroundColor={"#EFF6F8"}>
                <FlexRowAlignCenter justifyContent={"space-between"} pl={"16px"} pr={"25px"} py={"8px"}>
                    <Box>
                        <Text variant={"body3"} color={"petrolBlue"}>
                            {product.name}
                        </Text>
                        <Text variant={"label1"} color={"ipanemaBlue"}>
                            {product.description}
                        </Text>
                    </Box>
                    <Text variant={"body3"}>{formatter.format(_.round(product.monthlyPrice))}/mo.</Text>
                </FlexRowAlignCenter>
                <FlexRowAlignCenter pl={"16px"} pb={"10px"}>
                    <Text variant={"body4"} color={"petrolBlue"}>
                        To see the data path for this campaign:
                    </Text>
                    <Box pl={"5px"} />
                    <FlexRowAlignCenter cursor={"pointer"} onClick={setDataPathPopup}>
                        <Text variant={"body4"} color={"petrolBlue"} fontWeight={"bold"}>
                            click here
                        </Text>
                        <Box pl={"5px"} />
                        <ArrowRight fill={"petrolBlue"} />
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
            </Box>

            <FlexRowAlignCenter
                px={"15px"}
                height={"28px"}
                backgroundColor={"#A6D8D8"}
                justifyContent={"space-between"}
                width={"100%"}
            >
                <LearnMore color={"deepPetrol"} setPopup={setPopup} />
                <AddToCart color={"deepPetrol"} isAdded={isAdded} toggleProduct={toggleProduct} />
            </FlexRowAlignCenter>
        </Box>
    );
};

const SummaryRow = ({ children }) => (
    <Grid gridTemplateColumns={"49px 68px 58px 82px"} justifyItems={"center"} alignItems={"center"}>
        {children}
    </Grid>
);

const BackNumber = ({ number, path }) => (
    <FlexRow
        alignItems={"center"}
        cursor={"pointer"}
        justifySelf={"flex-start"}
        onClick={() => routerHistory.push(path)}
    >
        <ArrowLeft fill={"ipanemaBlue"} />
        <Box pl={"3px"} />
        <Text variant={"body2"} color={"ipanemaBlue"} fontWeight={"bold"}>
            {number}
        </Text>
    </FlexRow>
);

const BaseMontserratNumber = ({ children, color }) => (
    <Text fontFamily={"montserrat"} fontSize={"11px"} fontWeight={"medium"} color={color}>
        {children}
    </Text>
);

const Montserrat11 = ({ children }) => <BaseMontserratNumber color={"ipanemaBlue"}>{children}</BaseMontserratNumber>;

const BlueMontserratNumber = ({ children }) => (
    <BaseMontserratNumber color={"deepPetrol"}>{children}</BaseMontserratNumber>
);
const OrangeMontserratNumber = ({ children }) => (
    <BaseMontserratNumber color={"sienna"}>{children}</BaseMontserratNumber>
);

const Ipanema12 = ({ children, ...props }) => (
    <Text color={"ipanemaBlue"} variant={"body4"} lineHeight={"16px"} {...props}>
        {children}
    </Text>
);

const Ipanema10 = ({ children, ...props }) => (
    <Text color={"ipanemaBlue"} variant={"label2"} {...props}>
        {children}
    </Text>
);

const Ipanema9 = ({ children }) => (
    <Text variant={"label3"} color={"ipanemaBlue"} fontWeight={"medium"} justifySelf={"flex-start"} uppercase>
        {children}
    </Text>
);
