import React from "react";
import { Box } from "../../styledSystemUtilities";
import { BrandHero, DoubleArrows, Heart, KeyBenefits, Target } from "../../Atoms/assets";
import {
    GrayBackground,
    IconBulletPoint,
    IconContainer,
    NumberedParagraph,
    PricingNotes,
    SellingPoint,
} from "../../Molecules";
import { ProfitHeader } from "../../Molecules/PopupComponents";

export const BrandDefinition = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <ProfitHeader setPopup={setPopup} Hero={BrandHero} />
            <GrayBackground>
                <SellingPoint
                    header={"Perceptions dictate actions"}
                    body={
                        "By defining your messaging strategy, you can better control the perceptions that people form about your practice. Those perceptions ultimately dictate why people choose you and, in turn, what they purchase. A great brand definition is the best way to increase your per patient value."
                    }
                />
                <IconContainer name={"What's Included"} />
                <IconBulletPoint Icon={Target} body={"It’s hard to hit a target you can’t see."} header={"Vision"} />
                <IconBulletPoint
                    Icon={Heart}
                    body={"Define your values to build your culture. Control the patient experience."}
                    header={"Values"}
                />
                <IconBulletPoint
                    Icon={DoubleArrows}
                    body={"Choices are based on perceived differences. Stand out."}
                    header={"Differentiation"}
                />
                <IconBulletPoint
                    Icon={KeyBenefits}
                    body={"Help your patients understand why you’re the right choice."}
                    header={"Key Benefits"}
                />
                <SellingPoint
                    header={"Everything flows from your brand"}
                    body={
                        "Once defined and approved, all your marketing messaging flows from your brand definition. This ensures your message is always correct, consistent, compelling, and hitting the right person at the right time. It allows Ascent to get to know you and, more importantly, help you and your team follow the Greek maxim: “Know thyself.”"
                    }
                />
                <IconContainer name={"How it works"} />
                <NumberedParagraph
                    header={"1. Fill out the questionnaire"}
                    body={"Answer questions about how you see your practice in its ideal form."}
                />
                <NumberedParagraph
                    header={"2. We write the brand"}
                    body={"An Ascent Copywriter uses your information to write a marketing-specific brand."}
                />
                <NumberedParagraph
                    header={"3. We present the brand"}
                    body={"An Ascent Strategist will go through your brand with you in detail."}
                />
                <NumberedParagraph
                    header={"4. Give feedback"}
                    body={"We work with you to fine-tune the message to ensure it tells your correct story."}
                />
                <NumberedParagraph
                    header={"5. We finalize the brand"}
                    body={"The brand is delivered to you and used to standardize your message."}
                />
            </GrayBackground>
            <PricingNotes>
                Brand definitions are purchased on a per-item basis and must be paid in full. We offer the choice of a
                1-time payment or 12 payments at a monthly interest rate of 12%.
            </PricingNotes>
        </Box>
    );
};
