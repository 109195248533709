import React from "react";
import { Box, FlexRow, Grid } from "../styledSystemUtilities";
import { Gradient, Text } from "../Atoms";
import { SiennaTriangle } from "../Atoms/assets";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";
import { useSelector } from "react-redux";
import _ from "lodash/fp";

//dispatch(
//                 actions.createPracticeAndUpdateAccount({
//                     practiceName: user.practiceName,
//                     type: user.practiceType,
//                     perPatientValue: perPatientValue,
//                     firstName: account.firstName,
//                     lastName: account.lastName,
//                     phone: account.phone,
//                     email: account.email,
//                     placeId: report.seo.placeId,
//                     recurlyAccountCode: _.get("charge_invoice.account.code", invoice),
//                     strategistId: response.data.id,
//                     clientAccountId: account.accountId,
//                 })
//             );

export const SubmittedOrder = () => {
    const invoice = useSelector((state) => state.invoice);
    const strategist = useSelector((state) => state.strategist);

    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Box pb={"70px"} />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"20px"}>
                    <Text variant={"header2"} color={"graphiteGray"} textAlign={"center"}>
                        Thank you!
                    </Text>
                    <Box pb={"14px"} />
                    <Text variant={"body2"} color={"graphiteGray"}>
                        Your order has been submitted.
                    </Text>
                    <Text variant={"body2"} color={"graphiteGray"}>
                        Order # {_.get("chargeInvoice.number", invoice)}
                    </Text>
                    <Box pb={"10px"} />
                    <Text variant={"body3"} lineHeight={"20px"}>
                        You’ll receive an overview of your order in your email.
                    </Text>
                    <Text variant={"body3"} lineHeight={"20px"}>
                        If you don’t receive the email within 1 business day, call us at 801-285-5669
                    </Text>
                    <Box pb={"14px"} />
                    <FlexRow>
                        {/*{profilePictures[strategist.id] && profilePictures[strategist.id]()}*/}
                        <Box pl={"8px"} />
                        <Box width={"184px"}>
                            <Text variant={"body2"} lineHeight={"20px"} color={"graphiteGray"}>
                                Your Marketing Strategist will be {strategist.name}.
                            </Text>
                            <Box pb={"3px"} />
                            <Text variant={"body3"} lineHeight={"20px"} color={"ipanemaBlue"}>
                                {strategist.email}
                            </Text>
                        </Box>
                    </FlexRow>
                    <Box pb={"16px"} />
                    <Text color={"graphiteGray"} fontWeight={"medium"} variant={"body1"}>
                        What's Next?
                    </Text>
                    <Box pb={"8px"} />
                    <Box width={"100%"} height={"1px"} backgroundColor={"midGray"} />
                    <Box pb={"14px"} />
                    <FlexRow>
                        <SiennaTriangle />
                        <Box pl={"16px"} />
                        <Text
                            alignSelf={"flex-end"}
                            color={"amaranthRed"}
                            fontWeight={"medium"}
                            variant={"body1"}
                            uppercase
                        >
                            kick off call
                        </Text>
                    </FlexRow>
                    <Box pb={"14px"} />
                    <Text variant={"body2"} color={"sienna"}>
                        Schedule your kick-off call with {strategist.name.split(" ")[0]}:
                    </Text>
                    <Box pb={"6px"} />
                    <Text variant={"body3"} lineHeight={"20px"}>
                        We begin implementation of the items on your order within 2 business days of the purchase date.
                        The kick-off call ensures we understand your goals, explains reporting, builds strategy
                        alignment, manages housekeeping items, opens channels of communication, and sets correct
                        expectations to ensure a beneficial relationship. Schedule it below:
                    </Text>
                    <Box pb={"20px"} />
                    <iframe
                        src={strategist.calendlyLink}
                        title="Schedule Appointment"
                        width="100%"
                        height={"1000"}
                        frameBorder="0"
                    />
                </Box>
            </Grid>
        </Box>
    );
};
