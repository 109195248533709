import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash/fp";
import { includesById } from "../../utils";

export default createSlice({
    name: "cart",
    initialState: [],
    reducers: {
        toggleProduct: (state, action) =>
            includesById(action.payload.id, state)
                ? _.reject({ id: action.payload.id }, state)
                : state.concat(action.payload),
        updateProduct: (state, action) =>
            _.map((item) => (item.id === action.payload.id ? action.payload : item), state),
        set: (state, action) => action.payload,
    },
});
