import React from "react";
import { GrowthPageTemplate } from "./PageTemplate";
import { Box, FlexCol, FlexRowAlignCenter } from "../styledSystemUtilities";
import { GrowthHeader, GrowthSubHeader } from "../Atoms/Headers";
import { CircleHeader } from "../Molecules/CircleHeader";
import { BigCheckbox } from "../Atoms/Checkbox";
import { Button, Text } from "../Atoms";
import { routerHistory } from "../../routerHistory";
import { ppcAverages } from "../../constants/ppcAverages";
import { formatter } from "../../utils/formatter";
import { useGrowthAverageType } from "../../hooks/useGrowthAverageType";
import { useSelector } from "react-redux";
import _ from "lodash/fp";
// import { report } from "./data";

export const GrowthTwo = () => {
    const [type, setType] = useGrowthAverageType();
    const report = useSelector((state) => state.report);

    const cpc = _.get("ppc.keywords.0.cpc", report);

    return (
        <GrowthPageTemplate hideSummary={true}>
            <Box pb={"24px"} />
            <GrowthHeader textAlign={"center"}>Your Expectations</GrowthHeader>
            <GrowthSubHeader>{_.get("user.practiceName", report)}</GrowthSubHeader>
            <Box pb={"17px"} />
            <CircleHeader
                number={2}
                color={"petrolBlue"}
                text={"How would you like to estimate results and set your expectations?"}
            />
            <Box pb={"23px"} />
            <Option
                lineOne={"I'd like to estimate using"}
                setType={setType}
                option={"Ascent's client averages"}
                selectedType={type}
                value={"ascentAverages"}
                body={"Set my data path to Ascent’s current averages:"}
                cpc={formatter.format(cpc)}
                ppcAverages={ppcAverages.ascentAverages}
                footer={
                    "This means your monthly costs will be lower and your probable results are in line with our average client."
                }
            />
            <Box pb={"20px"} />
            <Option
                lineOne={"I’d like to estimate"}
                setType={setType}
                option={"conservatively"}
                value={"conservativeAverages"}
                selectedType={type}
                body={"Set my data path to 20% below Ascent averages:"}
                cpc={formatter.format(cpc)}
                ppcAverages={ppcAverages.conservativeAverages}
                footer={
                    "This means your monthly costs will be higher and you will likely outperform your projected results."
                }
            />
            <Box pb={"28px"} />
            <Text color={"petrolBlue"} fontSize={"12px"} textAlign={"center"}>
                (You’ll be able to change this on the next screen.)
            </Text>
            <Box pb={"20px"} />
            <Button backgroundColor={"ipanemaBlue"} onClick={() => routerHistory.push("/growth-three")}>
                continue
            </Button>
            <Box pb={"100px"} />
        </GrowthPageTemplate>
    );
};

const Option = ({ selectedType, setType, option, lineOne, body, footer, ppcAverages, cpc, value }) => (
    <>
        <Box pl={"32px"} pt={"12px"} pb={"10px"} backgroundColor={"white"}>
            <OptionRow selectedType={selectedType} option={option} value={value} setType={setType} lineOne={lineOne} />
            <Box pb={"10px"} />
            <Text variant={"body3"} fontWeight={"medium"} color={"ipanemaBlue"}>
                {body}
            </Text>
        </Box>
        <FlexRowAlignCenter
            backgroundColor={"#F1F8FA"}
            py={"6px"}
            justifyContent={"space-between"}
            pr={"24px"}
            pl={"32px"}
            data-testid={value}
        >
            <Cell header={"Click Through"} data={ppcAverages.ctr} data-testid={"ctr"} />
            <Cell header={"Click to Call"} data={ppcAverages.ctc} data-testid={"ctc"} />
            <Cell header={"Call Conversion"} data={ppcAverages.conversionRate} data-testid={"conversionRate"} />
            <WhiteCell header={"+ Your Area Price Per Click"} data={cpc} />
        </FlexRowAlignCenter>
        <Box backgroundColor={"white"} py={"11px"} pl={"36px"} pr={"30px"}>
            <Text fontSize={"13px"} fontFamily={"roboto"} color={"ipanemaBlue"} lineHeight={"18px"}>
                {footer}
            </Text>
        </Box>
    </>
);

const OptionRow = ({ selectedType, lineOne, value, option, setType }) => (
    <FlexRowAlignCenter cursor={"pointer"} onClick={() => setType(value)}>
        <BigCheckbox isChecked={selectedType === value} fill={"sienna"} border={"petrol"} />
        <Box pl={"18px"} />
        <Box>
            <Text fontFamily={"montserrat"} fontSize={"14px"} fontWeight={"medium"} color={"sienna"}>
                {lineOne}
            </Text>
            <Text fontFamily={"montserrat"} fontSize={"21px"} color={"sienna"} pl={"20px"}>
                {option}
            </Text>
        </Box>
    </FlexRowAlignCenter>
);

const Cell = ({ header, data, ...props }) => (
    <FlexCol alignItems={"center"} {...props}>
        <Text variant={"label2"} color={"ipanemaBlue"}>
            {header}
        </Text>
        <Box pb={"4px"} />
        <Text fontSize={"11px"} fontFamily={"montserrat"} fontWeight={"medium"} color={"deepPetrol"}>
            {data * 100}%
        </Text>
    </FlexCol>
);

const WhiteCell = ({ header, data }) => (
    <FlexCol
        alignItems={"center"}
        justifyContent={"center"}
        backgroundColor={"white"}
        width={"76px"}
        height={"49px"}
        data-testid={"cpc"}
    >
        <Text variant={"label2"} color={"ipanemaBlue"} textAlign={"center"}>
            {header}
        </Text>
        <Box pb={"4px"} />
        <Text fontSize={"11px"} fontFamily={"montserrat"} fontWeight={"medium"} color={"sienna"}>
            {data}
        </Text>
    </FlexCol>
);
