import { combineReducers } from "redux";
import slices from "./slices";

export default combineReducers({
    user: slices.user.reducer,
    places: slices.places.reducer,
    loading: slices.loading.reducer,
    cart: slices.cart.reducer,
    popup: slices.popup.reducer,
    onlineOptions: slices.onlineOptions.reducer,
    growthAverageType: slices.growthAverageType.reducer,
    perPatientValue: slices.perPatientValue.reducer,
    profitOptions: slices.profitOptions.reducer,
    invoice: slices.invoice.reducer,
    recurlyToken: slices.recurlyToken.reducer,
    profitPaymentOption: slices.profitPaymentOption.reducer,
    error: slices.error.reducer,
    sessionId: slices.sessionId.reducer,
    report: slices.report.reducer,
    growthGoal: slices.growthGoal.reducer,
    couponCode: slices.couponCode.reducer,
    account: slices.account.reducer,
    strategist: slices.strategist.reducer,
    practiceExists: slices.practiceExists.reducer,
    isBestForDentistry: slices.isBestForDentistry.reducer,
    tasks: slices.tasks.reducer,
    isBurkhart: slices.isBurkhart.reducer,
    isHpa: slices.isHpa.reducer,
});
