import updateLead from "./updateLead";
import createPurchase from "./createPurchase";
import createSession from "./createSession";
import getPlaces from "./getPlaces";
import getReport from "./getReport";
import getSession from "./getSession";
import savePlan from "./savePlan";
import sendSessionLinkEmail from "./sendSessionLinkEmail";
import updateSession from "./updateSession";
import createAccount from "./createAccount";
import login from "./login";
import createPracticeAndUpdateAccount from "./createPracticeAndUpdateAccount";
import getStrategist from "./getStrategist";
import checkIfPracticeExists from "./checkIfPracticeExists";

const actions = {
    updateLead,
    createPurchase,
    createSession,
    getPlaces,
    getReport,
    getSession,
    savePlan,
    sendSessionLinkEmail,
    updateSession,
    createAccount,
    login,
    createPracticeAndUpdateAccount,
    getStrategist,
    checkIfPracticeExists,
};

export default actions;
