import { MiniNumberCircle } from "../Atoms/MiniCircleNumber";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import React from "react";
import { Text } from "../Atoms";

export const CheckoutHeader = ({ darkNumber, header }) => {
    return (
        <>
            <FlexRowAlignCenter justifyContent={"center"}>
                <MiniNumberCircle isDark={darkNumber === 1} number={1} />
                <Box pl={"12px"} />
                <MiniNumberCircle isDark={darkNumber === 2} number={2} />
                <Box pl={"12px"} />
                <MiniNumberCircle isDark={darkNumber === 3} number={3} />
            </FlexRowAlignCenter>
            <Box pb={"12px"} />
            <Text variant={"header2"} color={"graphiteGray"} textAlign={"center"}>
                {header}
            </Text>
        </>
    );
};
