import { Text } from "./Text";
import React from "react";

export const OnlineHeader = ({ children, ...props }) => (
    <Text lineHeight={"33px"} variant={"header2"} color={"forrestGreen"} {...props}>
        {children}
    </Text>
);

export const OnlineSubHeader = ({ children, ...props }) => (
    <Text lineHeight={"21px"} textAlign={"center"} variant={"body2"} color={"auratiumGreen"} {...props}>
        {children}
    </Text>
);

export const GrowthHeader = ({ children, ...props }) => (
    <Text lineHeight={"33px"} variant={"header2"} color={"deepPetrol"} {...props}>
        {children}
    </Text>
);

export const GrowthSubHeader = ({ children }) => (
    <Text lineHeight={"21px"} textAlign={"center"} variant={"body2"} color={"ipanemaBlue"}>
        {children}
    </Text>
);

export const ProfitHeader = ({ children, ...props }) => (
    <Text lineHeight={"33px"} variant={"header2"} color={"nauticBlue"} {...props}>
        {children}
    </Text>
);

export const ProfitSubHeader = ({ children }) => (
    <Text lineHeight={"21px"} textAlign={"center"} variant={"body2"} color={"balticBlue"}>
        {children}
    </Text>
);
