import React from "react";
import { Gradient, Text } from "../Atoms";
import { Box } from "../styledSystemUtilities";
import { useTimeout } from "react-use";
import { BigCheck, GoogleMyBusinessLogo } from "../Atoms/assets";

export const PlaceSelectLoading = ({ isLoading, children }) => {
    const [renderCheck] = useTimeout(1000);
    const [renderPage] = useTimeout(1500);
    const [renderDot1] = useTimeout(200);
    const [renderDot2] = useTimeout(400);
    const [renderDot3] = useTimeout(600);

    return isLoading || !renderPage() ? (
        <>
            <Gradient />
            <Box display={"grid"} gridTemplateColumns={"1fr 10px minmax(300px, 330px) 10px 1fr"}>
                <Box gridColumn={3}>
                    <Box pb={"136px"} />
                    <Text variant={"header2"} color={"graphiteGray"}>
                        Finding Your Listing
                        {renderDot1() ? "." : null}
                        {renderDot2() ? "." : null}
                        {renderDot3() ? "." : null}
                    </Text>
                    <Box pb={10} />
                    <Text variant={"body3"}>Looking for matching listing information</Text>
                    <Box pb={7} />
                    <Box display={"flex"} alignItems={"center"}>
                        <GoogleMyBusinessLogo />
                        <Box pl={8} />
                        {renderCheck() ? <BigCheck data-testid={"bigCheck"} fill={"sienna"} /> : null}
                    </Box>
                </Box>
            </Box>
        </>
    ) : (
        children
    );
};
