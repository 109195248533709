import React from "react";
import { Box } from "../styledSystemUtilities";
import { BigCheck, Check } from "./assets";

export const Checkbox = ({ isChecked, fill, ...props }) => {
    return (
        <Box
            width={8}
            height={8}
            pt={"1px"}
            pl={"1px"}
            border={"gray"}
            cursor={"pointer"}
            data-testid={"checkBox"}
            {...props}
        >
            {isChecked ? <Check fill={fill} className={"check"} /> : null}
        </Box>
    );
};

export const BigCheckbox = ({ isChecked, fill, ...props }) => {
    return (
        <Box
            width={"30px"}
            height={"30px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            border={"dark"}
            cursor={"pointer"}
            {...props}
        >
            {isChecked ? <BigCheck fill={fill} className={"check"} /> : null}
        </Box>
    );
};
