import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box } from "../styledSystemUtilities";
import { Text } from "./Text";
import { Pin } from "./assets";
import { ScoreGradient } from "./Gradient";
import { Transition } from "react-transition-group";

export const RankIndicator = ({ rank, max, startAnimationTimeout }) => {
    return (
        <Box mx={"10px"}>
            <StartFromRightPinPlacement
                score={rank}
                percent={((max - rank) / (max - 1)) * 100}
                isVisible={false}
                startAnimationTimeout={startAnimationTimeout}
            />
            <ScoreGradient />
            <Box mb={3} />
            <Box display={"flex"} justifyContent={"space-between"}>
                <Text variant={"body4"} color={"auratiumGreen"}>
                    Low
                </Text>
                <Text variant={"body4"} color={"auratiumGreen"}>
                    Average
                </Text>
                <Text variant={"body4"} color={"auratiumGreen"}>
                    Ideal
                </Text>
            </Box>
        </Box>
    );
};

export const StartFromRightPinPlacement = ({ percent, score, isVisible, startAnimationTimeout }) => (
    <PinPlacement
        percent={percent}
        score={score}
        startingPosition={"100%"}
        formatter={(value) => value}
        increment={1}
        isVisible={isVisible}
        startAnimationTimeout={startAnimationTimeout}
    />
);

export const StartFromLeftPinPlacement = ({
    percent,
    score,
    formatter,
    increment,
    isVisible,
    startAnimationTimeout,
}) => (
    <PinPlacement
        percent={percent}
        score={score}
        startingPosition={"0"}
        formatter={formatter}
        increment={increment}
        isVisible={isVisible}
        startAnimationTimeout={startAnimationTimeout}
    />
);

export const PinPlacement = ({ percent, score, startingPosition, formatter, increment, startAnimationTimeout }) => {
    const [startAnimation, setStartAnimation] = useState(false);
    const [stopTimer, setStopTimer] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const animationDuration = 2000;
    const ref = useRef(null);

    useLayoutEffect(() => {
        const topPosition = ref.current.getBoundingClientRect().top;
        const initialScrollPosition = window.scrollY + window.innerHeight;
        if (topPosition < initialScrollPosition) {
            setIsVisible(true);
        }
        const onScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            if (topPosition < scrollPosition) {
                setIsVisible(true);
            }
        };

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        const interval = (animationDuration - 100) / score;
        const interval1 =
            isVisible && startAnimation
                ? setInterval(() => {
                      setCounter((prevState) => (prevState + increment > score ? prevState : prevState + increment));
                  }, interval / (1 / increment))
                : () => {};

        if (stopTimer) {
            clearInterval(interval1);
        }

        return () => (isVisible ? clearInterval(interval) : () => {});
    }, [stopTimer, score, animationDuration, increment, isVisible, startAnimation]);

    const styles = {
        entering: { opacity: 1, left: `${percent > 100 ? 100 : percent}%` },
        entered: { opacity: 1, left: `${percent > 100 ? 100 : percent}%` },
        exiting: { opacity: 0, left: startingPosition },
        exited: { opacity: 0, left: startingPosition },
    };

    useEffect(() => {
        const timer1 = startAnimation ? () => {} : setTimeout(() => setStartAnimation(true), startAnimationTimeout);
        const timer2 = isVisible && startAnimation ? setTimeout(() => setStopTimer(true), animationDuration) : () => {};

        return () => {
            clearTimeout(timer1);
            return isVisible ? clearTimeout(timer2) : () => {};
        };
    }, [isVisible, startAnimation, startAnimationTimeout]);

    return (
        <Box mx={"14px"} ref={ref}>
            <Transition in={isVisible && startAnimation} timeout={animationDuration}>
                {(state) => (
                    <Box
                        position={"relative"}
                        transform={"translate(-16px)"}
                        transition={"all 2000ms"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        width={"32px"}
                        style={{ ...styles[state] }}
                    >
                        <Text variant={"header2"} color={"sienna"}>
                            {stopTimer ? formatter(score) : formatter(counter)}
                        </Text>
                        <Pin />
                    </Box>
                )}
            </Transition>
        </Box>
    );
};
