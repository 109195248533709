import React from "react";
import { Box } from "../../styledSystemUtilities";
import { EnhancedWebsiteHero, EnhancedWebsiteSample } from "../../Atoms/assets";
import { AdvancedSeo, GaGraphSample, GmbGraphSample, Google, SeoContent, Text } from "../../Atoms";
import {
    GrayBackground,
    IconContainer,
    NumberedParagraph,
    OnlineHeader,
    Paragraph,
    PricingNotes,
    ReportingCard,
    ReportingHeader,
    SellingPoint,
} from "../../Molecules";

export const EnhancedWebsite = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <OnlineHeader setPopup={setPopup} Hero={EnhancedWebsiteHero} />
            <GrayBackground>
                <SellingPoint
                    header={"Outperform your competition in more competitive areas."}
                    body={
                        "Abundant, keyword specific content is one of the key things Google looks at when deciding where to rank your business. These enhancers provide just that and give you the edge in more competitive areas."
                    }
                />
                <SellingPoint
                    header={"An advanced SEO approach"}
                    body={
                        "Our Website Enhancer is structured to integrate advanced SEO strategy into additional keyword rich and content-specific pages. These allow us to help you outperform your local competition for content relevancy."
                    }
                />
                <SellingPoint header={"Enhanced Service Pages"} body={"We pride ourselves on superior design."} />

                <IconContainer name={"Technology"}>
                    <AdvancedSeo name={"Advanced SEO Integration"} />
                    <Google name={"Google Analytics & Optimized"} fill={"auratiumGreen"} />
                </IconContainer>
                <IconContainer name={"Fulfillment"}>
                    <SeoContent name={"Additional SEO Content"} />
                </IconContainer>
                <Text color={"auratiumGreen"} variant={"header2"}>
                    Sample Page
                </Text>
                <Box pb={"6px"} />
                <Text variant={"body3"} lineHeight={"20px"} pl={"4px"}>
                    Below is a partial sample for an enhanced content page specifically for dental implants. The page is
                    focused on using critical keywords to tie the benefits of the service into your practice.
                </Text>
            </GrayBackground>
            <Box backgroundColor={"chalk"}>
                <EnhancedWebsiteSample />
            </Box>

            <GrayBackground>
                <ReportingHeader product={"website"} color={"auratiumGreen"} />
            </GrayBackground>
            <ReportingCard>
                <NumberedParagraph
                    header={"1. Phone Calls"}
                    body={
                        "How many phone calls were generated from the website? We track phone calls and conversions through a dynamic call tracking number."
                    }
                />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"Is it generating enough traffic to help with your ranking?"}
                    header={"2. Online ranking"}
                />
                <Paragraph>
                    We track new users and total users. This allows us to report how many new users are being driven to
                    the site and how many visitors return each month.
                </Paragraph>
                <GaGraphSample />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    header={"3. Google My Business integration"}
                    body={"Are we seeing enough interaction with your GMB account?"}
                />
                <Paragraph>
                    Tracking web link clicks and phone actions from your Google My Business listing help determine where
                    your opportunity lies.
                </Paragraph>
                <GmbGraphSample />
            </ReportingCard>
            <PricingNotes>
                The Website Enhancer is purchased on a subscription basis and requires 30 days notice to cancel.
            </PricingNotes>
        </Box>
    );
};
