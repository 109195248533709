import React from "react";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Text } from "./Text";
import { Checkbox } from "./Checkbox";
import { ProductCardSelect } from "./ProductCardSelect";
import _ from "lodash/fp";
import { useCart } from "../../hooks/useCart";
import { getIsChecked } from "../../utils/getIsChecked";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export const BaseCheckoutCard = ({ product, checkedProducts, setChecked, testId }) => {
    const isChecked = getIsChecked(product, checkedProducts);

    return (
        <FlexRowAlignCenter
            py={"4px"}
            pl={"10px"}
            pr={"15px"}
            justifyContent={"space-between"}
            backgroundColor={"white"}
            data-testid={testId ? testId : product.recurlyCode}
        >
            <FlexRowAlignCenter>
                <Checkbox
                    fill={"nauticBlue"}
                    border={"1px solid #5A788F"}
                    isChecked={isChecked}
                    onClick={() =>
                        isChecked
                            ? setChecked(_.reject((checkedProduct) => checkedProduct.id === product.id))
                            : setChecked((prevState) => prevState.concat(product))
                    }
                />
                <Box pl={"20px"} />
                <Box>
                    <Text color={"nauticBlue"} variant={"body3"} opacity={isChecked ? 1 : 0.31}>
                        {product.name}
                    </Text>
                    <Text variant={"label1"} color={"balticBlue"} opacity={isChecked ? 1 : 0.27}>
                        {product.description}
                    </Text>
                </Box>
            </FlexRowAlignCenter>
            {isChecked ? (
                <Text variant={"body3"} color={"agateGray"}>
                    {formatter.format(_.round(product.price))}
                </Text>
            ) : null}
        </FlexRowAlignCenter>
    );
};

export const PrintCheckoutCard = ({ product, checkedProducts, setChecked }) => {
    const { 2: updateProduct, 3: watchedProduct } = useCart(product);

    return (
        <Box data-testid={watchedProduct.recurlyCode}>
            <BaseCheckoutCard
                product={watchedProduct}
                setChecked={setChecked}
                checkedProducts={checkedProducts}
                testId={"base"}
            />
            <Box display={"flex"} justifyContent={"space-between"} backgroundColor={"white"} px={"13px"}>
                <Box display={"flex"} alignItems={"center"} height={"46px"}>
                    <Text variant={"label2"} color={"balticBlue"}>
                        Quantity
                    </Text>
                    <Box ml={2} />
                    <Box width={"93px"} data-testid={`quantitySelect${watchedProduct.id}`}>
                        <ProductCardSelect
                            onChange={(quantityOption) => {
                                updateProduct({
                                    ...watchedProduct,
                                    price: quantityOption.price,
                                    recurlyCode: quantityOption.recurlyCode,
                                    defaultQuantity: quantityOption,
                                });
                                setChecked((prevState) =>
                                    prevState.map((checkedProduct) =>
                                        checkedProduct.id === watchedProduct.id
                                            ? {
                                                  ...watchedProduct,
                                                  price: quantityOption.price,
                                                  recurlyCode: quantityOption.recurlyCode,
                                                  defaultQuantity: quantityOption,
                                              }
                                            : checkedProduct
                                    )
                                );
                            }}
                            defaultValue={watchedProduct.defaultQuantity}
                            options={watchedProduct.quantities}
                        />
                    </Box>
                    <Box ml={"34px"} />
                </Box>
                <Box width={"132px"} textAlign={"center"}>
                    <Text variant={"label2"} position={"relative"} bottom={"2px"} color={"balticBlue"}>
                        Paper type:
                    </Text>
                    <Box data-testid={"paperTypeSelect"}>
                        <Box
                            display={"flex"}
                            backgroundColor={"white"}
                            height={"25px"}
                            justifyContent={"center"}
                            border={"1px solid #94A5B5"}
                            alignItems={"center"}
                        >
                            <Text variant={"body4"} color={"nauticBlue"}>
                                {watchedProduct.paperType}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export const LionSpeakCheckoutCard = ({ product, setChecked, checkedProducts }) => {
    const { 2: updateProduct, 3: watchedProduct } = useCart(product);

    return (
        <Box data-testid={watchedProduct.recurlyCode}>
            <BaseCheckoutCard
                product={watchedProduct}
                checkedProducts={checkedProducts}
                setChecked={setChecked}
                testId={"base"}
            />
            <FlexRowAlignCenter justifyContent={"space-between"} backgroundColor={"white"} pb={"13px"} px={"13px"}>
                <Text variant={"label2"} color={"balticBlue"}>
                    Choose Training Level:
                </Text>
                <Box width={"192px"} data-testid={`trainingLevelSelect${watchedProduct.id}`}>
                    <ProductCardSelect
                        onChange={(option) => {
                            updateProduct({
                                ...watchedProduct,
                                defaultValue: option,
                                price: option.price,
                                recurlyCode: option.recurlyCode,
                            });
                            setChecked((prevState) =>
                                prevState.map((checkedProduct) =>
                                    checkedProduct.id === watchedProduct.id
                                        ? {
                                              ...watchedProduct,
                                              defaultValue: option,
                                              price: option.price,
                                              recurlyCode: option.recurlyCode,
                                          }
                                        : checkedProduct
                                )
                            );
                        }}
                        defaultValue={watchedProduct.defaultValue}
                        options={watchedProduct.options}
                        classNamePrefix={"trainingLevel"}
                    />
                </Box>
            </FlexRowAlignCenter>
        </Box>
    );
};
