import React from "react";
import { Text } from "../Atoms";
import { Box } from "../styledSystemUtilities";
import { KeywordsTable } from "../Molecules";

export const SeoKeywordsTableCard = ({ keywords, competitorCount }) => {
    return (
        <Box backgroundColor={"cardGreen"} px={9} pb={8} pt={5}>
            <Text variant={"body3"} color={"auratiumGreen"} fontWeight={"medium"}>
                Top Keyword Rankings
            </Text>
            <Box mb={4} />
            <Text variant={"body4"}>Where do you rank for the most searched keywords in Google Local searches?</Text>
            <Box mb={4} />
            <KeywordsTable keywords={keywords} competitorCount={competitorCount} />
        </Box>
    );
};
