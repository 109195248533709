import React from "react";
import { Box } from "../styledSystemUtilities";
import { CardContainer, OnlineSlider, Text } from "../Atoms";
import { QuestionIcon } from "../Atoms/assets";
import _ from "lodash/fp";
import { onlineProductTiers } from "../../constants/onlineProductTiers";
import { RoiExplanation } from "./Popups";
import { OnlineProductCardContainer } from "../Molecules/ProductCardContainer";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export const OnlineProductSelect = ({
    onlineScore = 5,
    perPatientValue = 1000,
    products,
    setProducts,
    setPopup,
    defaultValue,
    setDefaultValue,
    visibilityLevel,
    handleAdd,
}) => {
    const idealProducts = filterProducts(100, onlineScore);
    const idealBudget = _.sumBy("monthlyPrice", idealProducts);
    const budget = _.flow(_.filter("isChecked"), _.sumBy("monthlyPrice"))(products);
    const highPatients = _.round(
        budget / (onlineProductTiers[onlineScore].highCostPerPatient * (idealBudget / budget))
    );
    const lowPatients = _.round(budget / (onlineProductTiers[onlineScore].lowCostPerPatient * (idealBudget / budget)));
    const estimatedRevenue = _.round(((highPatients + lowPatients) / 2) * perPatientValue);
    const estimatedRoi = estimatedRevenue - budget;
    const currentSliderPosition = defaultValue === 0 ? "low" : defaultValue === 50 ? "average" : "ideal";

    const handleSliderChange = (value) => {
        const newProducts = filterProducts(value, onlineScore);
        setProducts((prevState) => newProducts);
        setDefaultValue((prevState) => value);
    };

    return (
        <CardContainer>
            <Box mb={3} ml={"11px"}>
                <Text variant={"body3"}>What level of online visibility do you want?</Text>
            </Box>

            <Box px={"11px"} mb={8}>
                <OnlineSlider handleChange={handleSliderChange} defaultValue={defaultValue} />
            </Box>

            <Box display={"flex"} alignItems={"center"} ml={"11px"}>
                <Text variant={"body3"} fontWeight={"medium"}>
                    To {currentSliderPosition === visibilityLevel ? "maintain" : "achieve"}
                </Text>
                <Box
                    backgroundColor={"white"}
                    height={9}
                    width={"70px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mx={2}
                >
                    <Text
                        variant={"body3"}
                        fontWeight={"medium"}
                        color={"auratiumGreen"}
                        data-testid={"sliderPosition"}
                    >
                        {currentSliderPosition}
                    </Text>
                </Box>
                <Text variant={"body3"} fontWeight={"medium"}>
                    visibility, we recommend:
                </Text>
            </Box>

            <Box mb={4} />

            <OnlineProductCardContainer products={products} handleAdd={handleAdd} setPopup={setPopup} />
            <Box mb={4} />
            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} pr={"53px"} mb={"3px"}>
                <Text variant={"label3"} color={"slateGray"}>
                    MONTHLY
                </Text>
            </Box>
            <TotalRowContainer>
                <Text variant={"body3"} fontWeight={"bold"} color={"auratiumGreen"}>
                    Online Total
                </Text>
                <Text variant={"body3"} fontWeight={"bold"} data-testid={"total"}>
                    {formatter.format(budget)}
                </Text>
            </TotalRowContainer>
            <Box mb={1} />
            <TotalRowContainer>
                <Text variant={"body4"}>Estimated New Patients</Text>
                <Text variant={"body3"}>
                    {lowPatients}-{highPatients}
                </Text>
            </TotalRowContainer>
            <TotalRowContainer>
                <Text variant={"body4"}>Estimated Revenue Avg.</Text>
                <Text variant={"body3"}>{formatter.format(estimatedRevenue)}</Text>
            </TotalRowContainer>
            <Box mb={1} />
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                backgroundColor={"white"}
                height={"29px"}
                pl={"11px"}
            >
                <Text variant={"body3"} fontWeight={"bold"} color={"auratiumGreen"}>
                    Estimated ROI Avg.
                </Text>
                <Box display={"flex"} alignItems={"center"} mr={"15px"}>
                    <Box width={"64px"}>
                        <Text variant={"body3"} fontWeight={"bold"}>
                            {formatter.format(estimatedRoi)}
                        </Text>
                    </Box>
                    <QuestionIcon fill={"auratiumGreen"} onClick={() => setPopup((prevState) => RoiExplanation)} />
                </Box>
            </Box>
        </CardContainer>
    );
};

function TotalRowContainer({ children }) {
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            backgroundColor={"white"}
            height={"29px"}
            pl={"11px"}
            pr={"53px"}
        >
            {children}
        </Box>
    );
}

function filterProducts(value, score) {
    const desiredRanking = value === 0 ? "low" : value === 50 ? "average" : "ideal";

    return _.map(
        (product) => ({
            ...product,
            isChecked: true,
        }),
        onlineProductTiers[score][desiredRanking]
    );
}
