import React from "react";
import { Box } from "../styledSystemUtilities";
import { Gradient } from "../Atoms";
import { GrowthMenu, OnlineMenu, ProfitMenu } from "../Organisms/Menu";
import { GrowthPhaseLabel, OnlinePhaseLabel, ProfitPhaseLabel } from "../Molecules/PhaseLabel";
import onlineClouds from "../Atoms/assets/onlineClouds.jpg";
import growthClouds from "../Atoms/assets/growthClouds.jpg";
import profitClouds from "../Atoms/assets/profitClouds.jpg";
import { useWindowSize } from "react-use";
import { PopupContainer } from "../Organisms/PopupContainer";
import { useSelector } from "react-redux";

export const OnlinePageTemplate = ({ hideSummary, children }) => (
    <PageTemplate
        Menu={OnlineMenu}
        PhaseLabel={OnlinePhaseLabel}
        backgroundImage={onlineClouds}
        hideSummary={hideSummary}
        children={children}
        frontColor={"seaGreen"}
    />
);

export const GrowthPageTemplate = ({ hideSummary, children }) => (
    <PageTemplate
        Menu={GrowthMenu}
        PhaseLabel={GrowthPhaseLabel}
        backgroundImage={growthClouds}
        hideSummary={hideSummary}
        children={children}
        frontColor={"ipanemaBlue"}
    />
);

export const ProfitPageTemplate = ({ hideSummary, children }) => (
    <PageTemplate
        Menu={ProfitMenu}
        PhaseLabel={ProfitPhaseLabel}
        backgroundImage={profitClouds}
        hideSummary={hideSummary}
        children={children}
        frontColor={"balticBlue"}
    />
);

export const PageTemplate = ({ PhaseLabel, hideSummary, backgroundImage, Menu, children, frontColor }) => {
    const { width, height } = useWindowSize();
    const padding = hideSummary ? 69 : 133;
    const popupId = useSelector((state) => state.popup);

    return (
        <>
            <Box position={"fixed"} zIndex={100}>
                <Gradient />
                <Box height={5} backgroundColor={frontColor} />
            </Box>

            <PopupContainer popupId={popupId} />
            <Menu hideSummary={hideSummary} />
            <Box
                height={"100vh"}
                position={"fixed"}
                width={"100vw"}
                backgroundImage={`url(${backgroundImage})`}
                backgroundPosition={"left top"}
                backgroundSize={"cover"}
            />
            <Box pt={`${padding}px`} />
            <Box
                position={popupId ? "fixed" : "relative"}
                minHeight={`${height - padding}px`}
                zIndex={1}
                display={"grid"}
                gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}
                width={"100vw"}
            >
                {width > 718 ? (
                    <Box pt={hideSummary ? "64px" : 0}>
                        <PhaseLabel />
                    </Box>
                ) : null}

                <Box backgroundColor={"rgba(255, 255, 255, .5)"} gridColumn={2}>
                    {popupId ? null : children}
                </Box>
            </Box>
        </>
    );
};
