export const directMailAverages = {
    postcard: {
        costPerMailer: 0.4094,
        mailerToCall: 0.0022,
        callConversion: 0.4,
        managementFee: 450,
    },
    minizine: {
        costPerMailer: 0.464,
        mailerToCall: 0.0024,
        callConversion: 0.4,
        managementFee: 450,
    },
};
