import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { AddToCart, LearnMore } from "./ProductButtons";
import React from "react";
import { Text } from "./Text";

export const ProductBottomRow = ({ toggleProduct, isAdded, color, backgroundColor, setPopup, learnMoreText }) => (
    <FlexRowAlignCenter
        justifyContent={"space-between"}
        backgroundColor={backgroundColor}
        height={"28px"}
        pl={"12px"}
        pr={"16px"}
    >
        <LearnMore color={color} setPopup={setPopup} learnMoreText={learnMoreText} />
        <AddToCart toggleProduct={toggleProduct} isAdded={isAdded} color={color} />
    </FlexRowAlignCenter>
);

export const HpaBottomRow = () => (
    <FlexRowAlignCenter backgroundColor={"pattensBlue"} height={"28px"} pl={"12px"} pr={"16px"}>
        <Text variant={"label1"} fontWeight={"bold"} color={"nauticBlue"}>
            Speak with your HPA regional manager
        </Text>
    </FlexRowAlignCenter>
);
