import { api } from "../utilityActions";

const createPracticeAndUpdateAccount = (practiceInfo) =>
    api({
        call: {
            url: "/account/createPracticeAndUpdateAccount",
            method: "post",
            data: practiceInfo,
        },
    });

export default createPracticeAndUpdateAccount;
