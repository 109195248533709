import styled from "styled-components";
import { RawGradient, RawReverseGradient, RawScoreGradient } from "./assets";
import { all } from "../styledSystemUtilities";

export const Gradient = styled(RawGradient)`
    width: 100vw;
    height: 11px;
    margin-bottom: 0;
    display: block;
    ${all}
`;

export const ScoreGradient = styled(RawScoreGradient)`
    height: 10px;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    width: 100%;
`;

export const ReverseGradient = styled(RawReverseGradient)`
    height: 10px;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    width: 100%;
`;
