import React from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { Button, Gradient, Input, Text } from "../Atoms";
import { AbsolutePositionLogo } from "../Atoms/AbsolutePositionLogo";
import { FormError } from "../Atoms/FormError";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { routerHistory } from "../../routerHistory";
import { actions } from "../../redux";

const schema = object().shape({
    email: string().required("Please enter your email.").email("Please enter a valid email.").trim(),
    firstName: string().required("Please enter your name.").trim(),
    lastName: string().required("Please enter your name.").trim(),
});

export const EmailLink = () => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (input) => {
        const userName = `${input.firstName} ${input.lastName}`;
        const sessionLink = `${window.origin}?token=${store.sessionId}`;
        dispatch(actions.sendSessionLinkEmail(input, sessionLink));
        dispatch(actions.updateLead({ email: input.email, userName, id: store.report.leadId }));
        routerHistory.push("/sent-link");
    };
    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"70px"} />
                    <Text variant={"header2"} color={"graphiteGray"} textAlign={"center"}>
                        Enter your email:
                    </Text>
                    <Box pb={"20px"} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            data-testid={"firstName"}
                            ref={register}
                            name={"firstName"}
                            placeholder="First Name"
                            hasError={errors.firstName}
                        />
                        <FormError error={errors.firstName} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"lastName"}
                            ref={register}
                            name={"lastName"}
                            placeholder="Last Name"
                            hasError={errors.lastName}
                        />
                        <FormError error={errors.lastName} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"email"}
                            ref={register}
                            name={"email"}
                            placeholder="Email Address"
                            hasError={errors.email}
                        />
                        <FormError error={errors.email} />
                        <Box pb={"26px"} />
                        <Button backgroundColor={"graphiteGray"} onClick={handleSubmit}>
                            send link
                        </Button>
                    </form>
                </Box>
            </Grid>
        </Box>
    );
};
