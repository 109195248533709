import React from "react";
import { Box } from "../styledSystemUtilities";
import { Text } from "./Text";
import { ProductCardSelect } from "./ProductCardSelect";
import { ProductDetails } from "./ProductCard";
import { useCart } from "../../hooks/useCart";
import { useSetPopup } from "../../hooks/useSetPopup";
import { ProductBottomRow } from "./ProductBottomRows";

export const PrintProductCard = ({ product }) => {
    const [isAdded, toggleProduct, updateProduct, watchedProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    return (
        <Box data-testid={watchedProduct.recurlyCode}>
            <ProductDetails
                name={watchedProduct.name}
                description={watchedProduct.description}
                price={watchedProduct.price}
            />
            <Box display={"flex"} justifyContent={"space-between"} backgroundColor={"catskillWhite"} px={"13px"}>
                <Box display={"flex"} alignItems={"center"} height={"46px"}>
                    <Text variant={"label2"} color={"balticBlue"}>
                        Quantity
                    </Text>
                    <Box ml={2} />
                    <Box width={"93px"} data-testid={"quantitySelect"}>
                        <ProductCardSelect
                            onChange={(quantityOption) => {
                                updateProduct({
                                    ...watchedProduct,
                                    price: quantityOption.price,
                                    recurlyCode: quantityOption.recurlyCode,
                                    defaultQuantity: quantityOption,
                                });
                            }}
                            defaultValue={watchedProduct.defaultQuantity}
                            options={watchedProduct.quantities}
                        />
                    </Box>
                    <Box ml={"34px"} />
                </Box>
                <Box width={"132px"} textAlign={"center"}>
                    <Text variant={"label2"} position={"relative"} bottom={"2px"} color={"balticBlue"}>
                        Paper type:
                    </Text>
                    <Box data-testid={"paperTypeSelect"}>
                        <Box
                            display={"flex"}
                            backgroundColor={"white"}
                            height={"25px"}
                            justifyContent={"center"}
                            border={"1px solid #94A5B5"}
                            alignItems={"center"}
                        >
                            <Text variant={"body4"} color={"nauticBlue"}>
                                {watchedProduct.paperType}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ProductBottomRow
                backgroundColor={"pattensBlue"}
                color={"nauticBlue"}
                toggleProduct={toggleProduct}
                isAdded={isAdded}
                setPopup={setPopup}
            />
        </Box>
    );
};
