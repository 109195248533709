import React, { useState } from "react";
import { Box, FlexRow, FlexRowAlignCenter, Grid } from "../styledSystemUtilities";
import { Button, Checkbox, Gradient, Input, Select, Text, WelcomeLogo } from "../Atoms";
import { GoogleMyBusinessLogo } from "../Atoms/assets";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import _ from "lodash/fp";
import { routerHistory } from "../../routerHistory";
import { useDispatch } from "react-redux";
import { FormError } from "../Atoms/FormError";
import { actions, slices } from "../../redux";

const practiceTypeOptions = [
    { value: "cosmeticDentist", label: "Cosmetic Dentist" },
    { value: "pediatricDentist", label: "Pediatric Dentist" },
    { value: "prosthodontist", label: "Prosthodontist" },
    { value: "periodontist", label: "Periodontist" },
    { value: "endodontist", label: "Endodontist" },
    { value: "orthodontist", label: "Orthodontist" },
    { value: "oralSurgeon", label: "Oral Surgeon" },
    { value: "generalDentist", label: "General Dentist" },
];

const shape = {
    false: {
        practiceName: string().required("Please enter your practice name.").trim(),
        zipCode: string().required("Please enter your zipcode.").trim(),
        practiceType: object()
            .shape({
                value: string(),
                label: string(),
            })
            .nullable()
            .required("Please select a practice type."),
    },
    true: {
        practiceName: string().required("Please enter your practice name.").trim(),
        city: string().required("Please enter your city.").trim(),
        provence: string().required("Please enter your provence,").trim(),
        practiceType: object()
            .shape({
                value: string(),
                label: string(),
            })
            .nullable()
            .required("Please select a practice type."),
    },
};

export const Welcome = () => {
    const dispatch = useDispatch();
    const [isCanada, setIsCanada] = useState(false);

    const schema = object().shape(shape[isCanada]);

    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        const formattedData = _.flow(
            _.set("practiceType", _.get("practiceType.value", data)),
            _.set("userName", ""),
            _.set("email", ""),
            _.set("perPatientValue", 1000),
            _.set("isCanada", isCanada),
            _.set("searchArea", isCanada ? `${data.city} ${data.provence}` : data.zipCode)
        )(data);
        dispatch(slices.user.actions.set(formattedData));
        dispatch(actions.getPlaces(formattedData));
        routerHistory.push("/place-select");
    };

    return (
        <Box>
            <Gradient />
            <Grid gridTemplateColumns={"1fr minmax(320px, 340px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"48px"} />
                    <FlexRow justifyContent={"center"}>
                        <WelcomeLogo />
                    </FlexRow>
                    <Box pb={"34px"} />
                    <Text fontSize={"31px"} color={"nauticBlue"} fontFamily={"montserrat"} textAlign={"center"}>
                        Interactive Analysis
                    </Text>
                    <Box pb={"21px"} />
                    <Text color={"graphiteGray"} variant={"body3"} fontWeight={"medium"}>
                        To get started, we need to find your listing with:
                    </Text>
                    <Box pb={"14px"} />
                    <GoogleMyBusinessLogo />
                    <Box pb={"18px"} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {isCanada ? (
                            <>
                                <Input
                                    key={"city"}
                                    data-testid={"city"}
                                    ref={register}
                                    name={"city"}
                                    placeholder="City"
                                    hasError={errors.city}
                                />
                                <FormError error={errors.city} />
                                <Box mb={7} />
                                <Input
                                    data-testid={"provence"}
                                    ref={register}
                                    name={"provence"}
                                    placeholder="Provence"
                                    hasError={errors.provence}
                                />
                                <FormError error={errors.provence} />
                            </>
                        ) : (
                            <>
                                <Input
                                    key={"zip"}
                                    data-testid={"zipCode"}
                                    ref={register}
                                    name={"zipCode"}
                                    placeholder="Zip Code"
                                    hasError={errors.zipCode}
                                />
                                <FormError error={errors.zipCode} />
                            </>
                        )}
                        <Box mb={7} />
                        <Input
                            data-testid={"practiceName"}
                            ref={register}
                            name={"practiceName"}
                            placeholder="Practice Name"
                            hasError={errors.practiceName}
                        />
                        <FormError error={errors.practiceName} />
                        <Box mb={7} />
                        <Controller
                            name="practiceType"
                            control={control}
                            defaultValue={"generalDentist"}
                            render={({ onChange, value }) => (
                                <Select
                                    options={practiceTypeOptions}
                                    classNamePrefix={"practiceType"}
                                    onChange={onChange}
                                    value={value}
                                    placeholder={"Type of practice"}
                                    hasError={errors.practiceType}
                                />
                            )}
                        />
                        <FormError error={errors.practiceType} />
                        <Box pb={"10px"} />
                        <FlexRowAlignCenter>
                            <Checkbox
                                fill={"sienna"}
                                isChecked={isCanada}
                                onClick={() => setIsCanada((prevState) => !prevState)}
                            />
                            <Box pl={"12px"} />
                            <Text variant={"body4"}>My practice is in Canada</Text>
                        </FlexRowAlignCenter>
                        <Box mb={7} />
                        <Text variant={"body4"}>
                            • Your Google My Business listing allows us to gather important public data to analyze your
                            needs.
                        </Text>
                        <Box mb={7} />
                        <Button backgroundColor={"graphiteGray"} onClick={handleSubmit}>
                            find your listing
                        </Button>
                        <Box pb={12} />
                    </form>
                </Box>
            </Grid>
        </Box>
    );
};
