import React from "react";
import { Box } from "../../styledSystemUtilities";
import { Insta, RawFacebook, RawGoogle, SocialSeoHero, Twitter } from "../../Atoms/assets";
import {
    ExternalLink,
    SeoContent,
    SeoOverTimeSample,
    SeoPlotSample,
    SeoTableSample,
    Strategy,
    Text,
} from "../../Atoms";
import {
    GrayBackground,
    IconContainer,
    NumberedParagraph,
    OnlineHeader,
    Paragraph,
    PricingNotes,
    ReportingCard,
    ReportingHeader,
    SellingPoint,
} from "../../Molecules";

export const SocialForSeo = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <OnlineHeader setPopup={setPopup} Hero={SocialSeoHero} />
            <GrayBackground>
                <Box display={"flex"} position={"relative"} top={"-21px"}>
                    <RawFacebook />
                    <Box ml={9} />
                    <RawGoogle style={{ fill: "#74AD85" }} />
                    <Box ml={9} />
                    <Insta />
                    <Box ml={9} />
                    <Twitter />
                </Box>
                <SellingPoint
                    header={"Keyword rich content in key places"}
                    body={
                        "We post keyword-rich content and external links to your website to help improve your online relevancy. This includes Facebook, Google My Business, Instagram, and Twitter."
                    }
                />
                <SellingPoint
                    header={"Coordinated with SEO, web and advertising"}
                    body={
                        "As an extension of the SEO process, we ensure that the keywords, links, and strategy are aligned with all other online efforts. This helps improve your ranking in top searches."
                    }
                />
                <SellingPoint
                    header={"Weekly content, tailored to you"}
                    body={
                        "We work with your overall growth strategy and can even include custom photos if you would like."
                    }
                />
                <IconContainer name={"Technology"}>
                    <Strategy name={"Content Strategy"} fill={"auratiumGreen"} />
                    <ExternalLink name={"External Link Building"} />
                    <Box />
                    <SeoContent name={"Content Creation"} />
                </IconContainer>
                <ReportingHeader product={"Social for SEO"} color={"auratiumGreen"} />
            </GrayBackground>
            <ReportingCard>
                <NumberedParagraph
                    header={"1. Top keyword averages"}
                    body={"Where do you rank on average for the most searched keywords?"}
                />
                <Paragraph>
                    80% of local search for dentists can be narrowed down to a handful of top keywords. Monitoring where
                    you rank for these top keywords over time is the best indicator of meaningful improvement. Because
                    this type of reporting holds the SEO company to a higher standard, most SEO companies choose to
                    report long lists of varied keywords that make the SEO company look good but are unclear in
                    conveying your position.
                </Paragraph>
                <SeoOverTimeSample />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"Are you doing enough to pass the competition?"}
                    header={"2. Competitive Marketplace"}
                />
                <Paragraph>
                    Local ranking is based on how Google sees you vs. how they see your competition. To improve, you
                    must outperform your competition. Therefore, understanding how competitive your area is and where
                    you sit in regards to those ranking above you is critical to devise a strategy to move you up. Our
                    reporting shows total competitors, where you sit in the landscape and identifies the top competitors
                    in your area.
                </Paragraph>
                <SeoPlotSample />
                <Text variant={"body3"} fontWeight={"medium"} color={"auratiumGreen"} mb={7}>
                    Your Market’s Top 5 Competitors & You
                </Text>
                <SeoTableSample />
            </ReportingCard>
            <PricingNotes>
                Essential SEO is purchased on a subscription basis and requires 30 days notice to cancel.
            </PricingNotes>
        </Box>
    );
};
