export const STANDARD_WEBSITE = {
    id: 1,
    name: "Essential Website",
    recurlyCode: "webe-01",
    type: "online",
    monthlyPrice: 329,
    description: "A website, only better.",
    secondaryDescription: "Includes hosting and email.",
    recommendationLevel: 0.1,
    recommendationLevelCutoff: 1,
    highNewPatientsProjection: 4,
    popup: "essentialWebsite",
    tasks: [
        { key: "domain", name: "Domain Name Info" },
        { key: "website", name: "Website Questionnaire" },
    ],
};

export const REVIEW_GENERATION = {
    id: 3,
    recurlyCode: "revg-01",
    name: "Review Generation",
    type: "online",
    monthlyPrice: 239,
    description: "Share the love.",
    secondaryDescription: "Google focused, automated.",
    recommendationLevel: 0.1,
    recommendationLevelCutoff: 1,
    highNewPatientsProjection: 2,
    popup: "reviewGeneration",
    tasks: [],
};

export const ESSENTIAL_SEO = {
    id: 4,
    name: "Essential SEO",
    recurlyCode: "seoe-01",
    type: "online",
    monthlyPrice: 529,
    description: "Ensure relevancy. Improve ranking.",
    secondaryDescription: "The best in ongoing optimization.",
    recommendationLevel: 0.1,
    recommendationLevelCutoff: 0.7,
    highNewPatientsProjection: 4,
    popup: "essentialSeo",
    tasks: [{ key: "seo", name: "SEO Setup" }],
};

export const ENHANCED_SEO = {
    id: 5,
    recurlyCode: "seoe-02",
    name: "Enhanced SEO",
    type: "online",
    monthlyPrice: 1039,
    description: "When more is needed.",
    secondaryDescription: "Our most robust monthly optimization.",
    recommendationLevel: 0.7,
    recommendationLevelCutoff: 1,
    highNewPatientsProjection: 7,
    popup: "enhancedSeo",
    tasks: [{ key: "seo", name: "SEO Setup" }],
};

export const SOCIAL_FOR_SEO = {
    id: 6,
    recurlyCode: "soci-01",
    name: "Social Media for SEO",
    type: "online",
    monthlyPrice: 499,
    description: "A social solution to improve ranking.",
    secondaryDescription: "Weekly posts designed to improve SEO.",
    recommendationLevel: 0.9,
    recommendationLevelCutoff: 1,
    highNewPatientsProjection: 2,
    popup: "socialForSeo",
    tasks: [{ key: "socialMedia", name: "Social Media Channel Access" }],
};

export const ENHANCED_WEBSITE = {
    id: 7,
    recurlyCode: "webe-02",
    name: "Website Enhancer",
    type: "online",
    monthlyPrice: 59,
    description: "Potential. Multiplied.",
    secondaryDescription: "More robust keyword content.",
    recommendationLevel: 0.8,
    recommendationLevelCutoff: 1,
    highNewPatientsProjection: 2,
    popup: "enhancedWebsite",
    tasks: [],
};

export const MY_SOCIAL_PRACTICE_SELF = {
    id: 41,
    recurlyCode: "msop-01",
    name: "Social Media – Self Management",
    type: "online",
    monthlyPrice: 299,
    description: "Engage, Interact and Educate",
    recommendationLevel: 1.1,
    recommendationLevelCutoff: 1,
    highNewPatientsProjection: 2,
    popup: "mySocialPracticeSelf",
    tasks: [],
};

export const MY_SOCIAL_PRACTICE_PRO = {
    id: 42,
    recurlyCode: "msop-02",
    name: "Social Media – Pro Management",
    type: "online",
    monthlyPrice: 399,
    description: "Engage, Interact and Educate",
    recommendationLevel: 1.1,
    recommendationLevelCutoff: 1,
    highNewPatientsProjection: 2,
    popup: "mySocialPracticePro",
    tasks: [],
};

export const PAY_PER_CLICK = {
    id: 8,
    recurlyCode: "gooa-01",
    name: "Google Ads",
    type: "growth",
    managementFee: 589,
    description: "Controlled growth.",
    callTrackingFee: 60,
    adSpend: 0,
    conversionRate: 0.13,
    callConversionRate: 0.23,
    popup: "googleAds",
    tasks: [{ key: "googleAds", name: "Google Ads Setup" }],
};

export const DIRECT_MAIL_POSTCARD = {
    id: 9,
    name: "Direct Mail - Postcard",
    recurlyCode: "dmpc-01",
    description: "Build your brand and patient base.",
    type: "growth",
    popup: "postcard",
    tasks: [],
};

export const DIRECT_MAIL_MINIZINE = {
    id: 10,
    recurlyCode: "dmmz-01",
    name: "Direct Mail - Mini-Zine",
    description: "Expansive. Distinctive. Effective.",
    type: "growth",
    popup: "minizine",
    tasks: [],
};

export const BRAND_DEFINITION = {
    id: 11,
    recurlyCode: "bran-01",
    name: "Brand Definition",
    type: "profit",
    price: 2599,
    description: "Your practice, defined.",
    popup: "brandDefinition",
    productCard: "productCard",
    checkoutCard: "checkoutCard",
    tasks: [{ key: "brand", name: "Brand Questionnaire" }],
};

export const LOGO_DESIGN = {
    id: 12,
    recurlyCode: "logo-01",
    name: "Logo Design",
    type: "profit",
    price: 1699,
    description: "A professionally designed logo",
    popup: "logoDesign",
    productCard: "productCard",
    checkoutCard: "checkoutCard",
    tasks: [],
};

export const INSURANCE_MANAGEMENT = {
    id: 13,
    recurlyCode: "imgt-01",
    name: "1 Yr. Insurance Management",
    type: "profit",
    price: 5988,
    description: "Reimbursement, maximized.",
    popup: "insuranceManagement",
    productCard: "productCard",
    checkoutCard: "checkoutCard",
    tasks: [],
    logo: "unitas",
};

export const PHONE_SKILLS = {
    id: 14,
    name: "Phone Skills Training",
    recurlyCode: "tpho-01",
    type: "profit",
    description: 'Have them at "Hello."',
    price: 889,
    defaultValue: { value: 1, label: "Level 1", price: 889, recurlyCode: "tpho-01" },
    options: [
        { value: 1, label: "Level 1", price: 889, recurlyCode: "tpho-01" },
        { value: 2, label: "Level 2", price: 4999, recurlyCode: "tpho-02" },
    ],
    popup: "phoneSkillsTraining",
    productCard: "lionSpeakProductCard",
    checkoutCard: "lionSpeakCheckoutCard",
    tasks: [],
};

export const PATIENT_REFERRAL = {
    id: 15,
    name: "Patient Referral Training",
    recurlyCode: "tref-01",
    type: "profit",
    description: "Expand your sales force.",
    price: 999,
    defaultValue: { value: 1, label: "Level 1", price: 999, recurlyCode: "tref-01" },
    options: [
        { value: 1, label: "Level 1", price: 999, recurlyCode: "tref-01" },
        { value: 2, label: "Level 2", price: 2199, recurlyCode: "tref-02" },
    ],
    popup: "patientReferralTraining",
    productCard: "lionSpeakProductCard",
    checkoutCard: "lionSpeakCheckoutCard",
    tasks: [],
};

export const CASE_ACCEPTANCE = {
    id: 16,
    name: "Case Acceptance Training",
    recurlyCode: "tcas-01",
    type: "profit",
    description: "Sell value, increase profits.",
    price: 999,
    defaultValue: { value: 1, label: "Level 1", price: 999, recurlyCode: "tcas-01" },
    options: [
        { value: 1, label: "Level 1", price: 999, recurlyCode: "tcas-01" },
        { value: 2, label: "Level 2", price: 2199, recurlyCode: "tcas-02" },
        { value: 3, label: "Level 3", price: 2999, recurlyCode: "tcas-03" },
    ],
    popup: "caseAcceptanceTraining",
    productCard: "lionSpeakProductCard",
    checkoutCard: "lionSpeakCheckoutCard",
    tasks: [],
};

export const ANCILLARY_SERVICES = {
    id: 17,
    name: "Ancillary Services Training",
    recurlyCode: "tanc-01",
    type: "profit",
    description: "Sell more of the profitable stuff.",
    price: 999,
    defaultValue: { value: 1, label: "Level 1", price: 999, recurlyCode: "tanc-01" },
    options: [
        { value: 1, label: "Level 1", price: 999, recurlyCode: "tanc-01" },
        { value: 2, label: "Level 2", price: 2199, recurlyCode: "tanc-02" },
        { value: 3, label: "Level 3", price: 2999, recurlyCode: "tanc-03" },
    ],
    popup: "ancillaryServicesTraining",
    productCard: "lionSpeakProductCard",
    checkoutCard: "lionSpeakCheckoutCard",
    tasks: [],
};

export const REACTIVATION = {
    id: 18,
    name: "Reactivation Training",
    recurlyCode: "trea-01",
    type: "profit",
    description: "They miss you too.",
    price: 999,
    defaultValue: { value: 1, label: "Level 1", price: 999, recurlyCode: "trea-01" },
    options: [
        { value: 1, label: "Level 1", price: 999, recurlyCode: "trea-01" },
        { value: 2, label: "Level 2", price: 2199, recurlyCode: "trea-02" },
    ],
    popup: "reactivationTraining",
    productCard: "lionSpeakProductCard",
    checkoutCard: "lionSpeakCheckoutCard",
    tasks: [],
};

export const BUSINESS_CARDS = {
    id: 19,
    name: "Business Cards",
    recurlyCode: "busc-02",
    type: "profit",
    key: "businessCards",
    description: "Complete the look.",
    price: 609,
    defaultQuantity: { value: 500, label: "500", price: 609, recurlyCode: "busc-02" },
    paperType: "Uncoated",
    quantities: [
        { value: 500, label: "500", price: 609, recurlyCode: "busc-02" },
        { value: 1000, label: "1000", price: 629, recurlyCode: "busc-03" },
    ],
    popup: "businessCards",
    productCard: "printProductCard",
    checkoutCard: "printCheckoutCard",
    tasks: [],
};

export const ENVELOPES = {
    id: 20,
    name: "Envelopes",
    recurlyCode: "enve-02",
    type: "profit",
    key: "envelopes",
    description: "Your brand matters.",
    price: 699,
    defaultQuantity: { value: 500, label: "500", price: 699, recurlyCode: "enve-02" },
    paperType: "Uncoated",
    quantities: [
        { value: 500, label: "500", price: 699, recurlyCode: "enve-02" },
        { value: 1000, label: "1000", price: 729, recurlyCode: "enve-03" },
    ],
    popup: "envelopes",
    productCard: "printProductCard",
    checkoutCard: "printCheckoutCard",
    tasks: [],
};

export const LETTERHEAD = {
    id: 21,
    name: "Letterhead",
    recurlyCode: "lett-02",
    type: "profit",
    key: "letterhead",
    description: "The difference is in the details.",
    designUnitCost: 550,
    price: 650,
    defaultQuantity: { value: 500, label: "500", price: 650, recurlyCode: "lett-02" },
    paperType: "Uncoated",
    quantities: [
        { value: 500, label: "500", price: 650, recurlyCode: "lett-02" },
        { value: 1000, label: "1000", price: 709, recurlyCode: "lett-03" },
    ],
    popup: "letterhead",
    productCard: "printProductCard",
    checkoutCard: "printCheckoutCard",
    tasks: [],
};

export const PROMOTIONAL_FLYERS = {
    id: 26,
    name: "Promotional Flyers",
    recurlyCode: "prof-02",
    type: "profit",
    key: "promotionalFlyers",
    description: "Keep them educated.",
    designUnitCost: 550,
    price: 1349,
    defaultQuantity: { value: 500, label: "500", price: 1349, recurlyCode: "prof-02" },
    paperType: "Gloss",
    quantities: [
        { value: 500, label: "500", price: 1349, recurlyCode: "prof-02" },
        { value: 1000, label: "1000", price: 1399, recurlyCode: "prof-03" },
    ],
    popup: "promotionalFlyers",
    productCard: "printProductCard",
    checkoutCard: "printCheckoutCard",
    tasks: [],
};

export const PROMOTIONAL_CARDS = {
    id: 27,
    name: "Promotional Cards",
    recurlyCode: "proc-02",
    type: "profit",
    key: "promotionalCards",
    description: "Do you want fries with that?",
    designUnitCost: 550,
    price: 1239,
    defaultQuantity: { value: 500, label: "500", price: 1239, recurlyCode: "proc-02" },
    paperType: "Gloss",
    quantities: [
        { value: 500, label: "500", price: 1239, recurlyCode: "proc-02" },
        { value: 1000, label: "1000", price: 1299, recurlyCode: "proc-03" },
    ],
    popup: "promotionalCards",
    productCard: "printProductCard",
    checkoutCard: "printCheckoutCard",
    tasks: [],
};

export const PATIENT_REFERRAL_CARDS = {
    id: 28,
    name: "Patient Referral Cards",
    recurlyCode: "refc-02",
    type: "profit",
    key: "patientReferralCards",
    description: "Seize the opportunity.",
    price: 759,
    defaultQuantity: { value: 1000, label: "1000", price: 759, recurlyCode: "refc-02" },
    paperType: "Uncoated",
    quantities: [
        { value: 1000, label: "1000", price: 759, recurlyCode: "refc-02" },
        { value: 2000, label: "2000", price: 889, recurlyCode: "refc-03" },
    ],
    popup: "patientReferralCards",
    productCard: "printProductCard",
    checkoutCard: "printCheckoutCard",
    tasks: [],
};

export const ANCILLARY_BROCHURE = {
    id: 29,
    name: "Ancillary Services Brochure",
    recurlyCode: "ansb-02",
    type: "profit",
    key: "triFoldBrochure",
    description: "À la carte menu of ancillary services",
    price: 1349,
    defaultQuantity: { value: 500, label: "500", price: 1349, recurlyCode: "ansb-02" },
    paperType: "Gloss",
    quantities: [
        { value: 500, label: "500", price: 1349, recurlyCode: "ansb-02" },
        { value: 1000, label: "1000", price: 1449, recurlyCode: "ansb-03" },
    ],
    popup: "ancillaryServicesBrochure",
    productCard: "printProductCard",
    checkoutCard: "printCheckoutCard",
    tasks: [],
};

export const PRACTICE_VIDEO = {
    id: 31,
    recurlyCode: "vide-01",
    name: "Practice Video",
    type: "profit",
    price: 9400,
    description: "A professionally designed logo",
    popup: "logoDesign",
    productCard: "productCard",
    checkoutCard: "checkoutCard",
    tasks: [],
};

export const REACTIVATION_BURKHART = {
    id: 32,
    name: "Reactivation Training",
    recurlyCode: "bhre-01",
    type: "profit",
    description: "They miss you too.",
    price: 0,
    popup: "reactivationBurkhart",
    productCard: "burkhartProductCard",
    checkoutCard: "burkhartCheckoutCard",
    tasks: [],
};

export const PHONE_SKILLS_BURKHART = {
    id: 33,
    name: "Phone Skills Training",
    recurlyCode: "bhps-01",
    type: "profit",
    description: 'Have them at "Hello."',
    price: 0,
    popup: "phoneSkillsBurkhart",
    productCard: "burkhartProductCard",
    checkoutCard: "burkhartCheckoutCard",
    tasks: [],
};

export const PATIENT_REFERRAL_BURKHART = {
    id: 34,
    name: "Patient Referral Training",
    recurlyCode: "bhpr-01",
    type: "profit",
    description: "Enhance internal marketing",
    price: 0,
    popup: "referralsBurkhart",
    productCard: "burkhartProductCard",
    checkoutCard: "burkhartCheckoutCard",
    tasks: [],
};

export const ANCILLARY_SERVICES_BURKHART = {
    id: 35,
    name: "Ancillary Services Training",
    recurlyCode: "bhas-01",
    type: "profit",
    description: "More profitable treatment.",
    price: 0,
    popup: "ancillaryBurkhart",
    productCard: "burkhartProductCard",
    checkoutCard: "burkhartCheckoutCard",
    tasks: [],
};

export const CASE_ACCEPTANCE_BURKHART = {
    id: 36,
    name: "Case Acceptance Training",
    recurlyCode: "bhca-01",
    type: "profit",
    description: "Sell value, increase profits.",
    price: 0,
    popup: "caseAcceptanceBurkhart",
    productCard: "burkhartProductCard",
    checkoutCard: "burkhartCheckoutCard",
    tasks: [],
};

export const ONE_YEAR_E_DENTAL = {
    id: 37,
    recurlyCode: "oyed-01",
    name: "Increase PPO Revenue",
    type: "profit",
    price: "$5,396",
    subPrice: "1 Location, 1 Dr.",
    description: "Reimbursement, maximized.",
    popup: "oneYearEDent",
    productCard: "eDentProductCard",
    tasks: [],
};

export const PRO_E_DENTAL = {
    id: 38,
    recurlyCode: "pred-01",
    name: "Plan Pro In-House Savings Plan",
    type: "profit",
    price: "$499 Set-up",
    subPrice: "$149/mo.",
    description: "Maximize revenue.",
    popup: "proEDent",
    productCard: "eDentProductCard",
    tasks: [],
};

export const PREMIUM_DENTAL_MENU = {
    id: 39,
    recurlyCode: "menu-01",
    name: "Premium Dental Plan",
    type: "profit",
    price: 299,
    description: "Increase conversion and retention.",
    popup: "premiumDentalMenu",
    productCard: "productCard",
    checkoutCard: "checkoutCard",
    subPrice: "+ Monthly",
    learnMoreText: "pricing & description",
    tasks: [],
    logo: "dentalMenu",
};

export const STANDARD_DENTAL_MENU = {
    id: 40,
    recurlyCode: "menu-02",
    name: "Standard Dental Plan",
    type: "profit",
    price: 99,
    description: "Increase conversion and retention.",
    popup: "standardDentalMenu",
    productCard: "productCard",
    checkoutCard: "checkoutCard",
    subPrice: "+ Monthly",
    learnMoreText: "pricing & description",
    tasks: [],
    logo: "dentalMenu",
};

export const HPA_INSURANCE_MANAGEMENT = {
    id: 43,
    recurlyCode: "hpin-01",
    name: "Insurance Contracting",
    type: "profit",
    description: "Reimbursement, maximized.",
    productCard: "productCard",
    price: 0,
    tasks: [],
    logo: "hpa",
    bottomRow: "hpa",
};

export const HPA_MEMBERSHIP_PROGRAM = {
    id: 44,
    recurlyCode: "hpmp-02",
    name: "Dental Membership Program",
    type: "profit",
    description: "Increase conversion and retention.",
    productCard: "productCard",
    price: 0,
    tasks: [],
    logo: "hpa",
    bottomRow: "hpa",
};
