import { useCallback, useState } from "react";

export const useCallbackRef = (coupon) => {
    const [position, setPosition] = useState(0);

    const ref = useCallback(
        (node) => {
            if (node !== null) {
                if (coupon) {
                    setPosition(node.getBoundingClientRect().top + window.scrollY);
                } else {
                    setPosition(node.getBoundingClientRect().top + window.scrollY);
                }
            }
        },
        [coupon]
    );

    return [ref, position];
};
