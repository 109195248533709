import styled from "styled-components";
import { variant } from "styled-system";
import { all } from "../styledSystemUtilities";

const header = {
    fontFamily: "montserrat",
    fontWeight: "regular",
};

export const Text = styled("div")(
    ({ theme }) => ({
        fontWeight: theme.fontWeights.regular,
        margin: 0,
        overflowWrap: "break-word",
        color: theme.colors.agateGray,
    }),
    variant({
        variants: {
            giantHeader: {
                fontFamily: "roboto",
                fontWeight: "thin",
            },
            giantNumber: {
                fontFamily: "montserrat",
                fontWeight: "semiBold",
                opacity: 0.3,
            },
            score: {
                fontFamily: "roboto",
                fontWeight: "medium",
                fontSize: 3,
            },
            header1: {
                ...header,
                fontSize: 9,
                color: "white",
            },
            header2: {
                ...header,
                fontSize: 8,
                as: "h1",
            },
            header3: {
                fontFamily: "roboto",
                fontSize: 7,
                fontWeight: "medium",
            },
            body1: {
                fontFamily: "roboto",
                fontSize: 6,
            },
            body2: {
                fontFamily: "roboto",
                fontSize: 5,
            },
            body3: {
                fontFamily: "roboto",
                fontSize: 4,
                lineHeight: "20px",
            },
            body4: {
                fontFamily: "roboto",
                fontSize: 3,
            },
            label1: {
                fontFamily: "roboto",
                fontSize: 2,
            },
            label2: {
                fontFamily: "roboto",
                fontSize: 1,
            },
            label3: {
                fontFamily: "roboto",
                fontSize: 0,
            },
        },
    }),
    all
);
