import { api, hydrateState, historyPush } from "../utilityActions";
import slices from "../slices";

const getSession = (sessionId) =>
    api({
        call: {
            url: "/getSession",
            method: "get",
            params: {
                sessionId,
            },
        },
        success: (data) => [hydrateState(data), slices.sessionId.actions.set(sessionId), historyPush("/cart")],
        failure: () => historyPush("/"),
    });

export default getSession;
