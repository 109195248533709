import React from "react";
import { Box } from "../../styledSystemUtilities";
import { BluePhone, Pdf } from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { BurkhartHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const ReactivationBurkhart = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <BurkhartHeader setPopup={setPopup} name={"Reactivation"} tagline={"They miss you too."} />
            <GrayBackground>
                <SellingPoint
                    header={"Old patients can be your new patients."}
                    body={
                        "When approached correctly, you’d be surprised at how many of your inactive patients are ready to come back. We can show you the right way to engage them for maximum results."
                    }
                />
            </GrayBackground>
            <WhiteBackground>
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={BluePhone}
                    header={"30 Minute Coaching Call"}
                    body={"Key to our customized approach"}
                />
                <IconBulletPoint
                    Icon={Pdf}
                    header={"PDF Scripting and Resources"}
                    body={"Supports team implementation"}
                />
            </WhiteBackground>
        </Box>
    );
};
