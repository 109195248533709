import { Box } from "../styledSystemUtilities";
import theme from "../../theme";
import React from "react";

export const LeftBanner = ({ frontColor, backColor, hideBackgroundImage }) => {
    return (
        <Box position={"relative"} height={"32px"} display={"flex"} justifyContent={"flex-end"} flexGrow={1}>
            {hideBackgroundImage ? (
                <Box position={"absolute"} height={"100%"} width={"100%"} zIndex={1} backgroundColor={"white"} />
            ) : null}
            <Box
                position={"absolute"}
                height={"9px"}
                width={"100%"}
                zIndex={2}
                mt={"14px"}
                backgroundColor={backColor}
            />
            <Box
                position={"absolute"}
                height={"9px"}
                width={"100%"}
                zIndex={2}
                mt={"23px"}
                backgroundColor={frontColor}
            />
            <Box
                position={"absolute"}
                height={"100%"}
                width={"20px"}
                background={`linear-gradient(110deg, transparent 37%, ${theme.colors[frontColor]} 37% 100%)}`}
                zIndex={3}
            />
        </Box>
    );
};

export const RightBanner = ({ frontColor, backColor }) => {
    return (
        <Box flexGrow={1} height={"32px"}>
            <Box height={"9px"} backgroundColor={frontColor} />
            <Box height={"23px"} backgroundColor={backColor} />
        </Box>
    );
};
