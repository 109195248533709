import { api } from "../utilityActions";
import slices from "../slices";
import actions from "./index";

const createSession = () => (dispatch, getState) => {
    const store = getState();
    dispatch(
        api({
            call: {
                url: "/createSession",
                method: "post",
                data: store,
            },
            success: (sessionId) => [
                slices.sessionId.actions.set(sessionId),
                actions.updateLead({ sessionLink: `${window.origin}?token=${sessionId}`, id: store.report.leadId }),
            ],
        })
    );
};

export default createSession;
