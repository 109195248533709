import React from "react";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { useSetPopup } from "../../hooks/useSetPopup";
import { Text } from "./Text";
import { EDentalLogo } from "./assets";
import { LearnMore } from "./ProductButtons";

export const EDentalProductCard = ({ product }) => {
    const setPopup = useSetPopup(product.popup);

    return (
        <Box data-testid={product.recurlyCode}>
            <FlexRowAlignCenter backgroundColor={"catskillWhite"} justifyContent={"center"} py={3}>
                <EDentalLogo />
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                px={"13px"}
                py={"6px"}
                backgroundColor={"catskillWhite"}
            >
                <Box>
                    <Text variant={"body3"} color={"nauticBlue"}>
                        {product.name}
                    </Text>
                    <Box pb={"1px"} />
                    <Text variant={"label1"} color={"balticBlue"}>
                        {product.description}
                    </Text>
                </Box>
                <Box>
                    <Text variant={"body3"} textAlign={"right"}>
                        {product.price}
                    </Text>
                    <Text variant={"body3"} textAlign={"right"}>
                        {product.subPrice}
                    </Text>
                </Box>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                backgroundColor={"pattensBlue"}
                height={"28px"}
                pl={"12px"}
                pr={"16px"}
            >
                <LearnMore color={"nauticBlue"} setPopup={setPopup} />
            </FlexRowAlignCenter>
        </Box>
    );
};
