import styled from "styled-components";
import { all } from "../styledSystemUtilities";

export const Input = styled.input`
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #e6e7e8;
    border-radius: 0;
    width: 100%;
    height: 50px;
    color: ${(props) => (props.hasError ? "#E27867" : "#7F7F7F")};
    font-size: 14px;
    padding-left: 20px;
    ::placeholder {
        color: ${(props) => (props.hasError ? "#E27867" : "#A7A9AC")};
    }
    :focus {
        outline: none;
    }
    ${all}
`;

export const HalfInput = styled.input`
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #cccccc;
    border-radius: 0;
    width: 100%;
    height: 50px;
    color: #343434;
    font-size: 14px;
    padding-left: 0;
    text-align: center;
    ::placeholder {
        color: #a7a9ac;
    }
    :focus {
        outline: none;
    }
    :focus::placeholder {
        color: transparent;
    }
    ${all}
`;
