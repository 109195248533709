import slices from "../slices";
import { api } from "../utilityActions";

const getPlaces = (input) =>
    api({
        call: {
            url: "/getPlaces",
            method: "post",
            data: input,
        },
        success: slices.places.actions.set,
    });
export default getPlaces;
