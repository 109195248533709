import {
    ANCILLARY_BROCHURE,
    ANCILLARY_SERVICES,
    BRAND_DEFINITION,
    BUSINESS_CARDS,
    CASE_ACCEPTANCE,
    ENVELOPES,
    HPA_INSURANCE_MANAGEMENT,
    HPA_MEMBERSHIP_PROGRAM,
    LETTERHEAD,
    LOGO_DESIGN,
    PATIENT_REFERRAL,
    PATIENT_REFERRAL_CARDS,
    PHONE_SKILLS,
    PRACTICE_VIDEO,
    PROMOTIONAL_CARDS,
    PROMOTIONAL_FLYERS,
    REACTIVATION,
} from "./productList";

export const hpaProfitOptions = [
    {
        option: "perceptions",
        label: "Influence Perceptions",
        shortLabel: "Perceptions",
        subtitle: "Improve and unify your brand",
        products: [BRAND_DEFINITION, LOGO_DESIGN, PRACTICE_VIDEO],
        printOptions: [BUSINESS_CARDS, ENVELOPES, LETTERHEAD, PROMOTIONAL_FLYERS],
    },
    {
        option: "conversion",
        label: "Improve Phone Conversion",
        shortLabel: "Phone Skills",
        subtitle: "The most important skill in practice marketing. ",
        products: [PHONE_SKILLS],
    },
    {
        option: "referrals",
        label: "Increase Patient Referrals",
        shortLabel: "Referrals",
        subtitle: "The most effective way to bring in new patients. ",
        products: [PATIENT_REFERRAL, PATIENT_REFERRAL_CARDS],
    },
    {
        option: "ancillary",
        label: "Sell More Ancillary Services",
        shortLabel: "Ancillary",
        subtitle: "Mouth guards, whitening, sleep apnea, cosmetic, electric toothbrushes, Botox, etc. ",
        products: [ANCILLARY_SERVICES, ANCILLARY_BROCHURE, PROMOTIONAL_CARDS],
    },
    {
        option: "acceptance",
        label: "Improve Case Acceptance",
        shortLabel: "Case Acceptance",
        subtitle: "Increase per patient value.",
        products: [CASE_ACCEPTANCE],
    },
    {
        option: "reactivation",
        label: "Reactivate Patients",
        shortLabel: "Reactivation",
        subtitle: "It’s more effective than finding new ones.",
        products: [REACTIVATION],
    },
    {
        option: "reimbursement",
        label: "Improve Reimbursement Rates",
        shortLabel: "Insurance",
        subtitle: "Get the most out of your insurance relationship.",
        products: [HPA_INSURANCE_MANAGEMENT, HPA_MEMBERSHIP_PROGRAM],
    },
];
