import { Box } from "../styledSystemUtilities";
import { ReviewsBoxLabel, Text } from "../Atoms";
import { DotLabel, ReviewsRankingPlot } from "../Molecules";
import { Chart } from "./Chart";
import React from "react";

export const ReviewsChartCard = ({ reviewsChartData }) => {
    return (
        <Box px={9} pb={7} pt={5} display={"flex"} flexDirection={"column"} backgroundColor={"white"}>
            <Text variant={"body3"} fontWeight={"medium"} color={"auratiumGreen"}>
                Google Reviews Ranking
            </Text>
            <Box mb={3} />
            <Box display={"flex"} alignItems={"center"}>
                <DotLabel fill={"sienna"}>Your Current Rank</DotLabel>
                <Box ml={7} />
                <DotLabel fill={"auratiumGreen"}>Top Competitor</DotLabel>
                <Box ml={7} />
                <DotLabel fill={"slateGray"}>Other Competitors</DotLabel>
            </Box>
            <Chart
                xLabel={"Number of Google Reviews"}
                yLabel={"Average rating"}
                renderPlot={() => <ReviewsRankingPlot data={reviewsChartData} />}
            />
            <Box mb={6} />
            <Box display={"flex"} justifyContent={"center"}>
                <ReviewsBoxLabel fill={"seaGreen"} opacity={".6"}>
                    POOR
                </ReviewsBoxLabel>
                <Box ml={14} />
                <ReviewsBoxLabel fill={"seaGreen"} opacity={".17"}>
                    AVERAGE
                </ReviewsBoxLabel>
                <Box ml={14} />
                <ReviewsBoxLabel fill={"white"}>IDEAL</ReviewsBoxLabel>
            </Box>
        </Box>
    );
};
