import React from "react";
import { Text } from "../Atoms";
import { Box } from "../styledSystemUtilities";
import { phases } from "../../constants/phases";

export const PhaseLabel = ({ textColor, numberColor, text, number }) => {
    return (
        <Box position={"fixed"}>
            <Box position={"relative"} top={"-18px"} left={"56px"}>
                <Text variant={"giantNumber"} fontSize={"200px"} color={numberColor}>
                    {number}
                </Text>
            </Box>

            <Box
                position={"relative"}
                top={"-160px"}
                left={"-26px"}
                zIndex={-1}
                writingMode={"vertical-rl"}
                textOrientation={"mixed"}
            >
                <Text variant={"giantHeader"} fontSize={"100px"} color={textColor}>
                    {text}
                </Text>
            </Box>
        </Box>
    );
};

export const OnlinePhaseLabel = () => {
    return <PhaseLabel text={"Online"} number={"1"} textColor={"auratiumGreen"} numberColor={"seaGreen"} />;
};
export const GrowthPhaseLabel = () => {
    return <PhaseLabel text={"Growth"} number={"2"} textColor={"petrolBlue"} numberColor={"ipanemaBlue"} />;
};
export const ProfitPhaseLabel = () => {
    return <PhaseLabel text={"Profit"} number={"3"} textColor={"nauticBlue"} numberColor={"balticBlue"} />;
};

export const MobilePhaseLabel = ({ phaseName }) => {
    const leftPosition = phases[phaseName].phasePageLeftPosition + "px";
    const numberPosition = phases[phaseName].phasePageNumberPosition + "px";

    return (
        <Box height={"130px"}>
            <Box position={"relative"} left={numberPosition}>
                <Text
                    variant={"giantNumber"}
                    lineHeight={"100px"}
                    fontSize={"140px"}
                    color={phases[phaseName].frontColor}
                >
                    {phases[phaseName].number}
                </Text>
            </Box>

            <Box position={"relative"} top={"-106px"} zIndex={-1} width={"290px"} left={leftPosition}>
                <Text variant={"giantHeader"} fontSize={"90px"} color={phases[phaseName].backColor}>
                    {phases[phaseName].name}
                </Text>
            </Box>
        </Box>
    );
};
