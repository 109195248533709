import React from "react";
import { Text } from "../Atoms";
import { Box } from "../styledSystemUtilities";

export const TableCard = ({ header, body, renderTable }) => {
    return (
        <Box backgroundColor={"cardGreen"} px={9} pb={8} pt={5}>
            <Text variant={"body3"} color={"auratiumGreen"} fontWeight={"medium"}>
                {header}
            </Text>
            <Box mb={4} />
            <Text variant={"body4"}>{body}</Text>
            <Box mb={4} />
            {renderTable()}
        </Box>
    );
};
