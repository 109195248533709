import React from "react";
import styled, { keyframes } from "styled-components";
import theme from "../../theme";
import { Box, FlexCol, FlexRowAlignCenter } from "../styledSystemUtilities";
import { useSelector } from "react-redux";
import { Text } from "../Atoms";
import { ArrowRight } from "../Atoms/assets";
import { routerHistory } from "../../routerHistory";
import { confirmBack } from "../../utils/confirmBack";

const LoadingLabel = styled.p`
    text-align: center;
    margin-top: 40px;
    font-size: 18px;
    color: ${theme.colors.graphiteGray};
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    
`;

const Spinner = styled.div`
    border: 16px solid ${theme.colors.pearlGray};
    border-radius: 50%;
    border-top: 16px solid ${theme.colors.ipanemaBlue};
    width: 100px;
    height: 100px;
    animation: ${spin} 2.5s linear infinite;
    margin: 0 auto;
`;

export const Loading = ({ children, isLoading }) => {
    const error = useSelector((state) => state.error);

    const handleBack = () => {
        window.removeEventListener("popstate", confirmBack);
        routerHistory.push("/checkout");
    };

    return error ? (
        <FlexCol height={"100vh"} alignItems={"center"} justifyContent={"center"}>
            <LoadingLabel>{error}</LoadingLabel>
            <FlexRowAlignCenter cursor={"pointer"} onClick={handleBack}>
                <Text color={"graphiteGray"}>Go back to checkout</Text>
                <Box pl={"10px"} />
                <ArrowRight fill={"graphiteGray"} />
            </FlexRowAlignCenter>
        </FlexCol>
    ) : isLoading ? (
        <Box height={"100vh"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box>
                <Spinner />
            </Box>
        </Box>
    ) : (
        children
    );
};
