import React from "react";
import ReactSelect from "react-select";
import { DropdownArrowLeft, ProductDropdownArrow } from "./assets";

export const ProductCardSelect = (props) => {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: "1px solid #94A5B5",
            height: "25px",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            color: "#28415C",
            padding: 0,
            borderRadius: 0,
            minHeight: 0,
            boxShadow: state.isFocused ? 0 : 0,
            ":hover": {
                cursor: "pointer",
                border: "1px solid #94A5B5",
            },
        }),
        valueContainer: (base, state) => ({
            ...base,
            display: "flex",
            justifyContent: "center",
        }),
        indicatorsContainer: (base, state) => ({
            ...base,
            height: "25px",
            width: "24px",
            paddingRight: "11px",
        }),
        singleValue: (base, state) => ({
            ...base,
            color: "#28415C",
        }),
        placeholder: (base, state) => ({
            ...base,
            color: "#28415C",
        }),
        menu: (base, state) => ({
            ...base,
            padding: 0,
            borderRadius: 0,
            margin: 0,
            borderBottom: "1px solid #94A5B5",
            borderRight: "1px solid #94A5B5",
            borderLeft: "1px solid #94A5B5",
            boxShadow: 0,
        }),
        menuList: (base, state) => ({
            ...base,
            padding: 0,
        }),
        input: (base, state) => ({
            ...base,
            color: "#28415C",
            padding: 0,
        }),
        option: (base, state) => ({
            ...base,
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            whiteSpace: "normal",
            color: "#28415C",
            backgroundColor: "white",
            height: "25px",
            "&:hover": {
                backgroundColor: "#F8F8F9",
            },
        }),
    };
    return (
        <ReactSelect
            styles={customStyles}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: ({ selectProps }) => {
                    return selectProps.menuIsOpen ? <DropdownArrowLeft /> : <ProductDropdownArrow />;
                },
            }}
            {...props}
            isSearchable={false}
        />
    );
};
