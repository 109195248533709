import { api } from "../utilityActions";

const updateLead = (input) =>
    api({
        call: {
            url: "/updateLead",
            method: "post",
            data: input,
        },
    });

export default updateLead;
