import React from "react";
import { Box } from "../../styledSystemUtilities";
import { AccordianHero } from "../../Atoms/assets";
import { CopyWriting, Pen, Printer, Shipping, Strategy } from "../../Atoms";
import { Dimensions, GrayBackground, IconContainer, PricingNotes, SellingPoint } from "../../Molecules";
import { ProfitHeader } from "../../Molecules/PopupComponents";

export const AccordianBrochure = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <ProfitHeader setPopup={setPopup} Hero={AccordianHero} />
            <GrayBackground>
                <SellingPoint
                    header={"A different way to tell your unique story"}
                    body={
                        "If you want a brochure that is different than a typical brochure, you just found it. Pediatric practices especially love this brochure. Plus, the 8 panels let you tell a more complete story."
                    }
                />
                <Dimensions bold={"8.5” x 24”"} light={"– Accordion folded"} />
                <SellingPoint
                    header={"Why Ascent for your brochure?"}
                    body={
                        "• Better brand Integration\n" +
                        "• High-end, original design \n" +
                        "• We specialize in dental – It’s all we do\n" +
                        "• Smart strategy\n" +
                        "• Professional copywriting\n" +
                        "• It’s easier – We’re a one-stop-shop\n" +
                        "• Timely delivery"
                    }
                    lineHeight={"24px"}
                />
                <IconContainer name={"What's Included"}>
                    <Pen name={"Design"} />
                    <Strategy name={"Strategy"} fill={"balticBlue"} />
                    <CopyWriting name={"Copywriting"} fill={"balticBlue"} />
                    <Printer name={"Printing"} />
                    <Shipping name={"Shipping"} />
                </IconContainer>
            </GrayBackground>
            <PricingNotes>
                Print materials are purchased on a per-item basis and must be paid in full. We offer the choice of a
                1-time payment or 12 payments at a monthly interest rate of 12%.
            </PricingNotes>
        </Box>
    );
};
