import React from "react";
import { OnlinePageTemplate } from "./PageTemplate";
import { OnlineHeader, OnlineSubHeader } from "../Atoms/Headers";
import { Box, FlexRow, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Text } from "../Atoms";
import { OnlineOneSummaryRow, OnlineTwoSummaryRow } from "../Atoms/OnlineSummaryRows";
import { CircleHeader } from "../Molecules/CircleHeader";
import { VideoPlaybackIcon } from "../Atoms/assets";
import { formatter } from "../../utils/formatter";
import {
    ENHANCED_SEO,
    ENHANCED_WEBSITE,
    ESSENTIAL_SEO,
    REVIEW_GENERATION,
    SOCIAL_FOR_SEO,
    STANDARD_WEBSITE,
    MY_SOCIAL_PRACTICE_PRO,
    MY_SOCIAL_PRACTICE_SELF,
} from "../../constants/productList";
import { ShoppingCartButton, SmallButton } from "../Atoms/Button";
import { routerHistory } from "../../routerHistory";
import { useCart } from "../../hooks/useCart";
import { AddToCart, LearnMore } from "../Atoms/ProductButtons";
import { useSetPopup } from "../../hooks/useSetPopup";
import { useOnlineOptions } from "../../hooks/useOnlineOptions";
import _ from "lodash/fp";
// import { report } from "./data";
import { useOnlineEstimates } from "../../hooks/useOnlineEstimates";
import { EstimateRow } from "../Atoms/EstimateRow";
import { useSelector } from "react-redux";
import { getVisibilityLevel } from "../../utils/getVisibilityLevel";

const mySocialPracticeProducts = [MY_SOCIAL_PRACTICE_SELF, MY_SOCIAL_PRACTICE_PRO];

const onlineProducts = [STANDARD_WEBSITE, ESSENTIAL_SEO, ENHANCED_WEBSITE, ENHANCED_SEO, SOCIAL_FOR_SEO];

const log06 = (num) => {
    return Math.log(num) / Math.log(1.06);
};

export const OnlineThree = () => {
    const [option, setOption] = useOnlineOptions();
    const report = useSelector((state) => state.report);
    const isBurkhart = useSelector((state) => state.isBurkhart);
    const seoMultiplier =
        (log06(report.seo.overallRank) / log06(report.seo.numCompetitors) + report.competitionLevel * 2) / 3;
    const reviewsMultiplier =
        (log06(report.reviews.reviewsRank) / log06(report.seo.numCompetitors) + report.competitionLevel * 2) / 3;
    const recommendationLevels = {
        seoRecommendationLevel: seoMultiplier * option.multiplier > 1 ? 1 : seoMultiplier * option.multiplier,
        reviewsRecommendationLevel:
            reviewsMultiplier * option.multiplier > 1 ? 1 : reviewsMultiplier * option.multiplier,
    };

    const dynamicProducts = isBurkhart ? onlineProducts : [...onlineProducts, ...mySocialPracticeProducts];

    const products = getRecommendedProducts(recommendationLevels, seoMultiplier, reviewsMultiplier, dynamicProducts);

    const recommendedEstimates = useOnlineEstimates(products.recommendedProducts);

    const visibilityLevel = getVisibilityLevel(report);

    return (
        <OnlinePageTemplate>
            <Box pt={"26px"} />
            <OnlineHeader textAlign={"center"}>Recommendations</OnlineHeader>
            <OnlineSubHeader>{_.get("user.practiceName", report)}</OnlineSubHeader>
            <Box pt={"20px"} />
            <OnlineOneSummaryRow
                rank={_.get("seo.overallRank", report)}
                competitionLevel={`${_.round(_.get("competitionLevel", report) * 100)}%`}
                visibilityLevel={visibilityLevel}
            />
            <Box pb={"4px"} />
            <OnlineTwoSummaryRow checked={option} setChecked={setOption} />
            <Box pt={"20px"} />
            <CircleHeader number={3} color={"forrestGreen"} text={option.recommendation} />
            <Box pt={"20px"} />

            {products.recommendedProducts.map((product) => (
                <Box key={product.id} pb={"4px"}>
                    <RecommendedProductCard product={product} />
                </Box>
            ))}
            <Box pb={"12px"} />
            <EstimateRow
                color={"forrestGreen"}
                text={"With these recommendations, we estimate:"}
                estimates={recommendedEstimates}
                questionColor={"auratiumGreen"}
                roiPopup={"onlineRoiExplanation"}
            />
            <Box pb={"18px"} />
            <Box height={"20px"} backgroundColor={"auratiumGreen"} />
            <Box pb={"20px"} />
            <FlexRowAlignCenter
                py={"8px"}
                justifyContent={"center"}
                backgroundColor={"#F1F6F6"}
                pl={"16px"}
                pr={"25px"}
                mx={"22px"}
            >
                <Box width={"14px"}>
                    <VideoPlaybackIcon fill={"auratiumGreen"} />
                </Box>

                <Box pl={"19px"} />
                <Text variant={"body4"} color={"forrestGreen"} lineHeight={"16px"}>
                    All visibility products are subscription based and can be cancelled anytime with 30 days notice.
                </Text>
            </FlexRowAlignCenter>
            <Box pb={"15px"} />
            <Text color={"forrestGreen"} variant={"body3"} mx={"22px"} lineHeight={"20px"}>
                Other available subscriptions to consider:
            </Text>
            <Box pb={"5px"} />
            {products.additionalProducts.map((product) => (
                <Box pb={"10px"} key={product.id}>
                    <AdditionalProductCard product={product} />
                </Box>
            ))}

            <Box pb={"12px"} />
            <Text color={"forrestGreen"} variant={"body4"} px={"24px"}>
                The ROI estimate totals at the top of the page reflect the estimate totals for all products added to
                your cart.
            </Text>
            <Box pb={"12px"} />
            <FlexRow justifyContent={"space-between"}>
                <SmallButton backgroundColor={"ipanemaBlue"} onClick={() => routerHistory.push("/growth-one")}>
                    to growth
                </SmallButton>
                <SmallButton backgroundColor={"balticBlue"} onClick={() => routerHistory.push("/profit-one")}>
                    to profit
                </SmallButton>
            </FlexRow>
            <Box pb={"10px"} />
            <FlexRow justifyContent={"center"}>
                <ShoppingCartButton backgroundColor={"auratiumGreen"} onClick={() => routerHistory.push("/cart")} />
            </FlexRow>
            <Box pb={"100px"} />
        </OnlinePageTemplate>
    );
};

const RecommendedProductCard = ({ product }) => {
    const [isAdded, toggleProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    return (
        <Box data-testid={product.recurlyCode}>
            <FlexRow
                justifyContent={"space-between"}
                backgroundColor={"white"}
                pl={"20px"}
                pr={"25px"}
                pb={"14px"}
                pt={"9px"}
            >
                <Box>
                    <Text fontFamily={"montserrat"} fontSize={"20px"} color={"sienna"} lineHeight={"24px"}>
                        {product.name}
                    </Text>
                    <Text variant={"body4"} color={"auratiumGreen"} lineHeight={"16px"}>
                        {product.description}
                        <br />
                        {product.secondaryDescription}
                    </Text>
                </Box>
                <Text alignSelf={"flex-end"} variant={"body3"}>
                    {formatter.format(product.monthlyPrice)}/mo.
                </Text>
            </FlexRow>
            <FlexRowAlignCenter
                pl={"20px"}
                pr={"25px"}
                height={"28px"}
                backgroundColor={"#F1F6F6"}
                justifyContent={"space-between"}
                width={"100%"}
            >
                <LearnMore color={"forrestGreen"} setPopup={setPopup} />
                <AddToCart color={"forrestGreen"} toggleProduct={toggleProduct} isAdded={isAdded} />
            </FlexRowAlignCenter>
        </Box>
    );
};

const AdditionalProductCard = ({ product }) => {
    const [isAdded, toggleProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    return (
        <Box mx={"22px"} data-testid={product.recurlyCode}>
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                backgroundColor={"#F1F6F6"}
                pl={"16px"}
                pr={"25px"}
                py={"8px"}
            >
                <Box>
                    <Text variant={"body3"} color={"auratiumGreen"}>
                        {product.name}
                    </Text>
                    <Text variant={"label1"} color={"seaGreen"}>
                        {product.description}
                    </Text>
                </Box>
                <Text variant={"body3"}>{formatter.format(product.monthlyPrice)}/mo.</Text>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                px={"15px"}
                height={"28px"}
                backgroundColor={"#CCE2D7"}
                justifyContent={"space-between"}
                width={"100%"}
            >
                <LearnMore color={"forrestGreen"} setPopup={setPopup} />
                <AddToCart isAdded={isAdded} toggleProduct={toggleProduct} color={"forrestGreen"} />
            </FlexRowAlignCenter>
        </Box>
    );
};

const getRecommendedProducts = (recommendationLevels, seoMultiplier, reviewsMultiplier, products) => {
    const recommendedProductsDictionary = _.flow(
        _.map((product) => _.set("newPatientEstimate", product.highNewPatientsProjection * seoMultiplier, product)),
        _.groupBy(
            (product) =>
                recommendationLevels.seoRecommendationLevel >= product.recommendationLevel &&
                recommendationLevels.seoRecommendationLevel <= product.recommendationLevelCutoff
        ),
        (dictionary) => (dictionary.true ? dictionary : _.set("true", [], dictionary))
    )(products);

    const recommendReviews = recommendationLevels.reviewsRecommendationLevel >= REVIEW_GENERATION.recommendationLevel;

    const reviewsWithEstimate = _.set(
        "newPatientEstimate",
        REVIEW_GENERATION.highNewPatientsProjection * reviewsMultiplier,
        REVIEW_GENERATION
    );

    const recommendedProducts = recommendReviews
        ? _.set("true", recommendedProductsDictionary.true.concat(reviewsWithEstimate), recommendedProductsDictionary)
        : _.set(
              "false",
              recommendedProductsDictionary.false.concat(reviewsWithEstimate),
              recommendedProductsDictionary
          );

    return {
        recommendedProducts: _.getOr([], "true", recommendedProducts),
        additionalProducts: _.getOr([], "false", recommendedProducts),
    };
};
