import React from "react";
import { Box, FlexRow } from "../../styledSystemUtilities";
import { Dot, Text } from "../../Atoms";
import { GrayBackground, IconContainer, SellingPoint } from "../../Molecules";
import { EDentHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const ProEDent = ({ setPopup }) => {
    return (
        <Box width={"100%"}>
            <EDentHeader setPopup={setPopup} name={"Plan Pro In-House Savings Plan"} tagline={"Maximize revenue."} />
            <GrayBackground>
                <SellingPoint
                    header={"Maximize Revenue"}
                    body={
                        "A Dental Membership Plan or In-Office Dental Savings Plan will ensure that you can provide your patients with a valuable payment option regardless of dental insurance coverage. PlanPro is a service and software created to simplify the process for your team and give you the tools you need for success. Approximately 60% of Americans are not covered by traditional dental insurance. This number continues to rise with the retirement rate and the number of employers opting out of dental coverage. As a provider, it is your opportunity to attract these patients to your office."
                    }
                />
                <IconContainer name={"What We Do"} />
                <BulletPoint>
                    Customized plan development and consulting based on proven success and YOUR practice goals.
                </BulletPoint>
                <BulletPoint>
                    Templates – We will provide a complete enrollment form and explanation of plan benefits. These forms
                    will be customized to the decisions you make for your plan and will include your practice logo.
                </BulletPoint>
                <BulletPoint>
                    Our savings plan coaching and recommendations will depend on the UCR data shown in your FeePro fee
                    analysis. It’s so important to our process that we include the first year of FeePro with your
                    PlanPro purchase.
                </BulletPoint>
                <BulletPoint>
                    Marketing Starter Kit – Time-saving templates and suggestions to help you announce and market your
                    new program.
                </BulletPoint>
            </GrayBackground>
            <WhiteBackground>
                <Text variant={"body2"} color={"balticBlue"} lineHeight={"21px"}>
                    “Approximately 60% of Americans are not covered by traditional dental insurance.”
                </Text>
            </WhiteBackground>
            <GrayBackground>
                <Box pb={9} />
                <SellingPoint
                    header={"About PlanPro"}
                    body={
                        "Don’t waste valuable time and resources trying to figure this out on your own. PlanPro will simplify and expedite the process by providing FORMULAS AND DATA to ensure profitability, FORMS AND TEMPLATES to decrease the time it takes to get started, MARKETING SUPPORT to increase success and PLANPRO SOFTWARE, the business tool to take care of all the tracking details."
                    }
                />
                <Box pb={6} />
            </GrayBackground>
        </Box>
    );
};

function BulletPoint({ children }) {
    return (
        <FlexRow pl={3} pb={8}>
            <Box pt={3}>
                <Dot fill={"balticBlue"} width={"5px"} />
            </Box>
            <Box pl={3} />
            <Text width={"315px"} variant={"body3"}>
                {children}
            </Text>
        </FlexRow>
    );
}
